import { Checkbox, FormColumn } from "best-common-react-2";
import React from "react";
import { AdminSuiteActionsProps } from "./AdminSuiteRequestsActions";

const AdminSuiteInReviewAction = ({ actionState, setActionState }: AdminSuiteActionsProps) => {
  return (
    <FormColumn width={2}>
      <Checkbox
        id="request-sent-to-club"
        label="Request Sent To Club"
        className="mt-4"
        checked={actionState.requestSentToClub}
        onChange={(value: boolean) => {
          setActionState({
            ...actionState,
            requestSentToClub: value,
          });
        }}
      />
    </FormColumn>
  );
};

export default AdminSuiteInReviewAction;

import { Checkbox, FormColumn } from "best-common-react-2";
import React from "react";
import { ActionProps } from "./ActionHandler";

const RequestSentToClubAction: React.FC<ActionProps> = ({ state, onStateChange }) => (
  <FormColumn width={2} className="mt-4">
    <Checkbox
      id="request-sent-to-club"
      label="Request Sent to Club"
      checked={!!state.requestSentToClub}
      onChange={() => {
        onStateChange({ ...state, requestSentToClub: !state.requestSentToClub });
      }}
    />
  </FormColumn>
);

export default RequestSentToClubAction;

import React from "react";
import { StatsApiGame } from "../../../../types/Stats";
import GameDisplay from "./GameDisplay";
import GameSelector from "./GameSelector";

type GameSelectorProps = {
  value?: number;
  onChange: (value: number) => void;
  label: string;
  required?: boolean;
};

const GameSelectorDisplay: React.FC<GameSelectorProps> = ({ value, onChange, label, required }) => {
  return (
    <div className="d-flex flex-column align-items-start">
      <GameSelector onChange={onChange} label={label} required={required} />
      <GameDisplay
        gamePk={value}
        setGame={(game: StatsApiGame) => {
          if (game.gamePk !== value) {
            onChange(game.gamePk);
          }
        }}
      />
    </div>
  );
};

export default GameSelectorDisplay;

import { BestLabel, BestSelect, BestTable, FormColumn4Wide } from "best-common-react";
import { useLoading } from "best-common-react-2";
import React, { useEffect, useState } from "react";
import { getRequestsByVenueForSeasonId } from "../../../api/RequesTixApi";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { getSeasonOption } from "../../../util/DropdownUtil";

const Columns = [
  {
    name: "Team Name",
    key: "teamName",
  },
  {
    name: "Venue Name",
    key: "venueName",
  },
  {
    name: "Approved Personal Requests",
    key: "approvedPersonalRequests",
  },
  {
    name: "Total Personal Requests",
    key: "personalRequests",
  },
  {
    name: "Approved Business Requests",
    key: "approvedBusinessRequests",
  },
  {
    name: "Total Business Requests",
    key: "businessRequests",
  },
];

const RequestsByVenueReport = ({ reportData, setReportData }) => {
  const { setLoading } = useLoading();
  const { seasonsOptions } = useDropdowns();
  const { seasonId } = reportData;
  const [requestsByVenue, setRequestsByVenue] = useState([]);

  const getData = async (seasonId) => {
    try {
      setLoading(true);
      const result = await getRequestsByVenueForSeasonId(seasonId);
      setRequestsByVenue(result);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onChange = (key, data) => {
    setReportData({ ...reportData, [key]: data });
  };

  useEffect(() => {
    if (!!seasonId) {
      getData(seasonId);
    }
  }, [seasonId]);

  return (
    <div>
      <div className="row">
        <FormColumn4Wide>
          <BestLabel htmlFor="season">Season</BestLabel>
          <BestSelect
            id="season"
            options={seasonsOptions}
            value={getSeasonOption(seasonId, seasonsOptions)}
            onChange={(value) => {
              onChange("seasonId", value.value.seasonId);
            }}
          />
        </FormColumn4Wide>
      </div>
      <div className="mt-2">
        <BestTable data={requestsByVenue} columns={Columns} />
      </div>
    </div>
  );
};

export default RequestsByVenueReport;

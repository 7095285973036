import { DesktopTabletView, Label, MobileView, RequiredIndicator, Table } from "best-common-react-2";
import React from "react";
import UserAllotmentConstants from "../../constants/UserAllotmentConstants";
import EditableAllotment from "../misc/EditableAllotment";

const SPRING_TRAINING = "springTraining";
const REGULAR_SEASON = "regularSeason";
const POST_SEASON = "postSeason";

const DepartmentAllotmentTable = ({ department, onChange }) => {
  const onAllotmentChange = (season, type, amount, unlimited) => {
    const key = `${type}Allotment`;
    const newAllotment = { ...department[season], [key]: { amount: amount, unlimited: unlimited } };
    const allotment = {
      [SPRING_TRAINING]: { ...department[SPRING_TRAINING] },
      [REGULAR_SEASON]: { ...department[REGULAR_SEASON] },
      [POST_SEASON]: { ...department[POST_SEASON] },
      [season]: newAllotment,
    };
    onChange(allotment);
  };

  return (
    <>
      <DesktopTabletView>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Header style={{ width: "30%" }}>Season</Table.Header>
              <Table.Header>Personal</Table.Header>
              <DesktopTabletView>
                <Table.Header>Business</Table.Header>
              </DesktopTabletView>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {department && department.springTraining && (
              <Table.Row>
                <Table.TdInfo>Spring Training</Table.TdInfo>
                <EditableAllotment
                  type={UserAllotmentConstants.PERSONAL}
                  value={department.springTraining.personalAllotment.amount}
                  unlimited={department.springTraining.personalAllotment.unlimited}
                  onValueChange={(type, value) => {
                    onAllotmentChange(
                      SPRING_TRAINING,
                      type,
                      value,
                      department.springTraining.personalAllotment.unlimited
                    );
                  }}
                  onUnlimitedChange={(type, unlimited) => {
                    onAllotmentChange(
                      SPRING_TRAINING,
                      type,
                      department.springTraining.personalAllotment.amount,
                      unlimited
                    );
                  }}
                />
                <EditableAllotment
                  type={UserAllotmentConstants.BUSINESS}
                  value={department.springTraining.businessAllotment.amount}
                  unlimited={department.springTraining.businessAllotment.unlimited}
                  onValueChange={(type, value) => {
                    onAllotmentChange(
                      SPRING_TRAINING,
                      type,
                      value,
                      department.springTraining.businessAllotment.unlimited
                    );
                  }}
                  onUnlimitedChange={(type, unlimited) => {
                    onAllotmentChange(
                      SPRING_TRAINING,
                      type,
                      department.springTraining.businessAllotment.amount,
                      unlimited
                    );
                  }}
                />
              </Table.Row>
            )}
            {department && department.regularSeason && (
              <Table.Row>
                <Table.TdInfo>Regular Season</Table.TdInfo>
                <EditableAllotment
                  type={UserAllotmentConstants.PERSONAL}
                  value={department.regularSeason.personalAllotment.amount}
                  unlimited={department.regularSeason.personalAllotment.unlimited}
                  onValueChange={(type, value) => {
                    onAllotmentChange(
                      REGULAR_SEASON,
                      type,
                      value,
                      department.regularSeason.personalAllotment.unlimited
                    );
                  }}
                  onUnlimitedChange={(type, unlimited) => {
                    onAllotmentChange(
                      REGULAR_SEASON,
                      type,
                      department.regularSeason.personalAllotment.amount,
                      unlimited
                    );
                  }}
                />
                <EditableAllotment
                  type={UserAllotmentConstants.BUSINESS}
                  value={department.regularSeason.businessAllotment.amount}
                  unlimited={department.regularSeason.businessAllotment.unlimited}
                  onValueChange={(type, value) => {
                    onAllotmentChange(
                      REGULAR_SEASON,
                      type,
                      value,
                      department.regularSeason.businessAllotment.unlimited
                    );
                  }}
                  onUnlimitedChange={(type, unlimited) => {
                    onAllotmentChange(
                      REGULAR_SEASON,
                      type,
                      department.regularSeason.businessAllotment.amount,
                      unlimited
                    );
                  }}
                />
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </DesktopTabletView>

      <MobileView>
        {department && department.springTraining && (
          <>
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Header>Spring Training</Table.Header>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                <Table.Row>
                  <EditableAllotment
                    title={
                      <Label htmlFor={`spring-personal-${department.departmentName}`}>
                        Personal <RequiredIndicator />
                      </Label>
                    }
                    type={UserAllotmentConstants.PERSONAL}
                    value={department.springTraining.personalAllotment.amount}
                    unlimited={department.springTraining.personalAllotment.unlimited}
                    onValueChange={(type, value) => {
                      onAllotmentChange(
                        SPRING_TRAINING,
                        type,
                        value,
                        department.springTraining.personalAllotment.unlimited
                      );
                    }}
                    onUnlimitedChange={(type, unlimited) => {
                      onAllotmentChange(
                        SPRING_TRAINING,
                        type,
                        department.springTraining.personalAllotment.amount,
                        unlimited
                      );
                    }}
                  />
                </Table.Row>
                <Table.Row>
                  <EditableAllotment
                    title={
                      <Label htmlFor={`spring-business-${department.departmentName}`}>
                        Business <RequiredIndicator />
                      </Label>
                    }
                    type={UserAllotmentConstants.BUSINESS}
                    value={department.springTraining.businessAllotment.amount}
                    unlimited={department.springTraining.businessAllotment.unlimited}
                    onValueChange={(type, value) => {
                      onAllotmentChange(
                        SPRING_TRAINING,
                        type,
                        value,
                        department.springTraining.businessAllotment.unlimited
                      );
                    }}
                    onUnlimitedChange={(type, unlimited) => {
                      onAllotmentChange(
                        SPRING_TRAINING,
                        type,
                        department.springTraining.businessAllotment.amount,
                        unlimited
                      );
                    }}
                  />
                </Table.Row>
              </Table.Body>
            </Table>
            <div className="mt-2">
              <Table>
                <Table.Head>
                  <Table.Row>
                    <Table.Header>Regular Season</Table.Header>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  <Table.Row>
                    <EditableAllotment
                      title={
                        <Label htmlFor={`regular-personal-${department.departmentName}`}>
                          Personal <RequiredIndicator />
                        </Label>
                      }
                      type={UserAllotmentConstants.PERSONAL}
                      value={department.regularSeason.personalAllotment.amount}
                      unlimited={department.regularSeason.personalAllotment.unlimited}
                      onValueChange={(type, value) => {
                        onAllotmentChange(
                          REGULAR_SEASON,
                          type,
                          value,
                          department.regularSeason.personalAllotment.unlimited
                        );
                      }}
                      onUnlimitedChange={(type, unlimited) => {
                        onAllotmentChange(
                          REGULAR_SEASON,
                          type,
                          department.regularSeason.personalAllotment.amount,
                          unlimited
                        );
                      }}
                    />
                  </Table.Row>
                  <Table.Row>
                    <EditableAllotment
                      title={
                        <Label htmlFor={`regular-business-${department.departmentName}`}>
                          Business <RequiredIndicator />
                        </Label>
                      }
                      type={UserAllotmentConstants.BUSINESS}
                      value={department.regularSeason.businessAllotment.amount}
                      unlimited={department.regularSeason.businessAllotment.unlimited}
                      onValueChange={(type, value) => {
                        onAllotmentChange(
                          REGULAR_SEASON,
                          type,
                          value,
                          department.regularSeason.businessAllotment.unlimited
                        );
                      }}
                      onUnlimitedChange={(type, unlimited) => {
                        onAllotmentChange(
                          REGULAR_SEASON,
                          type,
                          department.regularSeason.businessAllotment.amount,
                          unlimited
                        );
                      }}
                    />
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
          </>
        )}
      </MobileView>
    </>
  );
};

export default DepartmentAllotmentTable;

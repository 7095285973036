import {
  Button,
  Card,
  FormColumn,
  Row,
  Search,
  Select,
  StickyFooterButtons,
  useLoading,
  ValueOpt,
} from "best-common-react-2";
import React, { useEffect, useState } from "react";
import { getVenues } from "../../../api/RequesTixApi";
import CopyVenueDataModal from "../../../components/venue/CopyVenueDataModal";
import VenueTable from "../../../components/venue/VenueTable";
import { useAdminYear } from "../../../contexts/AdminYearContext";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { VenueDTO } from "../../../types/Venue";

const AdminVenues = () => {
  const { setLoading } = useLoading();
  const [venues, setVenues] = useState<VenueDTO[]>([]);
  const { yearsOptions } = useDropdowns();
  const [selectedYear, setSelectedYear] = useAdminYear();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [openCopyData, setOpenCopyData] = useState<boolean>(false);

  const fetchVenues = async (selectedYear: ValueOpt<number>) => {
    try {
      setLoading(true);
      const res: VenueDTO[] = await getVenues(selectedYear.value);
      setVenues(res);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void fetchVenues(selectedYear);
  }, [selectedYear]);

  return (
    <Card>
      <Card.Header>Venues</Card.Header>
      <Card.Body>
        <CopyVenueDataModal isOpen={openCopyData} setIsOpen={setOpenCopyData} />
        <Row>
          <FormColumn width={4}>
            <Select
              id="years-dropdown"
              label="Year"
              options={yearsOptions}
              value={selectedYear}
              onChange={setSelectedYear}
            />
          </FormColumn>
          <FormColumn width={4}>
            <Search
              id="search-box"
              label="Search Venues"
              placeholder="begin typing to filter venues"
              value={searchTerm}
              onChange={setSearchTerm}
            />
          </FormColumn>
        </Row>
        <VenueTable values={venues} searchTerm={searchTerm} />
      </Card.Body>
      <Card.Footer>
        <StickyFooterButtons>
          <Button variant="default" onClick={() => setOpenCopyData(true)}>
            Copy Venue Data
          </Button>
        </StickyFooterButtons>
      </Card.Footer>
    </Card>
  );
};

export default AdminVenues;

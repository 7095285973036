import { Button, Label, Modal } from "best-common-react-2";
import React from "react";
import styled from "styled-components";

const CopyText = styled.div`
  font-size: 0.9rem;
`;

const GovernmentUseModal = ({ isOpen, onClose }) => (
  <Modal show={isOpen} size="lg">
    <Modal.Header close={onClose}>Government Officials</Modal.Header>
    <Modal.Body>
      <div>
        <Label htmlFor="whoIs">Who is a Government Official?</Label>
        <CopyText id="whoIs">
          For the purposes of this policy, a government official includes (1) any employee, executive, official, or
          agent of a governmental department, agency or instrumentality, (2) any employee, director, officer, or agent
          of a wholly or partially government-owned or –controlled company or business, (3) a political party or one of
          its officials, (4) a candidate for public office, (5) an employee, executive, official, or agent of a public
          international organization (e.g., the International Monetary Fund or the World Bank) or (6) any other person,
          individual or entity acting on behalf of any of the above-described persons or entities.
          <br />
          <br />
          <u>
            If you are unsure of whether a potential guest is a government official, please contact Jorge Perez Diaz,
            Leo Villalobos, or Quest Meeks for further assistance.
          </u>
        </CopyText>
      </div>
      <div className="mt-2">
        <Label htmlFor="whyMatter">Why does it matter?</Label>
        <CopyText id="whyMatter">
          Providing anything of value such as a game ticket or event admittance to a government official may give rise
          to an appearance of impropriety and/or violate federal, state, or local anti-corruption and anti-bribery laws
          such as the Foreign Corrupt Practices Act. If you provide anything of value (including a game ticket) to a
          government official in violation of MLB policy, you may be subject to discipline.
        </CopyText>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="default" onClick={onClose}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
);

export default GovernmentUseModal;

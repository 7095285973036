import { DataTableDataType, FormatterType, TextFormatter } from "best-common-react-2";
import React from "react";
import { UserDTO } from "../../types/User";

const UserDepartmentFormatter = <T extends DataTableDataType>({ value, ...rest }: FormatterType<T>) => {
  const user: UserDTO = value as UserDTO;
  const department: string = user?.department;
  return <TextFormatter {...rest} value={department} />;
};

export default UserDepartmentFormatter;

import { Accordion, AttributeHeader, AttributeValue, CustomMobileAccordionType, Icon, Link } from "best-common-react-2";
import React from "react";
import RouteConstants, { urlReplace } from "../../constants/RouteConstants";
import { BlackoutFormatter, VenueData } from "./VenueTable";

const VenueAccordion = ({ data, ...rest }: CustomMobileAccordionType<VenueData>) => {
  return (
    <Accordion {...rest}>
      <Accordion.Header>
        <div className="d-flex">
          <Link to={urlReplace(RouteConstants.ADMIN.VENUES_EDIT, { venueId: data.id })}>
            <Icon iconName="fa-pencil-alt" className="fa-lg me-3" />
          </Link>
          {data.name}
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <div>
          <AttributeHeader>Location</AttributeHeader>
          <AttributeValue>{data.location}</AttributeValue>

          <AttributeHeader>Accepted Credit Cards</AttributeHeader>
          <AttributeValue>{data.acceptedCCs}</AttributeValue>

          <AttributeHeader>Delivery Method</AttributeHeader>
          <AttributeValue>{data.deliveryMethod}</AttributeValue>

          <AttributeHeader>Requests</AttributeHeader>
          <AttributeValue>
            <BlackoutFormatter row={data} value="" />
          </AttributeValue>
        </div>
      </Accordion.Body>
    </Accordion>
  );
};

export default VenueAccordion;

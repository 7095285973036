import { Checkbox } from "best-common-react-2";
import React from "react";
import styled from "styled-components";
import AccessCutoff from "./AccessCutoff";
import PersonalBusinessCutoff from "./PersonalBusinessCutoff";

const Container = styled.div`
  @media (max-width: 992px) {
    margin-bottom: 1rem;
  }
`;

const Card = styled.div`
  background-color: ${(props) => props.theme["almost-white"]};
  border: 1px solid ${(props) => props.theme["grey"]};
  padding: 1rem;
`;

const AccessCutoffOverrider = ({ override, setOverride, isCutoff = false, timezone = "America/New_York" }) => {
  const { active } = override;

  const toggleActive = () => {
    setOverride({ ...override, active: !active });
  };

  return (
    <Container>
      <Checkbox
        id={`${!isCutoff ? "access" : "cutoff"}-active`}
        checked={active}
        onChange={toggleActive}
        label={!isCutoff ? "Event Access" : "Event Cutoff"}
      />
      <Card>
        {isCutoff ? (
          <PersonalBusinessCutoff override={override} setOverride={setOverride} timezone={timezone} active={active} />
        ) : (
          <AccessCutoff override={override} setOverride={setOverride} isCutoff={false} timezone={timezone} />
        )}
      </Card>
    </Container>
  );
};

export default AccessCutoffOverrider;

import { getLocalStorageItem, setLocalStorageItem } from "best-common-react-2";
import { getCurrentUser } from "../api/RequesTixApi";
import LocalStorageConstants from "../constants/LocalStorageConstants";
import { UserInfo } from "../types/User";

export const getUserInfo = async () => {
  try {
    let userInfo: UserInfo | undefined = getLocalStorageItem(LocalStorageConstants.USER_INFO);
    if (!userInfo) {
      userInfo = await getCurrentUser();
      if (userInfo.email) {
        setLocalStorageItem(LocalStorageConstants.USER_INFO, userInfo);
      }
    }
    return Promise.resolve(userInfo);
  } catch (e) {
    console.error(e);
    return Promise.reject(undefined);
  }
};

export default {
  WILDCARD: "*",
  BASE: "/",
  TODAYS_TICKETS: "/todaysTickets",
  LOGIN: "/login",
  LOGIN_CALLBACK: "/login/callback",
  REPORTS: "/reports/:reportId",
  REQUESTS: {
    BASE: "/requests",
    TICKETS: "/requests/:gamePk/:type",
    EDIT: "/request/edit/:requestId",
    SUITES: "/suites/request",
    SUITES_REQUEST: "/suites/requests/new",
    SUITES_REQUEST_EDIT: "/suites/requests/edit/:suiteRequestId",
  },
  USER: {
    ACCOUNT: "/account",
  },
  PURCHASE_OPPORTUNITY: {
    LIST: "/purchaseOpportunity/list",
    REQUESTS: "/purchaseOpportunity/requests",
    REQUEST: "/purchaseOpportunity/request/:id",
    REQUEST_EDIT: "/purchaseOpportunity/request/:purchaseOpportunityId/edit/:purchaseOpportunityEntryId",
  },
  ADMIN: {
    REQUESTS: "/admin/requests",
    REQUESTS_GAME: "/admin/requests/:gamePk",
    REQUESTS_SERIES: "/admin/series/requests/:season/:homeTeamId/:seriesNumber/:gameType",
    REQUESTS_EDIT: "/admin/request/edit/:requestId",
    USERS: "/admin/users",
    USERS_EDIT: "/admin/users/:userId",
    DIGITAL_TICKETS: "/admin/digitalTickets",
    DEPARTMENTS: "/admin/departments",
    DEPARTMENTS_EDIT: "/admin/departments/:departmentId",
    VENUES: "/admin/venues",
    VENUES_EDIT: "/admin/venues/:venueId",
    EVENTS: "/admin/events",
    EVENTS_EDIT: "/admin/events/:eventId",
    PROJECTS: "/admin/projects",
    POSTSEASON: "/admin/postseason",
    TDC: "/admin/tdc",
    PROJECTS_EDIT: "/admin/projects/:projectId",
    TODAYS_TICKETS: "/admin/todaysTickets",
    TODAYS_TICKETS_EDIT: "/admin/todaysTickets/edit/:requestId",
    PURCHASE_OPPORTUNITY: {
      BASE: "/admin/purchaseOpportunity",
      VIEW: "/admin/purchaseOpportunity/:id",
      EDIT: "/admin/edit/purchaseOpportunity/:id",
    },
    SUITE_REQUESTS: {
      BASE: "/admin/suite/requests",
      EDIT: "/admin/suite/request/:suiteRequestId",
    },
  },
};

export const urlReplace = (str: string, data: object): string => {
  if (str) {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      str = str.replace(`:${key}`, data[key]);
    });
    return str;
  } else {
    return "";
  }
};

import { FormatterType, Link } from "best-common-react-2";
import React from "react";
import styled from "styled-components";
import CompleteConstants from "../../../../constants/CompleteConstants";
import { formatGameDate, useDayDate } from "../../../../util/TimeUtil";
import MatchUp from "../../../game/MatchUp";
import { TotalData } from "./BySeriesTypes";

const GameDateHolder = styled.div.attrs(() => ({
  className: "me-2",
}))`
  width: 11.5rem;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

type TicketValueProps = {
  subrow: boolean;
};

const TicketValue = styled.span<TicketValueProps>`
  font-weight: ${(props) => (props.subrow ? "inherit" : "bold")};
`;

const Grey = styled.span`
  color: ${(props) => props.theme.grey};
`;

const ExclamationIcon = styled.div.attrs({ className: "fas fa-exclamation-circle" })`
  height: 1rem;
  width: 1rem;
  color: #ff9300;
`;

const EventChangeText = styled.span`
  font-size: 0.875rem;
`;

const zero = <Grey>0</Grey>;

export const GameDateFormatter = ({
  row: { homeTeam, awayTeam, venue, gameDate, toLink, location, seriesDescription },
  subrow = false,
}: FormatterType<TotalData>) => (
  <Link
    to={{
      pathname: toLink,
      state: { previousLocation: location },
    }}
  >
    <div className="d-flex">
      {subrow ? <GameDateHolder>{formatGameDate(gameDate, venue.timeZone, false)}</GameDateHolder> : ""}
      <MatchUp away={awayTeam} home={homeTeam} description={seriesDescription} />
    </div>
  </Link>
);

export const CompleteInCompleteFormatter = ({
  value: { complete, incomplete, filter },
  row: { toLink, location },
  subrow = false,
}: FormatterType<TotalData>) => (
  <div>
    <TicketValue subrow={subrow}>
      {incomplete === 0 ? (
        zero
      ) : (
        <Link
          to={{
            pathname: toLink,
            state: { previousLocation: location },
            search: `?${filter}&completeId=${CompleteConstants.INCOMPLETE_ID}`,
          }}
        >
          {incomplete}
        </Link>
      )}
      <Grey> | </Grey>
      {complete === 0 ? (
        zero
      ) : (
        <Link
          to={{
            pathname: toLink,
            state: { previousLocation: location },
            search: `?${filter}&completeId=${CompleteConstants.COMPLETE_ID}`,
          }}
        >
          {complete}
        </Link>
      )}
    </TicketValue>
  </div>
);

export const LinkFormatter = ({
  value: { value, filter },
  row: { toLink, location },
  subrow = false,
}: FormatterType<TotalData>) => (
  <div>
    {value === 0 ? (
      zero
    ) : (
      <Link
        to={{
          pathname: toLink,
          state: { previousLocation: location },
          search: filter,
        }}
      >
        <TicketValue subrow={subrow}>{value}</TicketValue>
      </Link>
    )}
  </div>
);

export const EventChangeFormatter = ({
  row: { status, rescheduledFrom },
  subrow = false,
}: FormatterType<TotalData>) => {
  let message = "";
  if (status && status.codedGameState === "C") {
    message = "Cancelled";
  } else if (rescheduledFrom) {
    const date = useDayDate(rescheduledFrom);
    message = `Original date - ${date}`;
  } else if (status && status.codedGameState === "D") {
    message = "Postponed";
  }
  if (!subrow && message.length > 0) {
    return <ExclamationIcon />;
  } else {
    return <EventChangeText>{message}</EventChangeText>;
  }
};

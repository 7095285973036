import { Select, ValueOpt } from "best-common-react-2";
import React from "react";

const options: ValueOpt<boolean>[] = [
  {
    label: "On",
    value: true,
  },
  {
    label: "Off",
    value: false,
  },
];

type PersonalOnOffProps = {
  value: boolean;
  onChange: (value: boolean) => void;
};

const PersonalOnOff = ({ value, onChange }: PersonalOnOffProps) => (
  <Select
    id="personalRequests"
    placeholder="select on or off"
    label="Personal Requests"
    options={options}
    value={options.find((option) => option.value === value)}
    onChange={({ value }: ValueOpt<boolean>) => onChange(value)}
  />
);

export default PersonalOnOff;

import React, { useEffect, useState } from "react";
import { Team } from "../../types/Team";
import TeamLogo from "./TeamLogo";

type FoundLogoType = {
  away: boolean;
  home: boolean;
};

type MatchUpProps = {
  away: Team;
  home: Team;
  description?: string;
};

const MatchUp: React.FC<MatchUpProps> = ({ away, home, description }) => {
  const [notFound, setNotFound] = useState<FoundLogoType>({
    away: false,
    home: false,
  });
  const [displayDesc, setDisplayDesc] = useState(false);

  useEffect(() => {
    setNotFound({ away: false, home: false });
  }, [away.id, home.id]);

  const handleError = (event: any, type: "home" | "away") => {
    event.target.onerror = null;
    setNotFound({ ...notFound, [type]: true });
  };

  useEffect(() => {
    setDisplayDesc(parseInt(home.id) === 1 && parseInt(away.id) === 1 && !!description);
  }, [home, away, description]);

  return (
    <div>
      {displayDesc ? (
        <div>{description}</div>
      ) : (
        <div>
          {notFound.away ? (
            <span>{away.abbreviation}</span>
          ) : (
            <TeamLogo teamId={away.id} onError={(event) => handleError(event, "away")} />
          )}
          <span style={{ margin: "0 .5rem" }}>@</span>
          {notFound.home ? (
            <span>{home.abbreviation}</span>
          ) : (
            <TeamLogo teamId={home.id} onError={(event) => handleError(event, "home")} />
          )}
        </div>
      )}
    </div>
  );
};

export default MatchUp;

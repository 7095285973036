import { Checkbox, Input, TableTdInfo } from "best-common-react";
import React from "react";
import styled from "styled-components";
import UserAllotmentConstants from "../../constants/UserAllotmentConstants";

const AllotmentInput = styled.div`
  margin-right: 1rem;
  width: 3rem;
`;

const EditableTableData = styled.td`
  width: 35%;
  &&& {
    vertical-align: middle;
  }
`;

const Allotment = ({ value, index, type, changeAllotment }) => {
  const key = "allotment" + index + type + "amount";
  return (
    <EditableTableData>
      <div className="d-flex align-items-center">
        {!value.unlimited && (
          <AllotmentInput>
            <Input
              id={key}
              key={`input-${key}`}
              disabled={value.unlimited}
              value={value.amount}
              onChange={(newVal) => {
                changeAllotment(index, type, value.unlimited, newVal.target.value);
              }}
            />
          </AllotmentInput>
        )}
        <Checkbox
          id={`checkbox-${key}`}
          key={`checkbox-${key}`}
          label="unlimited"
          checked={value.unlimited}
          onChange={() => {
            changeAllotment(index, type, !value.unlimited, value.amount);
          }}
        />
      </div>
    </EditableTableData>
  );
};

const UserAllotmentBulkUpdateTr = ({ allotments, season, index, changeAllotment }) => {
  let personalAllotment = allotments && allotments[index] ? allotments[index][UserAllotmentConstants.PERSONAL] : {};
  let businessAllotment = allotments && allotments[index] ? allotments[index][UserAllotmentConstants.BUSINESS] : {};
  return (
    <tr key={"allotment-table-row-" + index}>
      <TableTdInfo>{season && season.seasonType ? season.seasonType.seasonTypeName : ""}</TableTdInfo>
      <>
        <Allotment
          value={personalAllotment}
          index={index}
          type={UserAllotmentConstants.PERSONAL}
          changeAllotment={changeAllotment}
        />
        <Allotment
          value={businessAllotment}
          index={index}
          type={UserAllotmentConstants.BUSINESS}
          changeAllotment={changeAllotment}
        />
      </>
    </tr>
  );
};

export default UserAllotmentBulkUpdateTr;

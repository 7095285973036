import React, { createContext, useContext, useEffect, useState } from "react";

type EnvContextType = {
  variables: any;
};

const EnvContext = createContext<EnvContextType | undefined>(undefined);

const EnvProvider = ({ children }) => {
  const [variables, setVariables] = useState<any>(window.MLBBest.envVariables);

  useEffect(() => {
    setVariables(window.MLBBest.envVariables || {});
  }, []);

  return (
    <EnvContext.Provider
      value={{
        variables,
      }}
    >
      {children}
    </EnvContext.Provider>
  );
};

const useEnv = (): EnvContextType => {
  const envContext: EnvContextType | undefined = useContext<EnvContextType | undefined>(EnvContext);
  if (envContext === undefined) {
    throw new Error(`useEnv must be used within a EnvProvider`);
  }
  return envContext;
};

export { EnvContext, EnvProvider, useEnv };

import { Checkbox, CreditCardNumberInput, FormColumn, Input, PhoneNumber, Select, ValueOpt } from "best-common-react-2";
import React, { useMemo } from "react";
import styled from "styled-components";
import { useDropdowns } from "../../contexts/DropdownsContext";
import { useMetadata } from "../../contexts/MetadataContext";
import { CreditCardType } from "../../types/CreditCardType";
import { Month } from "../../types/Months";
import { State } from "../../types/State";
import { UserBillingCountry } from "../../types/UserBilling";
import { StatedRequiredCountryIds } from "../../util/CreditCardUtil";

type StyleProps = {
  stacked: boolean;
};

const LeftDefault = styled.div<StyleProps>`
  margin-top: 0.5rem;
  display: ${(props: StyleProps) => (props.stacked ? "none" : "block")};
  @media (max-width: 992px) {
    display: none;
  }
`;

const BottomDefault = styled.div<StyleProps>`
  margin-top: 0.5rem;
  @media (min-width: 992px) {
    display: ${(props: StyleProps) => (props.stacked ? "block" : "none")};
  }
`;

const CreditCardForm = ({ cc, setCC, number, setNumber, stacked = false }) => {
  const { cardTypes } = useMetadata();
  const {
    cardTypeOptions,
    stateOptions,
    monthNumberOptions: monthOptions,
    creditYearOptions,
    billingCountryOptions,
  } = useDropdowns();

  const displayedStateOptions: ValueOpt<State>[] = useMemo(
    () => (!!cc?.countryId ? stateOptions.filter((state) => state.value.countryId === cc.countryId) : stateOptions),
    [stateOptions, cc.countryId]
  );

  const handleChange = (type: string, value: number | string | boolean | CreditCardType): void => {
    setCC({ ...cc, [type]: value });
  };

  const handleCvvChange = (value, ccType) => {
    const max: number = ccType && ccType.ccTypeId === 3 ? 4 : 3;
    if (value.length <= max) {
      setCC({ ...cc, cvv: value });
    }
  };

  const handleExpirationChange = (type, value) => {
    let [month, year] = cc.ccExp.length > 0 ? cc.ccExp.split("/") : ["", ""];
    if (type === "month") month = value;
    if (type === "year") year = value;
    setCC({ ...cc, ccExp: `${month}/${year}` });
  };

  const defaultCardCheckbox = (
    <Checkbox
      id="isDefault"
      checked={cc.isDefault}
      label={<span style={{ fontSize: "0.875rem", fontWeight: "bold" }}>Set as default payment method</span>}
      onChange={() => {
        handleChange("isDefault", !cc.isDefault);
      }}
    />
  );

  const month: ValueOpt<Month> = monthOptions.find(
    (option: ValueOpt<Month>) => option.value.number === (cc.ccExp.length > 0 ? cc.ccExp.split("/")[0] : "")
  );
  const year: ValueOpt<number> = creditYearOptions.find(
    (option: ValueOpt<number>) => option.value === (cc.ccExp.length > 1 ? parseInt(cc.ccExp.split("/")[1]) : "")
  );

  return (
    <div className="container" style={{ margin: 0 }}>
      <div className="row">
        <FormColumn width={stacked ? 1 : 2}>
          <div className="row">
            <FormColumn width={2}>
              <Select
                id="cardType"
                required
                gutterBottom
                label="Card Type"
                options={cardTypeOptions}
                value={cc.ccType && cardTypeOptions.find((option) => option.value.ccTypeId === cc.ccType.ccTypeId)}
                onChange={({ value }: ValueOpt<CreditCardType>) => handleChange("ccType", value)}
              />
            </FormColumn>
            <FormColumn width={stacked ? 1 : 2}>
              <Input
                id="cardNickname"
                label="Card Nickname"
                value={cc.ccNickname}
                onChange={(value: string) => handleChange("ccNickname", value)}
                required
                gutterBottom
              />
            </FormColumn>
          </div>
          <div className="row">
            <FormColumn width={1}>
              <CreditCardNumberInput
                id="creditCardNumber"
                label="Card Number"
                isAmex={cc.ccType === cardTypes.find((cc) => cc.ccTypeId === 3)}
                value={number}
                onChange={setNumber}
                setLastFour={(value) => handleChange("ccLast4", value)}
                gutterBottom
                required
              />
            </FormColumn>
          </div>
          <div className="row">
            <FormColumn width={4}>
              <Select
                id="expDate"
                label="Exp Month"
                required
                gutterBottom
                options={monthOptions}
                value={month}
                onChange={({ value }: ValueOpt<Month>) => handleExpirationChange("month", value.number)}
                placeholder="Month"
              />
            </FormColumn>
            <FormColumn width={4}>
              <Select
                id="expDateYear"
                required
                gutterBottom
                label="Exp Year"
                options={creditYearOptions}
                value={year}
                onChange={(value: ValueOpt<number>) => handleExpirationChange("year", value.value)}
                placeholder="Year"
              />
            </FormColumn>
            <FormColumn width={2}>
              <Input
                type="number"
                label="CVV"
                id="cvv"
                value={cc.cvv}
                placeholder="# # #"
                onChange={(value) => handleCvvChange(value, cc.ccType)}
                required
                gutterBottom
              />
            </FormColumn>
          </div>
          <LeftDefault stacked={stacked}>{defaultCardCheckbox}</LeftDefault>
        </FormColumn>
        <FormColumn width={stacked ? 1 : 2}>
          <div className="row">
            <FormColumn width={2}>
              <Input
                required
                id="billingFirstName"
                label="Billing First Name"
                value={cc.firstName}
                onChange={(value: string) => handleChange("firstName", value)}
                gutterBottom
              />
            </FormColumn>
            <FormColumn width={2}>
              <Input
                label="Billing Last Name"
                id="billingLastName"
                required
                value={cc.lastName}
                onChange={(value: string) => handleChange("lastName", value)}
                gutterBottom
              />
            </FormColumn>
            <FormColumn width={1}>
              <Select
                id="billingCountry"
                label="Billing Country"
                required
                gutterBottom
                options={billingCountryOptions}
                value={billingCountryOptions.find((option) => option.value.userBillingCountryId === cc.countryId)}
                onChange={(value: ValueOpt<UserBillingCountry>) =>
                  handleChange("countryId", value.value.userBillingCountryId)
                }
              />
            </FormColumn>
            <FormColumn width={1}>
              <Input
                label="Billing Address"
                id="billingAddress"
                required
                value={cc.addressLine1}
                onChange={(value: string) => handleChange("addressLine1", value)}
                gutterBottom
              />
              <Input
                id="addressLine2"
                value={cc.addressLine2}
                onChange={(event) => handleChange("addressLine2", event.target.value)}
                gutterBottom
              />
            </FormColumn>
            <FormColumn width={1}>
              <Input
                id="city"
                label="City"
                required
                gutterBottom
                value={cc.city}
                onChange={(value: string) => handleChange("city", value)}
              />
            </FormColumn>
            <FormColumn width={2}>
              <Select
                id="state"
                label="State"
                required={StatedRequiredCountryIds.includes(cc?.countryId)}
                gutterBottom
                options={displayedStateOptions}
                value={stateOptions.find((option: ValueOpt<State>) => option.value.name === cc.state)}
                onChange={(value) => handleChange("state", value?.value?.name)}
                clearable
              />
            </FormColumn>
            <FormColumn width={2}>
              <Input
                id="zipcode"
                required
                label="Zip Code"
                value={cc.zip}
                onChange={(value: string) => handleChange("zip", value)}
              />
            </FormColumn>
            <FormColumn width={1}>
              <PhoneNumber
                id="phoneNumber"
                label="Phone Number"
                required
                value={cc.phone}
                onChange={(value: string) => handleChange("phone", value)}
                gutterBottom
              />
            </FormColumn>
          </div>
          <BottomDefault stacked={stacked}>{defaultCardCheckbox}</BottomDefault>
        </FormColumn>
      </div>
    </div>
  );
};

export default CreditCardForm;

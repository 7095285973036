import { Button, FormColumn, Label, Modal, Row, Select, sort, useLoading, ValueOpt } from "best-common-react-2";
import React, { useEffect, useState } from "react";
import { saveEventOverrides } from "../../api/RequesTixApi";
import { defaultAccess, defaultCutoff } from "../../constants/CutoffConstants";
import { convertToEventTypeDTO } from "../../util/CutoffUtil";
import PersonalOnOff from "../requests/personal/PersonalOnOff";
import EventAccessAndCutoff from "./EventAccessAndCutoff";

const EventAccessBulkOverride = ({
  isOpen,
  onClose,
  onSave,
  departments,
  gamePk,
  timezone,
  personalRequestsOverride,
  setPersonalRequestsOverride,
  accessOverride,
  setAccessOverride,
  cutoffOverride,
  setCutoffOverride,
}) => {
  const { setLoading } = useLoading();
  const [selectedDepartments, setSelectedDepartments] = useState<ValueOpt<number>[]>([]);
  const [departmentOptions, setDepartmentOptions] = useState<ValueOpt<number>[]>([]);
  const [canSave, setCanSave] = useState<boolean>(false);

  const addAllDepartments = () => {
    setSelectedDepartments(departmentOptions);
  };

  const save = async () => {
    try {
      const access = accessOverride.active ? accessOverride : null;
      const cutoff = cutoffOverride.active ? cutoffOverride : null;
      const departmentIds = selectedDepartments.map((dept) => dept.value);

      setLoading(true);
      await saveEventOverrides({
        departmentIds: departmentIds,
        gamePk: parseInt(gamePk),
        personalRequestsOverride,
        accessOverride: access,
        cutoffOverride: convertToEventTypeDTO(cutoff),
      });

      if (onSave) {
        onSave();
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const options = sort(
      departments.map((dept) => ({
        label: dept.departmentName,
        value: dept.departmentId,
      })),
      "label"
    );
    setDepartmentOptions(options);
  }, [departments]);

  useEffect(() => {
    if (isOpen) {
      setAccessOverride(defaultAccess);
      setCutoffOverride(defaultCutoff);
      setPersonalRequestsOverride(null);
      setSelectedDepartments([]);
    }
  }, [isOpen]);

  useEffect(() => {
    const ableToSave =
      selectedDepartments &&
      selectedDepartments.length &&
      (accessOverride.active || cutoffOverride.active || personalRequestsOverride != null);
    setCanSave(ableToSave);
  }, [accessOverride, cutoffOverride, personalRequestsOverride, selectedDepartments]);

  return (
    <>
      {!!isOpen && (
        <Modal show={isOpen} size="xl">
          <Modal.Header close={onClose}>Bulk Override Ticket Access</Modal.Header>
          <Modal.Body>
            <Select
              isMulti
              id="departments-select"
              label={
                <div className="d-flex justify-content-between">
                  <Label htmlFor="departments-select" required>
                    Departments
                  </Label>
                  <Button variant="link" onClick={addAllDepartments}>
                    Add All
                  </Button>
                </div>
              }
              required
              options={departmentOptions}
              value={selectedDepartments}
              onChange={(value: ValueOpt<number>[]) => setSelectedDepartments(value)}
            />
            <Row className="my-2">
              <FormColumn width={2}>
                <PersonalOnOff
                  value={personalRequestsOverride}
                  onChange={(value) => setPersonalRequestsOverride(value)}
                />
              </FormColumn>
            </Row>
            <EventAccessAndCutoff
              accessOverride={accessOverride}
              setAccessOverride={setAccessOverride}
              cutoffOverride={cutoffOverride}
              setCutoffOverride={setCutoffOverride}
              timezone={timezone}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={save} disabled={!canSave}>
              Save Overrides
            </Button>
            <Button variant="default" onClick={onClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default EventAccessBulkOverride;

import { BestLabel, FormColumn4Wide } from "best-common-react";
import { useLoading } from "best-common-react-2";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getCharges, getRefunds } from "../../../api/RequesTixApi";
import { format, nextMonth, oneMonthBeforeYesterday, today, yesterday } from "../../../util/TimeUtil";
import DateRangePickerSeparate from "../../misc/DateRangePickerSeparate";
import FinanceTable from "./FinanceTable";

const FinanceReport = ({ reportData, setReportData }) => {
  const { setLoading } = useLoading();
  const { startDate, endDate } = reportData;
  const [dateRange, setDateRange] = useState({});
  const [charges, setCharges] = useState([]);
  const [refunds, setRefunds] = useState([]);

  const onChange = (key, data) => {
    setReportData({ ...reportData, [key]: data });
  };

  const updateDate = ({ fromStart, startDate, endDate }) => {
    if (fromStart) {
      onChange("startDate", format(startDate));
    } else {
      onChange("endDate", format(endDate));
    }
  };

  const getChargeData = async (startDate, endDate) => {
    try {
      const result = await getCharges(startDate, endDate);
      setCharges(result);
    } catch (e) {
      console.error(e);
    }
  };

  const getRefundData = async (startDate, endDate) => {
    try {
      const result = await getRefunds(startDate, endDate);
      setRefunds(result);
    } catch (e) {
      console.error(e);
    }
  };

  const getData = async (startDate, endDate) => {
    try {
      if (!!startDate && !!endDate) {
        setLoading(true);
        await Promise.all([getChargeData(startDate, endDate), getRefundData(startDate, endDate)]);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const newDate = {
      start: startDate ? moment(startDate) : oneMonthBeforeYesterday,
      end: endDate ? moment(endDate) : yesterday,
    };
    setDateRange(newDate);
    setReportData({ ...reportData, startDate: format(newDate.start), endDate: format(newDate.end) });
    getData(startDate, endDate);
  }, [startDate, endDate]);

  useEffect(() => {
    setReportData({ ...reportData, startDate: today, endDate: nextMonth });
  }, []);

  return (
    <div>
      <div className="row">
        <FormColumn4Wide>
          <BestLabel htmlFor="date">Approval Date</BestLabel>
          <DateRangePickerSeparate value={dateRange} onChange={updateDate} />
        </FormColumn4Wide>
      </div>
      <div className="row mt-2">
        <div className="col">
          <h3>Charges</h3>
          <FinanceTable data={charges} />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col">
          <h3>Refunds</h3>
          <FinanceTable data={refunds} />
        </div>
      </div>
    </div>
  );
};

export default FinanceReport;

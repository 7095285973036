export default {
  USER_REQUESTS_REPORT_ID: 1,
  PROJECT_REQUESTS_REPORT_ID: 2,
  RECONCILIATION_REPORT_ID: 3,
  FINANCE_REPORT_ID: 4,
  SEASON_TICKETS: 5,
  REQUESTS_BY_VENUE: 6,
  CLOSE_CUTOFF: 7,
  TODAYS_TICKETS: 8,
};

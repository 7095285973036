import { BestTable } from "best-common-react";
import { useLoading } from "best-common-react-2";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getProjectRequests } from "../../../api/RequesTixApi";
import { tableSort } from "../../../util/SortUtil";
import { getStatusColor, requestsFreeTextSearch } from "../../../util/TicketRequestUtil";
import { format } from "../../../util/TimeUtil";
import GameDateFormatter from "../../tables/GameDateFormatter";
import { MoneyFormatter } from "../../tables/MoneyFormatter";
import ProjectRequestsByProjectAccordion from "./ProjectRequestsByProjectAccordion";
import ProjectRequestDetails from "./ProjectRequestsDetails";
import ProjectRequestsSummaryTable from "./ProjectRequestsSummaryTable";

const EventsSize = styled.span`
  font-size: 0.8rem;
  color: ${(props) => props.theme["dark-grey"]};
  font-style: italic;
`;

const Expansion = ({ value }) => (
  <div className="py-2 px-3 ">
    <ProjectRequestDetails value={value} />
  </div>
);

const Columns = [
  {
    name: "Status",
    key: "statusName",
    sortable: true,
    width: 100,
  },
  {
    name: "Requester",
    key: "requester",
    sortable: true,
    minWidth: 180,
  },
  {
    name: "Game",
    key: "gameDate",
    sortable: true,
    formatter: GameDateFormatter,
    minWidth: 340,
  },
  {
    name: "Qty",
    key: "quantity",
    sortable: true,
    width: 100,
  },
  {
    name: "Business",
    key: "business",
    sortable: true,
    width: 180,
  },
  {
    name: "Fulfillment",
    key: "fulfillmentType",
    sortable: true,
    width: 140,
  },
  {
    name: "Total Ticket Cost",
    key: "totalTicketCost",
    sortable: true,
    formatter: MoneyFormatter,
    width: 150,
  },
  {
    name: "Home Team",
    key: "homeTeam",
    sortable: true,
    width: 200,
  },
];

const ProjectRequestsByProject = ({ reportData, dateRange, setCanExport }) => {
  const { setLoading } = useLoading();
  const [data, setData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [sortFilters, setSortFilters] = useState({ key: "gameDate", direction: BestTable.ASC });

  const onSort = (key, direction) => setSortFilters({ key, direction });

  const rowStylesGetter = (index, value) => {
    if (value) {
      const color = getStatusColor(value.requestId, value.status.requestStateId, false);
      return { background: color, transition: "background-color 2s ease 2s" };
    } else {
      return {};
    }
  };

  useEffect(() => {
    if (reportData.projectId && dateRange.start && dateRange.end) {
      setLoading(true);
      getProjectRequests(
        reportData.projectId,
        format(dateRange.start),
        format(dateRange.end),
        reportData.statusId,
        reportData.fulfillmentTypeId
      ).then((data) => {
        if (data) {
          setData(
            data.map((d) => ({
              ...d,
              gameDate: d.game ? d.game.gameDate : null,
              homeTeam: d.game ? d.game.teams.home.team.name : null,
              statusName: d.status.requestStateName,
            }))
          );
        }
        setLoading(false);
        setCanExport(!!data.length);
      });
    }
  }, [reportData.projectId, dateRange.start, dateRange.end, reportData.statusId, reportData.fulfillmentTypeId]);

  useEffect(() => {
    const filteredData = requestsFreeTextSearch(data, reportData.search);
    setSortedData(tableSort(filteredData, sortFilters, "gameDate"));
  }, [data, sortFilters, reportData.search]);

  return (
    <>
      <div className="mb-2 mt-2 mt-md-3">
        <ProjectRequestsSummaryTable
          projectId={reportData.projectId}
          startDate={format(dateRange.start)}
          endDate={format(dateRange.end)}
        />
      </div>
      <EventsSize>
        {sortedData.length === data.length ? data.length : `${sortedData.length} of ${data.length}`} requests
      </EventsSize>
      {!!data.length && (
        <BestTable
          data={sortedData}
          columns={Columns}
          rowHeight={46}
          accordion={ProjectRequestsByProjectAccordion}
          expansion={Expansion}
          rowStylesGetter={rowStylesGetter}
          sortColumn={sortFilters.key}
          sortDirection={sortFilters.direction}
          sortFunction={onSort}
        />
      )}
    </>
  );
};

export default ProjectRequestsByProject;

import { BestDropdown, BestLabel, BestSelect, FormColumn4Wide, Input } from "best-common-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { format, oneMonthBeforeYesterday, yesterday } from "../../../util/TimeUtil";
import DateRangePickerSeparate from "../../misc/DateRangePickerSeparate";
import ReconciliationDataTable from "./ReconciliationDataTable";

const ReconciliationReport = ({ reportData, setReportData, location, setCanExport }) => {
  const { search, fulfillmentTypeId, homeTeamId, startDate, endDate } = reportData;
  const [dateRange, setDateRange] = useState({});

  const { requestFulfillmentTypesOptions, getOptionByValue, teamsOptions } = useDropdowns();

  const fulfillmentTypesOptions = [{ label: "All", value: {} }].concat(
    requestFulfillmentTypesOptions.filter((opt) => opt.label.length)
  );

  const onChange = (key, data) => {
    setReportData({ ...reportData, [key]: data });
  };

  const updateDate = ({ fromStart, startDate, endDate }) => {
    if (fromStart) {
      onChange("startDate", format(startDate));
    } else {
      onChange("endDate", format(endDate));
    }
  };

  useEffect(() => {
    const newDate = {
      start: startDate ? moment(startDate) : oneMonthBeforeYesterday,
      end: endDate ? moment(endDate) : yesterday,
    };
    setDateRange(newDate);
    setReportData({ ...reportData, startDate: format(newDate.start), endDate: format(newDate.end) });
  }, [startDate, endDate]);

  return (
    <div>
      <div className="row">
        <FormColumn4Wide>
          <BestLabel htmlFor="date">Approval Date</BestLabel>
          <DateRangePickerSeparate
            value={dateRange}
            onChange={updateDate}
            // maxStartDate={yesterday}
            // maxEndDate={yesterday}
          />
        </FormColumn4Wide>
        <FormColumn4Wide>
          <BestLabel htmlFor="fulfillment">Fulfillment</BestLabel>
          <BestSelect
            id="fulfillment"
            options={fulfillmentTypesOptions}
            value={getOptionByValue(fulfillmentTypesOptions, fulfillmentTypeId ? fulfillmentTypeId * 1 : {})}
            onChange={(value) => {
              const val = typeof value.value === "object" ? null : value.value;
              onChange("fulfillmentTypeId", val);
            }}
          />
        </FormColumn4Wide>
        <FormColumn4Wide>
          <div className="form-group">
            <BestDropdown
              id="home-team-dropdown"
              title="Home Team"
              options={teamsOptions}
              value={teamsOptions.find((opt) => {
                if (!!homeTeamId) {
                  return opt.value.id === homeTeamId;
                } else {
                  return !opt.value.id;
                }
              })}
              onChange={(team) => onChange("homeTeamId", team.value.id || null)}
            />
          </div>
        </FormColumn4Wide>
        <FormColumn4Wide>
          <BestLabel htmlFor="search">Search Requests</BestLabel>
          <Input
            id="search"
            value={search}
            onChange={(event) => {
              onChange("search", event.target.value);
            }}
            placeholder="begin typing to filter requests"
          />
        </FormColumn4Wide>
      </div>
      <div className="row">
        <div className="col">
          <ReconciliationDataTable
            reportData={reportData}
            dateRange={dateRange}
            location={location}
            setCanExport={setCanExport}
          />
        </div>
      </div>
    </div>
  );
};

export default ReconciliationReport;

import { BestTable } from "best-common-react";
import { useLoading } from "best-common-react-2";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getAllUsersRequests, runReport } from "../../../api/RequesTixApi";
import FulfillmentTypeConstants from "../../../constants/FulfillmentTypeConstants";
import GameQueryConstants from "../../../constants/GameQueryConstants";
import ReportConstants from "../../../constants/ReportConstants";
import { APPROVED, DENIED } from "../../../constants/RequestTableConstants";
import UserAllotmentConstants from "../../../constants/UserAllotmentConstants";
import { downloadLink, FileTypes } from "../../../util/FileUtil";
import { createRouteSearch } from "../../../util/RouteUtil";
import { sortWithFunction } from "../../../util/SortUtil";
import { includes, toLower } from "../../../util/StringUtil";
import ExportFormatter from "../formatters/ExportFormatter";
import ToLinkFormatter from "../formatters/ToLinkFormatter";
import AllUsersAccordion from "./AllUsersAccordion";

const TotalRequests = styled.div`
  font-size: 0.8rem;
  color: ${(props) => props.theme["dark-grey"]};
  font-style: italic;
  margin-top: 0.5rem;
`;

const Columns = [
  {
    name: "",
    key: "requesterId",
    width: 30,
    formatter: ExportFormatter,
    sortable: true,
  },
  {
    name: "User",
    key: "recipient",
    minWidth: 200,
    formatter: ToLinkFormatter,
    sortable: true,
  },
  {
    name: "Department",
    key: "department",
    minWidth: 240,
    formatter: ToLinkFormatter,
    sortable: true,
  },
  {
    name: "Approved",
    key: "personalApproved",
    width: 90,
    cellClass: "text-center",
    formatter: ToLinkFormatter,
    sortable: true,
  },
  {
    name: "Comp",
    key: "personalComp",
    width: 75,
    cellClass: "text-center",
    formatter: ToLinkFormatter,
    sortable: true,
  },
  {
    name: "Personal Card",
    key: "personalCard",
    width: 70,
    cellClass: "text-center",
    formatter: ToLinkFormatter,
    sortable: true,
  },
  {
    name: "Denied",
    key: "personalDenied",
    width: 75,
    cellClass: "text-center",
    formatter: ToLinkFormatter,
    sortable: true,
  },
  {
    name: "Total",
    key: "personalTotal",
    width: 70,
    cellClass: "text-center",
    formatter: ToLinkFormatter,
    sortable: true,
  },
  {
    name: "Approved",
    key: "businessApproved",
    width: 75,
    cellClass: "text-center",
    formatter: ToLinkFormatter,
    headerBackgroundColor: "#e5e6e8",
    sortable: true,
  },
  {
    name: "Comp",
    key: "businessComp",
    width: 75,
    cellClass: "text-center",
    formatter: ToLinkFormatter,
    headerBackgroundColor: "#e5e6e8",
    sortable: true,
  },
  {
    name: "Business Card",
    key: "businessCard",
    width: 70,
    cellClass: "text-center",
    formatter: ToLinkFormatter,
    headerBackgroundColor: "#e5e6e8",
    sortable: true,
  },
  {
    name: "Dept",
    key: "businessDept",
    width: 75,
    cellClass: "text-center",
    formatter: ToLinkFormatter,
    headerBackgroundColor: "#e5e6e8",
    sortable: true,
  },
  {
    name: "Denied",
    key: "businessDenied",
    width: 75,
    cellClass: "text-center",
    formatter: ToLinkFormatter,
    headerBackgroundColor: "#e5e6e8",
    sortable: true,
  },
  {
    name: "Total",
    key: "businessTotal",
    width: 70,
    cellClass: "text-center",
    formatter: ToLinkFormatter,
    headerBackgroundColor: "#e5e6e8",
    sortable: true,
  },
];

const massageData = (data, seasonId, departmentId, location, generateReport) => {
  const to = location.pathname;
  return data.map((d) => ({
    requesterId: {
      value: d.requesterId,
      onClick: generateReport,
    },
    recipient: {
      value: d.requester,
      to: to,
      search: createRouteSearch({
        seasonId,
        departmentId,
        userId: d.requesterId,
      }),
    },
    department: {
      value: d.department,
      to: to,
      search: createRouteSearch({
        seasonId,
        departmentId: d.departmentId,
      }),
    },
    personalComp: {
      value: d.personal.comp,
      to: to,
      search: createRouteSearch({
        seasonId,
        departmentId,
        userId: d.requesterId,
        categoryId: UserAllotmentConstants.PERSONAL_ID,
        fulfillmentTypeId: FulfillmentTypeConstants.COMP,
      }),
    },
    personalApproved: {
      value: d.personal.approved,
      to: to,
      search: createRouteSearch({
        seasonId,
        departmentId,
        userId: d.requesterId,
        categoryId: UserAllotmentConstants.PERSONAL_ID,
        statusId: APPROVED,
      }),
    },
    personalCard: {
      value: d.personal.card,
      to: to,
      search: createRouteSearch({
        seasonId,
        departmentId,
        userId: d.requesterId,
        categoryId: UserAllotmentConstants.PERSONAL_ID,
        fulfillmentTypeId: FulfillmentTypeConstants.CHARGE_CARD,
      }),
    },
    personalDenied: {
      value: d.personal.denied,
      to: to,
      search: createRouteSearch({
        seasonId,
        departmentId,
        userId: d.requesterId,
        categoryId: UserAllotmentConstants.PERSONAL_ID,
        statusId: DENIED,
      }),
    },
    personalTotal: {
      value: d.personal.total,
      to: to,
      search: createRouteSearch({
        seasonId,
        departmentId,
        userId: d.requesterId,
        categoryId: UserAllotmentConstants.PERSONAL_ID,
      }),
    },
    businessComp: {
      value: d.business.comp,
      to: to,
      search: createRouteSearch({
        seasonId,
        departmentId,
        userId: d.requesterId,
        categoryId: UserAllotmentConstants.BUSINESS_ID,
        fulfillmentTypeId: FulfillmentTypeConstants.COMP,
      }),
    },
    businessApproved: {
      value: d.business.approved,
      to: to,
      search: createRouteSearch({
        seasonId,
        departmentId,
        userId: d.requesterId,
        categoryId: UserAllotmentConstants.BUSINESS_ID,
        statusId: APPROVED,
      }),
    },
    businessCard: {
      value: d.business.card,
      to: to,
      search: createRouteSearch({
        seasonId,
        departmentId,
        userId: d.requesterId,
        categoryId: UserAllotmentConstants.BUSINESS_ID,
        fulfillmentTypeId: FulfillmentTypeConstants.CHARGE_CARD,
      }),
    },
    businessDept: {
      value: d.business.dept,
      to: to,
      search: createRouteSearch({
        seasonId,
        departmentId,
        userId: d.requesterId,
        categoryId: UserAllotmentConstants.BUSINESS_ID,
        fulfillmentTypeId: FulfillmentTypeConstants.CHARGE_DEPT,
      }),
    },
    businessDenied: {
      value: d.business.denied,
      to: to,
      search: createRouteSearch({
        seasonId,
        departmentId,
        userId: d.requesterId,
        categoryId: UserAllotmentConstants.BUSINESS_ID,
        statusId: DENIED,
      }),
    },
    businessTotal: {
      value: d.business.total,
      to: to,
      search: createRouteSearch({
        seasonId,
        departmentId,
        userId: d.requesterId,
        categoryId: UserAllotmentConstants.BUSINESS_ID,
      }),
    },
  }));
};

const secondarySort = (a, b) => (a.submitted > b.submitted ? 1 : a.submitted < b.submitted ? -1 : 0);

const freeTextFilter = (requests, search) =>
  search && search.length
    ? requests.filter(
        (request) => includes(request.recipient.value, search) || includes(request.department.value, search)
      )
    : requests;

const AllUsersRequests = ({ seasonId, departmentId, search, location, setCanExport }) => {
  const { setLoading } = useLoading(true);
  const [requests, setRequests] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [sortFilters, setSortFilters] = useState({ key: "recipient", direction: BestTable.ASC });

  const onSort = (key, direction) => setSortFilters({ key, direction });

  const generateReport = (userId) => {
    setLoading(true);
    runReport(ReportConstants.USER_REQUESTS_REPORT_ID, { seasonId, departmentId, userId: userId }).then((response) => {
      downloadLink(response, FileTypes.EXCEL);
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    getAllUsersRequests(GameQueryConstants.PAST_ID, null, "", seasonId, null, departmentId).then((data) => {
      setRequests(massageData(data, seasonId, departmentId, location, generateReport));
      setLoading(false);
      setCanExport(!!data.length);
    });
  }, [seasonId, departmentId]);

  useEffect(() => {
    if (requests) {
      const dir = sortFilters.direction === BestTable.ASC ? 1 : sortFilters.direction === BestTable.DESC ? -1 : 0;
      setDisplayData(
        sortWithFunction(
          freeTextFilter(requests, search),
          sortFilters.key,
          dir,
          (value) => (value ? toLower(value.value) : ""),
          secondarySort
        )
      );
    } else {
      setDisplayData([]);
    }
  }, [requests, search, sortFilters]);

  const displayText =
    requests.length === displayData.length
      ? `${displayData.length} users`
      : `${displayData.length} of ${requests.length} users`;

  return (
    <div>
      <TotalRequests>{displayText}</TotalRequests>
      <BestTable
        columns={Columns}
        data={displayData}
        accordion={AllUsersAccordion}
        headerHeight={50}
        sortColumn={sortFilters.key}
        sortDirection={sortFilters.direction}
        sortFunction={onSort}
      />
    </div>
  );
};

export default AllUsersRequests;

import { Button, FormColumn, Modal, Row, StickyFooterButtons } from "best-common-react-2";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PersonalOnOff from "../requests/personal/PersonalOnOff";
import EventAccessAndCutoff from "./EventAccessAndCutoff";

const Container = styled.div`
  padding: 1rem;
`;

const DepartmentHolder = styled.div`
  font-size: 14px;
  margin-bottom: 1rem;
`;

const DepartmentHeader = styled.span`
  display: block;
  font-weight: bold;
  color: black;
`;

const DepartmentValue = styled.span`
  display: block;
  font-weight: 300;
`;

const OverrideSaveModal = ({
  personalRequestsOverride,
  setPersonalRequestsOverride,
  accessOverride,
  setAccessOverride,
  cutoffOverride,
  setCutoffOverride,
  department = "",
  show = false,
  onSave,
  onClose,
  isEdit = false,
  hasEdited,
  timezone = "America/New_York",
}) => {
  const [canSave, setCanSave] = useState(false);

  useEffect(() => {
    const accessValid =
      (accessOverride.useDate && accessOverride.date) || (!accessOverride.useDate && accessOverride.hoursBefore > 0);
    const cutoffPersonalValid =
      (cutoffOverride.personalCutoffUseDate && cutoffOverride.personalCutoffDate) ||
      (!cutoffOverride.personalCutoffUseDate && cutoffOverride.personalCutoffHoursBefore > 0);
    const cutoffBusinessValid =
      (cutoffOverride.businessCutoffUseDate && cutoffOverride.businessCutoffDate) ||
      (!cutoffOverride.businessCutoffUseDate && cutoffOverride.businessCutoffHoursBefore > 0);
    const cutoffValid = cutoffPersonalValid && cutoffBusinessValid;
    if (!hasEdited) {
      setCanSave(false);
    } else if (accessOverride.active && cutoffOverride.active) {
      setCanSave(accessValid && cutoffValid);
    } else if (accessOverride.active) {
      setCanSave(accessValid);
    } else if (cutoffOverride.active) {
      setCanSave(cutoffValid);
    } else if (personalRequestsOverride != null) {
      setCanSave(true);
    } else {
      setCanSave(isEdit);
    }
  }, [accessOverride, cutoffOverride, personalRequestsOverride]);

  return (
    <Modal show={show} size="xl">
      <Modal.Header close={onClose}>Override Ticket Access</Modal.Header>
      <Modal.Body>
        <Container>
          <DepartmentHolder>
            <DepartmentHeader>Department</DepartmentHeader>
            <DepartmentValue>{department}</DepartmentValue>
          </DepartmentHolder>
          <Row className="mb-3">
            <FormColumn width={2}>
              <PersonalOnOff
                value={personalRequestsOverride}
                onChange={(value) => setPersonalRequestsOverride(value)}
              />
            </FormColumn>
          </Row>
          <EventAccessAndCutoff
            accessOverride={accessOverride}
            setAccessOverride={setAccessOverride}
            cutoffOverride={cutoffOverride}
            setCutoffOverride={setCutoffOverride}
            timezone={timezone}
          />
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <StickyFooterButtons>
          <Button variant="primary" className="me-2" disabled={!canSave} onClick={onSave}>
            Save Override
          </Button>
          <Button variant="default" onClick={onClose}>
            Cancel
          </Button>
        </StickyFooterButtons>
      </Modal.Footer>
    </Modal>
  );
};

export default OverrideSaveModal;

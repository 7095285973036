import { Button, Modal, useLoading } from "best-common-react-2";
import React, { memo, useState } from "react";
import { bulkRefundGame } from "../../../api/RequesTixApi";
import { GamePreviewDTO } from "../../../types/Game";
import { TicketRequestDTO } from "../../../types/TicketRequest";
import GamePreviewDisplay from "../../game/GamePreviewDisplay";
import AdminRequestTable from "./AdminRequestTable";

type RefundGameModalProps = {
  show: boolean;
  game: GamePreviewDTO;
  onClose: () => void;
};

const RefundGameModal = ({ show, game, onClose }: RefundGameModalProps) => {
  const { setLoading } = useLoading();

  const refundGame = async () => {
    if (game.gamePk) {
      setLoading(true);
      await bulkRefundGame(game.gamePk);
      onClose();
      setLoading(false);
    }
  };

  return (
    <Modal show={show} size="lg">
      <Modal.Header close={onClose}>Are you sure?</Modal.Header>
      <Modal.Body>
        <GamePreviewDisplay game={game} />
        <div className="mt-3">
          <b>Are you sure you want to bulk refund this game?</b>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <Button variant="primary" className="me-2" disabled={!game.gamePk} onClick={() => refundGame()}>
            Refund Game
          </Button>
          <Button variant="default" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

type AdminRequestsGameProps = {
  index?: number;
  game: GamePreviewDTO;
  requests: TicketRequestDTO[];
  isVisible?: boolean;
  showHeader?: boolean;
  totalRequests: number;
  jumpToRequestId?: number;
};

const AdminRequestsGame = memo(
  ({ game, requests = [], showHeader = false, isVisible = false, jumpToRequestId }: AdminRequestsGameProps) => {
    const [showRefund, setShowRefund] = useState(false);

    return (
      <div>
        {!!game && (
          <div className="d-flex align-items-center mt-2">
            <GamePreviewDisplay game={game} />
            <RefundGameModal game={game} show={showRefund} onClose={() => setShowRefund(false)} />
            <Button variant="default" className="ms-2" onClick={() => setShowRefund(true)}>
              Refund Game
            </Button>
          </div>
        )}

        {!!(requests.length > 0 || showHeader) && (
          <AdminRequestTable requests={requests} jumpToRequestId={jumpToRequestId} visible={isVisible} />
        )}
      </div>
    );
  }
);

export default AdminRequestsGame;

import { Button, FormColumn, Modal, Row, useLoading, YearSelect } from "best-common-react-2";
import React, { useEffect, useState } from "react";
import { putCopyVenuesFromYearToYear } from "../../api/RequesTixApi";

const startValue = new Date().getFullYear();
const CopyVenueDataModal = ({ isOpen, setIsOpen }) => {
  const { setLoading } = useLoading();
  const [baseYear, setBaseYear] = useState<number>(startValue);
  const [toYear, setToYear] = useState<number>(startValue);
  const [canSave, setCanSave] = useState(false);

  const copyData = async () => {
    try {
      setLoading(true);
      await putCopyVenuesFromYearToYear(baseYear, toYear);
      setIsOpen(false);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setCanSave(!!baseYear && !!toYear && baseYear !== toYear);
  }, [baseYear, toYear]);

  const endYear = new Date().getFullYear() + 3;

  return (
    <Modal show={isOpen} size="lg">
      <Modal.Header close={onClose}>Copy Venue Information</Modal.Header>
      <Modal.Body>
        <Row>
          <FormColumn width={2}>
            <YearSelect
              id="from-year"
              label="From Year"
              value={baseYear}
              onChange={(value: number) => setBaseYear(value)}
              minYear={2019}
              maxYear={endYear}
            />
          </FormColumn>
          <FormColumn width={2}>
            <YearSelect
              id="to-year"
              label="To"
              value={toYear}
              onChange={(value: number) => setToYear(value)}
              minYear={2019}
              maxYear={endYear}
            />
          </FormColumn>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="me-2" disabled={!canSave} onClick={copyData}>
          Copy Data
        </Button>
        <Button variant="default" onClick={onClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CopyVenueDataModal;

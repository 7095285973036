import React from "react";
import { formatGameDate } from "../../util/TimeUtil";
import TeamLogo from "./TeamLogo";

type SimpleGameMatchupProps = {
  gameDate: string | Date;
  awayTeamId: number;
  homeTeamId: number;
};

const SimpleGameMatchup: React.FC<SimpleGameMatchupProps> = ({ gameDate, homeTeamId, awayTeamId }) => (
  <div className="d-flex align-items-center">
    <div>{formatGameDate(gameDate)}</div>
    <div className="d-flex align-items-center ms-2 justify-content-between">
      <TeamLogo teamId={awayTeamId} />
      <div className="mx-1">@</div>
      <TeamLogo teamId={homeTeamId} />
    </div>
  </div>
);

export default SimpleGameMatchup;

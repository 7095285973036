import {
  Card,
  DateRange,
  DateRangeInput,
  DATE_FORMATTER_STR,
  formatDate,
  FormColumn,
  Row,
  Search,
  Select,
  useLoading,
  ValueOpt,
} from "best-common-react-2";
import React, { useEffect } from "react";
import { StaticContext } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { getAdminSchedule } from "../../../api/RequesTixApi";
import EventsTable from "../../../components/events/EventsTable";
import RouteConstants from "../../../constants/RouteConstants";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { AdminGameDTO } from "../../../types/Game";
import { LocationState } from "../../../types/Routing";
import { Team } from "../../../types/Team";
import { nextMonthStr, todayStr } from "../../../util/DateObjUtil";
import { updateRouteParams, useRouteParams } from "../../../util/RouteUtil";
import { getTeamOption } from "../../../util/TeamUtil";
import { format, isDayBefore } from "../../../util/TimeUtil";

const AdminEvents = ({ history, location }: RouteComponentProps<any, StaticContext, LocationState>) => {
  const { teamsOptions } = useDropdowns();
  const { loading, setLoadingNonBlocking } = useLoading();
  const [events, setEvents] = React.useState<AdminGameDTO[]>([]);
  const { startDate = todayStr, endDate = nextMonthStr, teamId, searchTerm = "" } = useRouteParams(location.search);

  const dateRange: DateRange<Date> = {
    start: new Date(Date.parse(startDate)),
    end: new Date(Date.parse(endDate)),
  };

  const selectedHomeTeam = getTeamOption(teamId, teamsOptions);

  const updateUrl = ({ startDate, endDate, teamId, searchTerm }) => {
    updateRouteParams(history, RouteConstants.ADMIN.EVENTS, {
      startDate: format(startDate),
      endDate: format(endDate),
      teamId,
      searchTerm,
    });
  };

  const updateDate = (dateRange: DateRange<Date>) => {
    updateUrl({
      startDate: formatDate(dateRange.start, DATE_FORMATTER_STR),
      endDate: formatDate(dateRange.end, DATE_FORMATTER_STR),
      teamId,
      searchTerm,
    });
  };

  const fetchEvents = async (startDate: string, endDate: string, teamId?: number) => {
    try {
      if (isDayBefore(startDate, endDate)) {
        setLoadingNonBlocking(true);
        const res: AdminGameDTO[] = await getAdminSchedule(teamId, null, startDate, endDate);
        setEvents(res);
      }
    } catch (e) {
      console.error(e);
      setEvents([]);
    } finally {
      setLoadingNonBlocking(false);
    }
  };

  useEffect(() => {
    void fetchEvents(startDate, endDate, teamId);
  }, [startDate, endDate, teamId]);

  return (
    <Card>
      <Card.Header>Events</Card.Header>
      <Card.Body>
        <Row>
          <FormColumn width={4}>
            <DateRangeInput id="event-dates" label="Event Date" value={dateRange} onChange={updateDate} />
          </FormColumn>
          <FormColumn width={4}>
            <Select
              id="hometeam-dropdown"
              label="Home Team"
              options={teamsOptions}
              value={selectedHomeTeam}
              onChange={(team: ValueOpt<Team>) => updateUrl({ teamId: team.value.id, startDate, endDate, searchTerm })}
            />
          </FormColumn>
          <FormColumn width={4}>
            <Search
              id="search-box"
              label="Search Events"
              placeholder="begin typing to filter events"
              value={searchTerm}
              onChange={(value) => updateUrl({ teamId, startDate, endDate, searchTerm: value })}
            />
          </FormColumn>
        </Row>
        <EventsTable values={events} searchTerm={searchTerm} location={location} loading={loading} />
      </Card.Body>
    </Card>
  );
};

export default AdminEvents;

import { Button } from "best-common-react-2";
import React from "react";
import useCyberSource from "../../util/CreditCardUtil";
import CreditCardForm from "./CreditCardForm";

const NewCreditCardForm = ({ show, onClose, userId, refresh }) => {
  const { creditCard, setCreditCard, validCreditCard, onSave, onCancel } = useCyberSource(null, userId);
  const [number, setNumber] = React.useState("");
  const [valid, setValid] = React.useState(false);

  const close = () => {
    onCancel();
    onClose();
  };

  const save = () => {
    onSave(number).then(() => {
      refresh();
      close();
    });
  };

  React.useEffect(() => {
    setValid(validCreditCard(creditCard));
  }, [creditCard]);

  return (
    <>
      {!!show && (
        <div className="mb-3">
          <CreditCardForm cc={creditCard} setCC={setCreditCard} number={number} setNumber={setNumber} />
          <div className="mt-3 container" style={{ margin: 0 }}>
            <div className="d-flex justify-content-end">
              <Button variant="primary" className="me-2" disabled={!valid} onClick={save}>
                Save
              </Button>
              <Button variant="default" onClick={close}>
                Close
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NewCreditCardForm;

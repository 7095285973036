import { getStatsEvent } from "../api/StatsApi";
import { StatsApiGame, StatsApiSchedule } from "../types/Stats";

export const getGameFromGamePk = async (gamePk: string | number): Promise<StatsApiGame | undefined> => {
  const data: StatsApiSchedule = await getStatsEvent(gamePk);
  if (data.dates?.length) {
    const games: StatsApiGame[] = data.dates[0].games;
    if (games.length) {
      return games[0];
    }
  }
  return undefined;
};

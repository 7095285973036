import React from "react";
import styled from "styled-components";
import CreditCardMappings from "../../constants/CreditCardMappings";
import { BillingDTO } from "../../types/UserBilling";
import { CreditCardIcon } from "./CreditCard";

const Label = styled.div`
  margin-left: 0.75rem;
  font-size: 0.875rem;
  font-weight: 300;
`;

type CreditCardDisplayProps = {
  card?: BillingDTO;
};
const CreditCardDisplay: React.FC<CreditCardDisplayProps> = ({ card = {} }) => {
  const { ccType, ccNickname, ccLast4 } = card;

  return (
    <>
      {ccNickname && ccType && (
        <div className="d-flex align-items-center">
          <div>
            <CreditCardIcon type={CreditCardMappings[ccType.ccTypeName]} size={1.5} />
          </div>
          <Label>
            {ccNickname} ending in {ccLast4}
          </Label>
        </div>
      )}
    </>
  );
};

export default CreditCardDisplay;

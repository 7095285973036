import { Link } from "best-common-react-2";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getUserAllotmentCounts } from "../../../api/RequesTixApi";
import RouteConstants from "../../../constants/RouteConstants";
import { UserAllotmentCountDTO } from "../../../types/Allotment";
import { RequestTypeDesc } from "../../../types/RequestType";
import { SeasonTypeCode } from "../../../types/Season";
import { REGULAR_SEASON, seasonTypesCodes, SPRING_TRAINING } from "../../../util/SeasonUtil";
import { isBusiness } from "../../../util/TicketRequestUtil";

const TableRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.875rem;
  padding: 0 0.5rem;
  background-color: #fff;
  border: 1px solid ${(props) => props.theme["grey"]};
  border-top: 0;
  font-size: 0.8rem;
`;

const TableHeader = styled(TableRow)`
  border-top: 1px solid ${(props) => props.theme["grey"]};
  background-color: #eceeef;
  font-size: 0.8rem;
`;

const PendingText = styled.div`
  color: ${(props) => props.theme["sky-blue"]};
  font-weight: bold;
  font-style: oblique;
`;

type SeasonSelectorProps = {
  selected: boolean;
};

const SeasonSelector = styled.div<SeasonSelectorProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
  width: 50%;
  font-size: 0.8rem;
  font-weight: bold;
  background-color: ${(props) => (props.selected ? "#fff" : "#eceeef")};
  border: ${(props) => (props.selected ? `1px solid ${props.theme["grey"]}` : "")};
  color: ${(props) => (props.selected ? `${props.theme["dark-grey"]}` : `${props.theme["grey"]}`)};
  border-radius: 6px;
  cursor: ${(props) => (props.selected ? "default" : "pointer")};
`;

const AllotmentType = styled.b`
  color: #000;
  margin-right: 1rem;
`;

const AllotmentText = styled.span`
  color: ${(props) => props.theme["dark-grey"]}
  font-weight: 300;
  font-style: oblique;
`;

const sort = (data) =>
  data.sort((a, b) => (a.allotmentType.allotmentTypeId > b.allotmentType.allotmentTypeId ? 1 : -1));

type SeasonSelectProps = {
  year: number;
  userId?: number;
  seasonType: SeasonTypeCode;
  setSeasonType: (value: SeasonTypeCode) => void;
  setAllotments: (value: UserAllotmentCountDTO[]) => void;
  type?: RequestTypeDesc;
  pendingLink?: boolean;
};

const SeasonSelect: React.FC<SeasonSelectProps> = ({
  year,
  userId,
  seasonType,
  setSeasonType,
  setAllotments,
  type,
  pendingLink = false,
}) => {
  const [allotmentCounts, setAllotmentCounts] = useState([]);
  const [seasonAllotments, setSeasonAllotments] = useState([]);

  const fetchAllotments = async (userId: number, year: number) => {
    try {
      const data: UserAllotmentCountDTO[] = await getUserAllotmentCounts(userId, year);
      setAllotmentCounts(data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (year && userId) {
      void fetchAllotments(userId, year);
    }
  }, [userId, year]);

  useEffect(() => {
    const sorted: UserAllotmentCountDTO[] = sort(allotmentCounts);
    const filtered: UserAllotmentCountDTO[] = sorted.filter((allotment: UserAllotmentCountDTO) => {
      const correctSeason = seasonType.id === allotment.season.seasonType.seasonTypeId;
      let correctType = true;
      if (type) {
        if (isBusiness(type)) {
          correctType = allotment.allotmentType.allotmentTypeId === 2;
        } else {
          correctType = allotment.allotmentType.allotmentTypeId === 1;
        }
      }
      return correctSeason && correctType;
    });
    setSeasonAllotments(filtered);
    if (setAllotments) {
      setAllotments(sorted);
    }
  }, [allotmentCounts, seasonType]);

  return (
    <div>
      <TableHeader>
        <SeasonSelector
          selected={seasonType.id === seasonTypesCodes[SPRING_TRAINING].id}
          onClick={
            seasonType.id !== seasonTypesCodes[SPRING_TRAINING].id
              ? () => {
                  setSeasonType(seasonTypesCodes[SPRING_TRAINING]);
                }
              : null
          }
        >
          Spring
        </SeasonSelector>
        <SeasonSelector
          selected={seasonType.id === seasonTypesCodes[REGULAR_SEASON].id}
          onClick={
            seasonType.id !== seasonTypesCodes[REGULAR_SEASON].id
              ? () => {
                  setSeasonType(seasonTypesCodes[REGULAR_SEASON]);
                }
              : null
          }
        >
          Regular
        </SeasonSelector>
      </TableHeader>
      <>
        {seasonAllotments.map((allotment) => (
          <div key={allotment.season.seasonId + allotment.allotmentType.allotmentTypeName}>
            <TableRow>
              <div>
                <AllotmentType>{allotment.allotmentType.allotmentTypeName}</AllotmentType>
                <AllotmentText>
                  {!allotment.unlimited ? (
                    <>
                      <b>{allotment.remaining}</b> of <b>{allotment.allowed}</b> remaining
                    </>
                  ) : null}
                </AllotmentText>
              </div>
              {pendingLink ? (
                <Link
                  to={{
                    pathname: RouteConstants.REQUESTS.BASE,
                    search: `?games=1&status=1,2&category=${allotment.allotmentType.allotmentTypeId}`,
                  }}
                >
                  <PendingText>{allotment.pending > 0 ? <>({allotment.pending} pending)</> : null}</PendingText>
                </Link>
              ) : (
                <PendingText>{allotment.pending > 0 ? <>({allotment.pending} pending)</> : null}</PendingText>
              )}
            </TableRow>
          </div>
        ))}
      </>
    </div>
  );
};

export default SeasonSelect;

import { Loader, Title, ValueOpt } from "best-common-react-2";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getAdminPurchaseOpportunityEntriesForUser, getAllPurchaseOpportunityStatuses } from "../../api/RequesTixApi";
import {
  AdminPurchaseOpportunityEntryDTO,
  PurchaseOpportunityDTO,
  PurchaseOpportunityStatus,
} from "../../types/PurchaseOpportunity";
import AdminPurchaseOpportunityGameForm from "./AdminPurchaseOpportunityGameForm";

const GamesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

type AdminPurchaseOpportunityRequestActionsProps = {
  opportunity: PurchaseOpportunityDTO;
  userId: number | string;
  formChanged: boolean;
};

const AdminPurchaseOpportunityRequestActions: React.FC<AdminPurchaseOpportunityRequestActionsProps> = ({
  opportunity,
  userId,
  formChanged,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [entries, setEntries] = useState<AdminPurchaseOpportunityEntryDTO[]>([]);
  const [statuses, setStatuses] = useState<PurchaseOpportunityStatus[]>([]);
  const [statusOpts, setStatusOpts] = useState<ValueOpt<PurchaseOpportunityStatus>[]>([]);

  const fetchStatuses = async () => {
    try {
      const res: PurchaseOpportunityStatus[] = await getAllPurchaseOpportunityStatuses();
      setStatuses(res);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchAdminOpportunityEntries = async () => {
    try {
      setLoading(true);
      const res: AdminPurchaseOpportunityEntryDTO[] = await getAdminPurchaseOpportunityEntriesForUser(
        opportunity.purchaseOpportunityId,
        userId
      );
      setEntries(res);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void fetchStatuses();
  }, []);

  useEffect(() => {
    if (!!opportunity?.purchaseOpportunityId) {
      void fetchAdminOpportunityEntries();
    }
  }, [opportunity]);

  useEffect(() => {
    setStatusOpts(
      statuses.map((s: PurchaseOpportunityStatus) => ({
        value: s,
        label: s.description,
      }))
    );
  }, [statuses]);

  return (
    <div>
      <Title>Admin</Title>
      {loading ? (
        <Loader type="spinner" size="40px" />
      ) : (
        <GamesContainer>
          {entries.map((e: AdminPurchaseOpportunityEntryDTO) => (
            <AdminPurchaseOpportunityGameForm
              key={e.purchaseOpportunityGameId}
              entry={e}
              statusOpts={statusOpts}
              refresh={fetchAdminOpportunityEntries}
              formChanged={formChanged}
            />
          ))}
        </GamesContainer>
      )}
    </div>
  );
};

export default AdminPurchaseOpportunityRequestActions;

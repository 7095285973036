import { Table } from "best-common-react-2";
import React from "react";
import styled from "styled-components";
import { getAllotmentByRequest } from "../../../api/RequesTixApi";
import { UserAllotmentCountDTO } from "../../../types/Allotment";
import { RequestTypeDesc } from "../../../types/RequestType";
import { capitalizeFirstLetter } from "../../../util/StringUtil";

const StyledTable = styled(Table)`
  width: 300px;
`;

type RequestAllotmentTableProps = {
  requestId: number;
  category: RequestTypeDesc;
};

const RequestAllotmentTable: React.FC<RequestAllotmentTableProps> = ({ requestId, category }) => {
  const [allotment, setAllotment] = React.useState<UserAllotmentCountDTO>();

  const fetchAllotment = async (): Promise<void> => {
    try {
      const res: UserAllotmentCountDTO = await getAllotmentByRequest(requestId);
      setAllotment(res);
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    void fetchAllotment();
  }, [requestId]);

  return (
    <StyledTable>
      <Table.Head>
        <Table.Row>
          <Table.Header>Req Totals</Table.Header>
          <Table.Header style={{ borderLeft: "1px solid #dee2e6" }}>Pending</Table.Header>
          <Table.Header style={{ borderLeft: "1px solid #dee2e6" }}>Approved</Table.Header>
          <Table.Header style={{ borderLeft: "1px solid #dee2e6" }}>Allotted</Table.Header>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        <Table.Row>
          <Table.TdInfo>{capitalizeFirstLetter(category)}</Table.TdInfo>
          <Table.Td>{allotment?.pending}</Table.Td>
          <Table.Td>
            {allotment?.used} {allotment?.comped ? ` (${allotment?.comped} comp)` : ""}
          </Table.Td>
          <Table.Td>{allotment?.unlimited ? "unlimited" : allotment?.allowed}</Table.Td>
        </Table.Row>
      </Table.Body>
    </StyledTable>
  );
};

export default RequestAllotmentTable;

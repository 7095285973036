import { DataTableDataType, FormatterType } from "best-common-react-2";
import React from "react";
import { BillingDTO } from "../../types/UserBilling";
import CreditCardDisplay from "../payment/CreditCardDisplay";

const UserBillingFormatter = <T extends DataTableDataType>(props: FormatterType<T>) => {
  const { value } = props;
  return <CreditCardDisplay card={value as BillingDTO} />;
};

export default UserBillingFormatter;

import {
  Button,
  Card,
  DataTable,
  DataTableColumn,
  DateTimeFormatter,
  FormatterType,
  Icon,
  Link,
  PriceFormatter,
  StickyFooterButtons,
  TextFormatter,
  useLoading,
} from "best-common-react-2";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { getMySuiteRequests } from "../../../api/RequesTixApi";
import GameDateFormatter from "../../../components/tables/GameDateFormatter";
import UserDepartmentFormatter from "../../../components/tables/UserDepartmentFormatter";
import RouteConstants, { urlReplace } from "../../../constants/RouteConstants";
import { SuiteRequestDTO, SuiteRequestStatus, SuiteRequestStatusID, SuiteStatuses } from "../../../types/SuiteRequest";

const EditExpansionFormatter = ({ row }: FormatterType<SuiteRequestDTO>) => {
  const { suiteRequestStatus, suiteRequestId } = row ?? {};
  switch (suiteRequestStatus?.suiteStatusId) {
    case SuiteStatuses.OPEN:
      return (
        <Link
          to={{
            pathname: urlReplace(RouteConstants.REQUESTS.SUITES_REQUEST_EDIT, { suiteRequestId: suiteRequestId }),
          }}
        >
          <Icon iconName="fa-pencil-alt" />
        </Link>
      );
    case SuiteStatuses.IN_REVIEW:
    case SuiteStatuses.DENIED:
    case SuiteStatuses.APPROVED:
    default:
      return null;
  }
};

const StatusFormatter = ({ value, ...props }: FormatterType<SuiteRequestDTO>) => {
  const status: SuiteRequestStatus = value as SuiteRequestStatus;
  return <TextFormatter {...props} value={status.status} />;
};

const SuiteRequestColumns: DataTableColumn<SuiteRequestDTO>[] = [
  {
    name: "",
    key: "suiteRequestId",
    width: 35,
    readonlyFormatter: EditExpansionFormatter,
  },
  {
    name: "Game",
    key: "",
    minWidth: 340,
    readonlyFormatter: GameDateFormatter,
    sortable: true,
  },
  {
    key: "suiteRequestStatus",
    name: "Status",
    width: 200,
    readonlyFormatter: StatusFormatter,
  },
  {
    name: "Department",
    key: "onBehalfOfUser",
    readonlyFormatter: UserDepartmentFormatter,
    width: 250,
    sortable: true,
  },
  {
    name: "Capacity",
    key: "capacity",
    width: 150,
  },
  {
    name: "Budget",
    key: "ticketBudget",
    width: 120,
    readonlyFormatter: PriceFormatter,
    sortable: true,
  },
  {
    name: "Business Name",
    key: "paymentBusinessName",
    width: 250,
    sortable: true,
  },
  {
    name: "On Site Contact",
    key: "onSiteContact",
    width: 200,
    sortable: true,
  },
  {
    name: "Submitted",
    key: "submitted",
    readonlyFormatter: DateTimeFormatter,
    minWidth: 180,
    sortable: true,
  },
];

const MySuiteRequests: React.FC<RouteComponentProps> = ({ history }) => {
  const { setLoading } = useLoading();
  const [suiteRequests, setSuiteRequests] = useState<SuiteRequestDTO[]>([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const data: SuiteRequestDTO[] = await getMySuiteRequests();
      setSuiteRequests(data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const goToNewRequest = () => {
    history.push(RouteConstants.REQUESTS.SUITES_REQUEST);
  };

  useEffect(() => {
    void fetchData();
  }, []);

  const statusTextOverride = (_selected: number, totalCount: number) =>
    totalCount === 1 ? `1 request` : `${totalCount} requests`;

  const rowStylesGetter = (_index: number, value: SuiteRequestDTO): object => {
    const statusId: SuiteRequestStatusID | undefined = value?.suiteRequestStatus?.suiteStatusId;
    switch (statusId) {
      case SuiteStatuses.APPROVED:
        return {
          backgroundColor: "#eefbe0",
        };
      case SuiteStatuses.DENIED:
        return {
          backgroundColor: "#fbe3e3",
        };
      case SuiteStatuses.IN_REVIEW:
        return {
          backgroundColor: "#dee8f4",
        };
      case SuiteStatuses.OPEN:
      default:
        return {};
    }
  };

  return (
    <Card>
      <Card.Header>My Suite Requests</Card.Header>
      <Card.Body>
        <div>
          <DataTable
            columns={SuiteRequestColumns}
            data={suiteRequests}
            statusTextOverride={statusTextOverride}
            rowStylesGetter={rowStylesGetter}
          />
        </div>
      </Card.Body>
      <Card.Footer>
        <StickyFooterButtons>
          <Button variant="default" onClick={goToNewRequest}>
            New Suite Request
          </Button>
        </StickyFooterButtons>
      </Card.Footer>
    </Card>
  );
};

export default MySuiteRequests;

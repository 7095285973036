import { LoginCallback } from "@okta/okta-react";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import RouteConstants from "./constants/RouteConstants";
import Login from "./containers/Login";

const UnAuthenticatedApp = () => (
  <Switch>
    <Route path={RouteConstants.LOGIN} exact component={Login} />
    <Route path={RouteConstants.LOGIN_CALLBACK} exact component={LoginCallback} />
    <Redirect from={RouteConstants.WILDCARD} to={RouteConstants.LOGIN} />
  </Switch>
);

export default UnAuthenticatedApp;

import { Button } from "best-common-react-2";
import React from "react";
import CreditCardForm from "./CreditCardForm";

const EditCreditCard = ({ cc, setCC, number, setNumber, onSave, onCancel, valid }) => (
  <div>
    <CreditCardForm cc={cc} setCC={setCC} number={number} setNumber={setNumber} stacked={true} />
    <div className="d-flex justify-content-end mt-2">
      <Button variant="primary" className="btn-sm me-1" onClick={onSave} disabled={!valid}>
        Save
      </Button>
      <Button variant="default" className="btn-sm" onClick={onCancel}>
        Cancel
      </Button>
    </div>
  </div>
);

export default EditCreditCard;

import {
  Card,
  DateRange,
  DateRangeInput,
  DATE_FORMATTER_STR,
  DATE_FORMAT_STR,
  formatDate,
  FormColumn,
  getLocalStorageItem,
  getRawDate,
  NotApplicable,
  RadioGroup,
  RadioLabelValue,
  removeLocalStorageItem,
  Row,
  Search,
  Select,
  setLocalStorageItem,
  useLoading,
  ValueOpt,
} from "best-common-react-2";
import React, { useCallback, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { getAdminRequests, getAdminRequestsBySeries } from "../../../api/RequesTixApi";
import AdminRequestsView from "../../../components/requests/admin/AdminRequestsView";
import AdminRequestSeries from "../../../components/requests/admin/bySeries/AdminRequestSeries";
import LocalStorageConstants from "../../../constants/LocalStorageConstants";
import RouteConstants from "../../../constants/RouteConstants";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { AdminRequestsDTO, AdminRequestSeriesDTO } from "../../../types/AdminRequest";
import { RequestCategory } from "../../../types/RequestType";
import { nextWeekStr, todayStr } from "../../../util/DateObjUtil";
import { getCategoryOption, getCompleteOption } from "../../../util/DropdownUtil";
import { updateRouteParams, useRouteParams } from "../../../util/RouteUtil";
import { getTeamOption } from "../../../util/TeamUtil";
import { requestsFreeTextFilter } from "../../../util/TicketRequestUtil";
import { isDayBefore } from "../../../util/TimeUtil";

const radioOpts: RadioLabelValue[] = [
  { value: 2, label: "Series/Game" },
  { value: 1, label: "Requests" },
];

const defaultByRequests: AdminRequestsDTO = {
  schedule: [],
  games: {},
  requests: {},
  totalRequests: {},
  gameList: [],
  ticketRequests: [],
};

const AdminRequests: React.FC<RouteComponentProps> = ({ history, location }: RouteComponentProps) => {
  const { setLoading } = useLoading();
  const { requestCategoryOptions, requestStateFilterOptions, teamsOptions, completeOptions } = useDropdowns();
  const [byRequests, setByRequests] = useState<AdminRequestsDTO>(defaultByRequests);
  const [byRequestsFiltered, setByRequestsFiltered] = useState({});
  const [bySeries, setBySeries] = useState<AdminRequestSeriesDTO[]>([]);

  const localStorageHomeTeamId = getLocalStorageItem(LocalStorageConstants.ADMIN_REQUESTS_HOME_TEAM_ID);
  const {
    startDate = todayStr,
    endDate = nextWeekStr,
    homeTeamId = localStorageHomeTeamId,
    statusId = "",
    categoryId,
    completeId,
    search,
    view = 2,
    requestId,
  } = useRouteParams(location.search);

  const isRequests = parseInt(view as string) === 1;
  const homeTeam = getTeamOption(homeTeamId, teamsOptions);
  const category = getCategoryOption(categoryId, requestCategoryOptions);
  const complete = getCompleteOption(completeId, completeOptions);
  const dateRange: DateRange<Date> = {
    start: getRawDate(startDate, DATE_FORMAT_STR),
    end: getRawDate(endDate, DATE_FORMAT_STR),
  };

  const updateUrl = ({ date: { startDate, endDate }, homeTeamId, statusId, categoryId, completeId, search, view }) => {
    if (homeTeamId) {
      setLocalStorageItem(LocalStorageConstants.ADMIN_REQUESTS_HOME_TEAM_ID, homeTeamId);
    } else {
      removeLocalStorageItem(LocalStorageConstants.ADMIN_REQUESTS_HOME_TEAM_ID);
    }
    updateRouteParams(history, RouteConstants.ADMIN.REQUESTS, {
      startDate: startDate,
      endDate: endDate,
      homeTeamId: homeTeamId,
      statusId: statusId,
      completeId: completeId,
      categoryId: categoryId,
      search: search,
      view: view,
    });
  };

  const updateValue = useCallback(
    (key: string, value: string | { startDate: string; endDate: string } | number) => {
      updateUrl({
        date: { startDate, endDate },
        homeTeamId,
        statusId,
        categoryId,
        completeId,
        search,
        view,
        [key]: value,
      });
    },
    [startDate, endDate, homeTeamId, statusId, categoryId, completeId, search, view]
  );

  const getByRequests = async () => {
    try {
      if (isDayBefore(startDate, endDate)) {
        setByRequests(defaultByRequests);
        setLoading(true);
        const data: AdminRequestsDTO = await getAdminRequests(
          startDate,
          endDate,
          homeTeamId,
          statusId,
          categoryId,
          completeId
        );
        setByRequests(data);
      }
    } catch (e) {
      console.error(e);
      setByRequests(defaultByRequests);
    } finally {
      setLoading(false);
    }
  };

  const getBySeries = async () => {
    try {
      if (isDayBefore(startDate, endDate)) {
        setLoading(true);
        const data: AdminRequestSeriesDTO[] = await getAdminRequestsBySeries(startDate, endDate, homeTeamId, statusId);
        setBySeries(data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const updateDate = (dateRange: DateRange<Date> | NotApplicable) => {
    const dr: DateRange<Date> = dateRange as DateRange<Date>;
    updateValue("date", {
      startDate: formatDate(dr.start, DATE_FORMATTER_STR),
      endDate: formatDate(dr.end, DATE_FORMATTER_STR),
    });
  };

  useEffect(() => {
    if (isRequests) {
      void getByRequests();
    } else {
      void getBySeries();
    }
  }, [startDate, endDate, homeTeamId, statusId, categoryId, isRequests, completeId]);

  useEffect(() => {
    setByRequestsFiltered(requestsFreeTextFilter(byRequests.requests, search));
  }, [byRequests, search]);

  return (
    <Card>
      <Card.Header>Ticket Requests</Card.Header>
      <Card.Body>
        <Row>
          <FormColumn width={4}>
            <RadioGroup
              id="display-by"
              label="Display By"
              value={parseInt(view)}
              name="display-by"
              options={radioOpts}
              onChange={(value) => {
                updateValue("view", value);
              }}
            />
          </FormColumn>
          <FormColumn width={4}>
            <DateRangeInput
              id="requests-date"
              label="Date"
              value={dateRange}
              onChange={updateDate}
              maxRange={
                !!homeTeamId
                  ? {
                      months: 1,
                    }
                  : {
                      weeks: 1,
                    }
              }
              withPortal
            />
          </FormColumn>
          <FormColumn width={4}>
            <Select
              id="home-team"
              label="Home Team"
              value={homeTeam}
              options={teamsOptions}
              onChange={(value) => {
                updateValue("homeTeamId", value.value.id);
              }}
              placeholder="select a home team"
            />
          </FormColumn>
        </Row>
        <div className="row mt-0 mt-md-2">
          {isRequests && (
            <FormColumn width={4}>
              <Select
                id="status"
                label="Status"
                options={requestStateFilterOptions}
                value={requestStateFilterOptions.find((option: ValueOpt<number[]>) => option.value.join() === statusId)}
                onChange={(value: ValueOpt<number[]>) => updateValue("statusId", value.value.join())}
                placeholder="select a status"
              />
            </FormColumn>
          )}
          {isRequests && (
            <>
              <FormColumn width={4}>
                <Select
                  id="complete"
                  label="Complete/Incomplete"
                  value={complete}
                  options={completeOptions}
                  onChange={(value: ValueOpt<any>) => updateValue("completeId", value.value.id)}
                  placeholder="select a complete status"
                />
              </FormColumn>
              <FormColumn width={4}>
                <Select
                  id="category"
                  label="Category"
                  value={category}
                  options={requestCategoryOptions}
                  onChange={(value: ValueOpt<RequestCategory>) =>
                    updateValue("categoryId", value.value.requestCategoryId)
                  }
                  placeholder="select a category"
                />
              </FormColumn>
              <FormColumn width={4}>
                <Search
                  id="search"
                  label="Search Requests"
                  value={search}
                  onChange={(value: string) => updateValue("search", value)}
                  placeholder="begin typing to filter requests"
                />
              </FormColumn>
            </>
          )}
        </div>
        <div>
          {isRequests ? (
            <AdminRequestsView {...byRequests} requests={byRequestsFiltered} jumpToRequestId={requestId} />
          ) : (
            <AdminRequestSeries values={bySeries} location={location} />
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default AdminRequests;

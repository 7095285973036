import { BestLink } from "best-common-react";
import React from "react";
import styled from "styled-components";

const ValueHolder = styled.div`
  width: 100%;
`;

const ToLinkFormatter = ({ value, className }) => {
  const name = value ? value.value : "";
  const to = value ? value.to : "";
  const search = value ? value.search : "";
  return (
    <ValueHolder className={className}>
      {to && to.length ? (
        <BestLink
          to={{
            pathname: to,
            search: search,
          }}
        >
          {name}
        </BestLink>
      ) : (
        <div>{name}</div>
      )}
    </ValueHolder>
  );
};

export default ToLinkFormatter;

import { datadogRum } from "@datadog/browser-rum";
import { SecureRoute } from "@okta/okta-react";
import { AlertsFromProvider, LoadingFromProvider } from "best-common-react-2";
import React, { useEffect } from "react";
import { Redirect, Switch } from "react-router-dom";
import styled from "styled-components";
import Navigation from "./components/navigation/Navigation";
import RouteConstants from "./constants/RouteConstants";
import AdminDepartmentsPage from "./containers/admin/department/AdminDepartments";
import AdminEditDepartments from "./containers/admin/department/AdminEditDepartments";
import AdminDigitalTickets from "./containers/admin/digitalTickets/AdminDigitalTickets";
import AdminEditEventsPage from "./containers/admin/event/AdminEditEvent";
import AdminEventsPage from "./containers/admin/event/AdminEvents";
import AdminEditProjectsPage from "./containers/admin/project/AdminEditProject";
import AdminProjectsPage from "./containers/admin/project/AdminProject";
import AdminPurchaseOpportunity from "./containers/admin/puchaseOpportunity/AdminPurchaseOpportunity";
import AdminPurchaseOpportunityForm from "./containers/admin/puchaseOpportunity/AdminPurchaseOpportunityForm";
import AdminPurchaseOpportunityView from "./containers/admin/puchaseOpportunity/AdminPurchaseOpportunityView";
import AdminEditRequestPage from "./containers/admin/request/AdminEditRequest";
import AdminRequestsByGamePage from "./containers/admin/request/AdminRequestByGame";
import AdminRequestBySeriesPage from "./containers/admin/request/AdminRequestBySeries";
import AdminRequestsPage from "./containers/admin/request/AdminRequests";
import AdminSuiteRequestEdit from "./containers/admin/suites/AdminSuiteRequestEdit";
import AdminSuiteRequests from "./containers/admin/suites/AdminSuiteRequests";
import AdminDayOfPage from "./containers/admin/todaysTickets/AdminTodaysTickets";
import AdminTodaysTicketsEdit from "./containers/admin/todaysTickets/AdminTodaysTicketsEdit";
import AdminEditUserPage from "./containers/admin/user/AdminEditUser";
import AdminUsersPage from "./containers/admin/user/AdminUsers";
import AdminEditVenuePage from "./containers/admin/venue/AdminEditVenue";
import AdminVenuesPage from "./containers/admin/venue/AdminVenues";
import HomePage from "./containers/Home";
import MyPurchaseOpportunityRequest from "./containers/purchaseOpportunity/MyPurchaseOpportunityRequest";
import PurchaseOpportunityList from "./containers/purchaseOpportunity/PurchaseOpportunityList";
import PurchaseOpportunityRequest from "./containers/purchaseOpportunity/PurchaseOpportunityRequest";
import PurchaseOpportunityRequestEdit from "./containers/purchaseOpportunity/PurchaseOpportunityRequestEdit";
import ReportsPage from "./containers/Reports";
import MyRequestsPage from "./containers/request/MyRequests";
import EditSuiteRequestPage from "./containers/request/suite/EditSuiteRequest";
import MySuiteRequestsPage from "./containers/request/suite/MySuiteRequests";
import NewSuiteRequestPage from "./containers/request/suite/NewSuiteRequest";
import TicketRequestPage from "./containers/request/TicketRequest";
import TicketRequestEditPage from "./containers/request/TicketRequestEdit";
import DayOf from "./containers/TodaysTicketsContainer";
import AccountPage from "./containers/user/Account";
import Providers from "./contexts";
import { useAuth } from "./contexts/AuthContext";
import { useEnv } from "./contexts/EnvContext";
import ScrollToTop from "./util/ScrollToTop";

type ContainerProps = {
  loggedIn: boolean;
};

const Container = styled.div<ContainerProps>`
  min-width: 100%;
  min-height: calc(100% - ${(props) => (props.loggedIn ? "69px" : "50px")});
  padding: 1rem;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const AdminRoute = ({ component: Component, ...rest }) => {
  const { isAdmin } = useAuth();
  return (
    <SecureRoute
      {...rest}
      render={(props) => (isAdmin ? <Component {...props} /> : <Redirect to={RouteConstants.BASE} />)}
    />
  );
};

const Body = styled.div`
  &&& {
    font-weight: 300;

    .form-control {
      font-weight: 300;
    }
  }
`;

const AuthenticatedApp = () => {
  const { loggedIn } = useAuth();
  const {
    variables: { ENV, DATADOG_APP_ID, DATADOG_CLIENT_TOKEN },
  } = useEnv();

  useEffect(() => {
    datadogRum.init({
      applicationId: DATADOG_APP_ID,
      clientToken: DATADOG_CLIENT_TOKEN,
      site: "us5.datadoghq.com",
      service: "requestix",
      env: ENV,
      version: "0.0.1",
      sampleRate: 100,
      premiumSampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: "mask-user-input",
    });

    datadogRum.startSessionReplayRecording();
  }, []);

  return (
    <Body>
      {loggedIn && (
        <Providers>
          <LoadingFromProvider />
          <AlertsFromProvider />
          <>
            <ScrollToTop />
            <Navigation />
            <Container loggedIn={loggedIn}>
              <Switch>
                <SecureRoute path={RouteConstants.BASE} exact component={HomePage} />
                <SecureRoute path={RouteConstants.LOGIN} exact component={HomePage} />
                <SecureRoute path={RouteConstants.TODAYS_TICKETS} exact component={DayOf} />
                <SecureRoute path={RouteConstants.REQUESTS.BASE} exact component={MyRequestsPage} />
                <SecureRoute path={RouteConstants.REQUESTS.TICKETS} exact component={TicketRequestPage} />
                <SecureRoute path={RouteConstants.REQUESTS.SUITES} exact component={MySuiteRequestsPage} />
                <SecureRoute path={RouteConstants.REQUESTS.SUITES_REQUEST} exact component={NewSuiteRequestPage} />
                <SecureRoute
                  path={RouteConstants.REQUESTS.SUITES_REQUEST_EDIT}
                  exact
                  component={EditSuiteRequestPage}
                />
                <SecureRoute path={RouteConstants.REQUESTS.EDIT} exact component={TicketRequestEditPage} />
                <SecureRoute path={RouteConstants.REPORTS} exact component={ReportsPage} />
                <SecureRoute
                  path={RouteConstants.PURCHASE_OPPORTUNITY.LIST}
                  exact
                  component={PurchaseOpportunityList}
                />
                <SecureRoute
                  path={RouteConstants.PURCHASE_OPPORTUNITY.REQUESTS}
                  exact
                  component={MyPurchaseOpportunityRequest}
                />
                <SecureRoute
                  path={RouteConstants.PURCHASE_OPPORTUNITY.REQUEST}
                  exact
                  component={PurchaseOpportunityRequest}
                />
                <SecureRoute
                  path={RouteConstants.PURCHASE_OPPORTUNITY.REQUEST_EDIT}
                  exact
                  component={PurchaseOpportunityRequestEdit}
                />
                <SecureRoute path={RouteConstants.USER.ACCOUNT} exact component={AccountPage} />
                <AdminRoute path={RouteConstants.ADMIN.REQUESTS} exact component={AdminRequestsPage} />
                <AdminRoute path={RouteConstants.ADMIN.REQUESTS_GAME} exact component={AdminRequestsByGamePage} />
                <AdminRoute path={RouteConstants.ADMIN.REQUESTS_SERIES} exact component={AdminRequestBySeriesPage} />
                <AdminRoute path={RouteConstants.ADMIN.REQUESTS_EDIT} exact component={AdminEditRequestPage} />
                <AdminRoute path={RouteConstants.ADMIN.USERS_EDIT} exact component={AdminEditUserPage} />
                <AdminRoute path={RouteConstants.ADMIN.USERS} exact component={AdminUsersPage} />
                <AdminRoute path={RouteConstants.ADMIN.DEPARTMENTS_EDIT} exact component={AdminEditDepartments} />
                <AdminRoute path={RouteConstants.ADMIN.DEPARTMENTS} exact component={AdminDepartmentsPage} />
                <AdminRoute path={RouteConstants.ADMIN.VENUES_EDIT} exact component={AdminEditVenuePage} />
                <AdminRoute path={RouteConstants.ADMIN.VENUES} exact component={AdminVenuesPage} />
                <AdminRoute path={RouteConstants.ADMIN.EVENTS_EDIT} exact component={AdminEditEventsPage} />
                <AdminRoute path={RouteConstants.ADMIN.EVENTS} exact component={AdminEventsPage} />
                <AdminRoute path={RouteConstants.ADMIN.PROJECTS} exact component={AdminProjectsPage} />
                <AdminRoute path={RouteConstants.ADMIN.PROJECTS_EDIT} exact component={AdminEditProjectsPage} />
                <AdminRoute path={RouteConstants.ADMIN.TODAYS_TICKETS} exact component={AdminDayOfPage} />
                <AdminRoute path={RouteConstants.ADMIN.TODAYS_TICKETS_EDIT} exact component={AdminTodaysTicketsEdit} />
                <AdminRoute path={RouteConstants.ADMIN.DIGITAL_TICKETS} exact component={AdminDigitalTickets} />
                <AdminRoute
                  path={RouteConstants.ADMIN.PURCHASE_OPPORTUNITY.BASE}
                  exact
                  component={AdminPurchaseOpportunity}
                />
                <AdminRoute
                  path={RouteConstants.ADMIN.PURCHASE_OPPORTUNITY.EDIT}
                  exact
                  component={AdminPurchaseOpportunityForm}
                />
                <AdminRoute
                  path={RouteConstants.ADMIN.PURCHASE_OPPORTUNITY.VIEW}
                  exact
                  component={AdminPurchaseOpportunityView}
                />
                <AdminRoute path={RouteConstants.ADMIN.SUITE_REQUESTS.BASE} exact component={AdminSuiteRequests} />
                <AdminRoute path={RouteConstants.ADMIN.SUITE_REQUESTS.EDIT} exact component={AdminSuiteRequestEdit} />
                <Redirect from={RouteConstants.WILDCARD} to={RouteConstants.BASE} />
              </Switch>
            </Container>
          </>
        </Providers>
      )}
    </Body>
  );
};

export default AuthenticatedApp;

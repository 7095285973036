import { FormColumn } from "best-common-react-2";
import React from "react";
import { getUserBillings } from "../../../api/RequesTixApi";
import { FlattenedTicketRequest } from "../../../types/TicketRequest";
import { BillingDTO } from "../../../types/UserBilling";
import CreditCardSelect from "../../payment/CreditCardSelect";

type TicketRequestBillingProps = {
  ticketRequest: FlattenedTicketRequest;
  onChange: (key: string, value: BillingDTO) => void;
  canEdit?: boolean;
};

const TicketRequestBilling: React.FC<TicketRequestBillingProps> = ({ ticketRequest, onChange, canEdit = true }) => {
  const [billings, setBillings] = React.useState<BillingDTO[]>([]);

  const fetchBillings = async (userId, setDefault = false) => {
    const res: BillingDTO[] = await getUserBillings(userId);
    setBillings(billings.concat([...res]));
    if (!ticketRequest.userBilling && setDefault) {
      onChange(
        "userBilling",
        res.find((billing) => billing.isDefault)
      );
    }
  };

  const onNewCardAdded = (userBilling) => {
    if (!!userBilling) {
      setBillings([...billings, userBilling]);
      onChange("userBilling", userBilling);
    }
  };

  React.useEffect(() => {
    void fetchBillings(ticketRequest.requester.employeeUserId, true);
    void fetchBillings(ticketRequest.submitter.employeeUserId, false);
  }, [ticketRequest.requester.employeeUserId, ticketRequest.submitter.employeeUserId]);

  return (
    <FormColumn width={3}>
      <CreditCardSelect
        id="credit-card-select"
        userBillings={billings}
        selected={ticketRequest.userBilling}
        setSelected={(value) => onChange("userBilling", value)}
        disabled={!canEdit}
        userId={ticketRequest.requester.employeeUserId}
        canAddCard={true}
        onNewCardAdded={onNewCardAdded}
        gutterBottom={true}
      />
    </FormColumn>
  );
};

export default TicketRequestBilling;

import { isNumber, toLower } from "./StringUtil";

export const NONE = "NONE";
export const ASC = "ASC";
export const DESC = "DESC";

export const sort = (list, field = "id", direction = 1) => {
  return list.sort((a, b) => (a[field] < b[field] ? -direction : a[field] > b[field] ? direction : 0));
};

export const sortWithFunction = (
  list = [],
  field = "id",
  direction = 1,
  manipulationFunc = (a) => a,
  secondarySort = () => 0
) =>
  list
    .map((a) => a)
    .sort((a, b) => {
      const val1 = manipulationFunc(a[field]);
      const val2 = manipulationFunc(b[field]);
      return compare(val1, val2, direction, secondarySort);
    });

export const sortLabel = (a, b) => {
  const label1 = toLower(a.label);
  const label2 = toLower(b.label);
  return compare(label1, label2);
};

export const tableSort = (values, sortFilters, defaultKey = "name", secondarySort) => {
  if (sortFilters.direction === NONE) {
    return sortWithFunction(values, defaultKey, 1, toLower, secondarySort);
  } else if (sortFilters.direction === ASC) {
    return sortWithFunction(values, sortFilters.key, 1, toLower, secondarySort);
  } else {
    return sortWithFunction(values, sortFilters.key, -1, toLower, secondarySort);
  }
};

export const compare = (val1, val2, direction = 1, secondarySort = () => 0) => {
  if (isNumber(val1) && isNumber(val2)) {
    return val1 < val2 ? -direction : val1 > val2 ? direction : secondarySort(val1, val2);
  }
  if (isNumber(val1)) {
    return direction;
  } else if (isNumber(val2)) {
    return -direction;
  } else {
    return val1 < val2 ? -direction : val1 > val2 ? direction : secondarySort(val1, val2);
  }
};

import { Button, DesktopTabletView, Loader } from "best-common-react-2";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getEvent } from "../../../api/RequesTixApi";
import { useTicketRequest } from "../../../contexts/TicketRequestContext";
import { UserAllotmentCountDTO } from "../../../types/Allotment";
import { EventTimingTypeDTO } from "../../../types/EventTiming";
import { GameDTO, GameTime } from "../../../types/Game";
import { RequestTypeDesc } from "../../../types/RequestType";
import { SeasonTypeCode } from "../../../types/Season";
import { getSeasonTypeForGame } from "../../../util/SeasonUtil";
import { isBusiness } from "../../../util/TicketRequestUtil";
import { Offset, useTimeUntil, useTimezone } from "../../../util/TimeUtil";
import MatchUp from "../../game/MatchUp";
import TimeRemaining from "../card/TimeRemaining";
import TicketRequestSeasonSelect from "./TicketRequestSeasonSelect";

const Container = styled.div`
  min-width: 20rem;
  @media (max-width: 767px) {
    width: 100%;
  }
  @media (min-width: 768px) {
    margin-right: 1rem;
  }
`;

const InformationCard = styled.div`
  width: 100%;
  margin-top: 1rem;
  background-color: ${(props) => props.theme["white"]};
  border: ${(props) => `1px solid ${props.theme["grey"]}`};
  min-height: 10rem;
  padding: 0.5rem 0.75rem;
`;

const GameSection = styled.div`
  min-height: 6rem;
  @media (max-width: 767px) {
    min-height: 3rem;
  }
`;

const OriginalGame = styled.div`
  margin-top: 0.75rem;
  margin-bottom: 1.5rem;
  padding: 0.25rem;
`;

const OriginalGameText = styled.div`
  font-size: 0.75rem;
  font-weight: 300;
  border-bottom: solid 1px ${(props) => props.theme["grey"]};
  margin-bottom: 0.5rem;
`;

const TimeSection = styled.div`
  display: flex;
  justify-content: center;
`;

const Header = styled.div`
  font-size: 1.125rem;
  font-weight: bold;
  line-height: ${(props) => props.theme.lineHeight["eventGrid"]};
`;

const Description = styled.div`
  font-size: 0.875rem;
  color: ${(props) => props.theme["dark-grey"]};
  line-height: ${(props) => props.theme.lineHeight["eventGrid"]};
`;

type RequestInformationProps = {
  originalGamePk?: number | string;
  setAccessible?: (value: boolean) => void;
  userId: number;
  allotments: UserAllotmentCountDTO[];
  setAllotments: (value: UserAllotmentCountDTO[]) => void;
  type: RequestTypeDesc;
  changeGame?: any;
};

const RequestInformation: React.FC<RequestInformationProps> = ({
  originalGamePk,
  setAccessible,
  userId,
  allotments,
  setAllotments,
  type,
  changeGame = null,
}) => {
  const { game, fetchingGame } = useTicketRequest();
  const [originalGameLoading, setOriginalGameLoading] = useState<boolean>(true);
  const [originalGame, setOriginalGame] = useState<GameDTO>();
  const [gameTime, setGameTime] = useState<GameTime>({});
  const [originalGameTime, setOriginalGameTime] = useState<GameTime>({});
  const [offset, setOffset] = useState<Offset>({});
  const [_allotment, setAllotment] = useState({});

  useEffect(() => {
    if (!!game?.gamePk) {
      const gameDate = !!game.rescheduleDate ? game.rescheduleDate : game.gameDate;
      setGameTime(useTimezone(gameDate, game.venue.timeZone, game.status));
      const cutoff: EventTimingTypeDTO = isBusiness(type) ? game.cutoff.business : game.cutoff.personal;
      const { offset, accessible } = useTimeUntil(gameDate, game.access, cutoff);
      setOffset(offset);
      setAccessible(accessible);
    }
  }, [game]);

  useEffect(() => {
    setOriginalGameLoading(true);
    if (originalGamePk) {
      getEvent(originalGamePk).then((res) => {
        setOriginalGame(res);
        setOriginalGameTime(useTimezone(res.gameDate, res.venue.timeZone));
        setOriginalGameLoading(false);
      });
    }
  }, [originalGamePk]);

  useEffect(() => {
    if (game?.gameType && allotments) {
      const seasonType: SeasonTypeCode = getSeasonTypeForGame(game);
      const allot = allotments.find((allotment) => {
        const correctSeason: boolean = seasonType.id === allotment.season.seasonType.seasonTypeId;
        let correctType = true;
        if (type) {
          if (isBusiness(type)) {
            correctType = allotment.allotmentType.allotmentTypeId === 2;
          } else {
            correctType = allotment.allotmentType.allotmentTypeId === 1;
          }
        }
        return correctSeason && correctType;
      });
      if (allot) {
        setAllotment(allot);
      }
    }
  }, [allotments, game]);

  return (
    <Container>
      <InformationCard>
        {!fetchingGame && !!gameTime && !!game ? (
          <div>
            <GameSection>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column align-items-start">
                  <Header>{gameTime.date}</Header>
                  <Description>
                    {gameTime.day}
                    <span className="ms-2">{gameTime.time}</span>
                  </Description>
                  <DesktopTabletView>
                    <div className="mt-2">
                      <div style={{ fontSize: "0.875rem" }}>{game.venue.name}</div>
                      {game.venue.location ? (
                        <Description>
                          {game.venue.location.city}
                          {game.venue.location.city && game.venue.location.stateAbbrev ? ", " : ""}
                          {game.venue.location.stateAbbrev}
                        </Description>
                      ) : null}
                    </div>
                  </DesktopTabletView>
                </div>
                <div className="d-flex flex-column justify-content-between align-items-end" style={{ width: "6rem" }}>
                  <MatchUp
                    home={game.teams.home.team}
                    away={game.teams.away.team}
                    description={game.seriesDescription}
                  />
                  {/* TODO: Change to pending requests by game (TOTTIX-747)
                      {allotment.pending > 0 ? <Pending>{allotment.pending} pending</Pending> : null}
                  */}
                </div>
              </div>
            </GameSection>
            <hr style={{ marginBottom: 0 }} />
            {changeGame ? (
              <div className="d-flex justify-content-md-center justify-content-start">
                <Button variant="link" onClick={changeGame}>
                  change game
                </Button>
              </div>
            ) : (
              <TimeSection>
                <TimeRemaining offset={offset} isPersonal={!isBusiness(type)} />
              </TimeSection>
            )}
          </div>
        ) : (
          <Loader type="pulse" />
        )}
      </InformationCard>
      {!originalGameLoading && originalGame && originalGameTime && (
        <OriginalGame>
          <OriginalGameText>Original selection</OriginalGameText>
          <div>
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column align-items-start">
                <Header>{originalGameTime.date}</Header>
                <Description>
                  {originalGameTime.day}
                  <span className="ms-2">{originalGameTime.time}</span>
                </Description>
              </div>
              <div className="d-flex flex-column justify-content-between align-items-end" style={{ width: "6rem" }}>
                <MatchUp
                  home={originalGame.teams.home.team}
                  away={originalGame.teams.away.team}
                  description={originalGame.seriesDescription}
                />
              </div>
            </div>
          </div>
        </OriginalGame>
      )}
      <div style={{ height: "5.625rem" }} className="mt-3">
        {!fetchingGame && !!game ? (
          <TicketRequestSeasonSelect game={game} userId={userId} setAllotments={setAllotments} type={type} />
        ) : (
          <Loader type="pulse" />
        )}
      </div>
    </Container>
  );
};

export default RequestInformation;

import { Card } from "best-common-react-2";
import React from "react";
import MyPurchaseOppTable from "../../components/purchaseOpportunity/MyPurchaseOppTable";

const MyPurchaseOpportunityRequest: React.FC = () => {
  return (
    <Card>
      <Card.Header>My Purchase Opportunities</Card.Header>
      <Card.Body>
        <MyPurchaseOppTable />
      </Card.Body>
    </Card>
  );
};

export default MyPurchaseOpportunityRequest;

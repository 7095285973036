import { OktaAuth } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import { Theme } from "best-common-react";
import { AlertProvider, ErrorCatcher, LoadingProvider } from "best-common-react-2";
import React, { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import HttpInterceptor from "../api/HttpInterceptor";
import { AuthProvider } from "./AuthContext";
import { useEnv } from "./EnvContext";

const WrapperProvider = ({ children }) => {
  const [oktaAuth, setOktaAuth] = useState<OktaAuth>();

  const {
    variables: { ENV = "prod", OKTA_TESTING_DISABLEHTTPSCHECK, OKTA_CLIENT_ID, OKTA_ISSUER, OKTA_REDIRECT_URI },
  } = useEnv();
  const IS_PROD: boolean = ENV === "prod";

  const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
    window.location.replace(originalUri);
  };
  const OKTA_TESTING_DISABLE_HTTPS_CHECK = OKTA_TESTING_DISABLEHTTPSCHECK || false;
  const REDIRECT_URI = OKTA_REDIRECT_URI + `/login/callback`;

  const OKTA_CONFIG = {
    oidc: {
      clientId: OKTA_CLIENT_ID,
      issuer: OKTA_ISSUER,
      redirectUri: REDIRECT_URI,
      scopes: ["openid", "profile", "email", "offline_access"],
      pkce: true,
      disableHttpsCheck: OKTA_TESTING_DISABLE_HTTPS_CHECK,
      tokenManager: {
        expireEarlySeconds: 60,
      },
    },
  };

  useEffect(() => {
    setOktaAuth(new OktaAuth(OKTA_CONFIG.oidc));
  }, []);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <ErrorCatcher active={true} appName="Requestix" contactEmail="requestixsupport@mlb.com">
        <ThemeProvider theme={Theme}>
          <AlertProvider>
            <AuthProvider isProd={IS_PROD} oktaAuth={oktaAuth}>
              <LoadingProvider>
                <HttpInterceptor>{children}</HttpInterceptor>
              </LoadingProvider>
            </AuthProvider>
          </AlertProvider>
        </ThemeProvider>
      </ErrorCatcher>
    </Security>
  );
};

export default WrapperProvider;

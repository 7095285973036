import {
  AttributeHeader,
  AttributeValue,
  Button,
  Card,
  DATE_FORMATTER_STR_WITH_TIME,
  formatDate,
  FormColumn,
  Row,
  StickyFooterButtons,
  SubSection,
  useLoading,
} from "best-common-react-2";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { fetchAdminSuiteRequest, saveAdminSuiteRequest } from "../../../api/RequesTixApi";
import AdminSuiteRequestsActions from "../../../components/requests/adminSuite/AdminSuiteRequestsActions";
import SuiteRequestForm from "../../../components/requests/suite/SuiteRequestForm";
import { useMetadata } from "../../../contexts/MetadataContext";
import { StatsApiGame } from "../../../types/Stats";
import {
  AdminSuiteRequest,
  AdminSuiteRequestStateDTO,
  SuiteRequestDTO,
  SuiteRequestStatus,
} from "../../../types/SuiteRequest";
import { UserDTO } from "../../../types/User";
import { getGameFromGamePk } from "../../../util/GameUtil";
import { useRouteParams } from "../../../util/RouteUtil";

type AdminSuiteRequestEditUrlProps = {
  suiteRequestId: string;
};

type AdminSuiteRequestEditProps = RouteComponentProps<AdminSuiteRequestEditUrlProps>;

const AdminSuiteRequestEdit = ({ match, history, location }: AdminSuiteRequestEditProps) => {
  const { suiteRequestId } = match.params;
  const { suiteRequestStatusId } = useRouteParams(location.search);
  const { setLoading } = useLoading();
  const { suiteRequestStatuses } = useMetadata();
  const [request, setRequest] = useState<SuiteRequestDTO>(undefined);
  const [actionState, setActionState] = useState<AdminSuiteRequestStateDTO>(undefined);
  const [validRequest, setValidRequest] = useState<boolean>(false);
  const [validActionState, setValidActionState] = useState<boolean>(false);
  const [canSave, setCanSave] = useState<boolean>(false);
  const [selectedGame, setSelectedGame] = useState<StatsApiGame | undefined>(undefined);

  const goBack = () => {
    history.goBack();
  };

  const save = async (request: SuiteRequestDTO, actionState: AdminSuiteRequestStateDTO) => {
    try {
      setLoading(true);
      const res: AdminSuiteRequest = await saveAdminSuiteRequest({
        request,
        actionState,
      });
      setRequest(res.request);
      setActionState(res.actionState);
      goBack();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const thinSave = async (request: SuiteRequestDTO, actionState: AdminSuiteRequestStateDTO) => {
    try {
      const res: AdminSuiteRequest = await saveAdminSuiteRequest({
        request,
        actionState,
      });
      setRequest(res.request);
      setActionState(res.actionState);
    } catch (e) {
      console.error(e);
    }
  };

  const getAdminSuiteRequest = async (id: number | string) => {
    try {
      setLoading(true);
      const res: AdminSuiteRequest = await fetchAdminSuiteRequest(id);
      if (!!suiteRequestStatusId) {
        setRequest({
          ...res.request,
          suiteRequestStatus: suiteRequestStatuses.find(
            (sr: SuiteRequestStatus) => sr.suiteStatusId === parseInt(suiteRequestStatusId)
          ),
        });
        history.push(location.pathname);
      } else {
        setRequest(res.request);
      }

      setActionState(res.actionState);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const goFetchGame = async (gamePk: number | string) => {
    const game: StatsApiGame | undefined = await getGameFromGamePk(gamePk);
    setSelectedGame(game);
  };

  useEffect(() => {
    if (!!suiteRequestStatuses?.length) {
      void getAdminSuiteRequest(suiteRequestId);
    }
  }, [suiteRequestId, suiteRequestStatuses]);

  useEffect(() => {
    setCanSave(validRequest && validActionState);
  }, [validRequest, validActionState]);

  useEffect(() => {
    if (!!actionState?.selectedGamePk) {
      void goFetchGame(actionState.selectedGamePk);
    }
  }, [actionState?.selectedGamePk]);

  const user: UserDTO | undefined = request?.requesterUser ?? request?.onBehalfOfUser;
  return (
    <Card>
      <Card.Header>Suite Request</Card.Header>
      <Card.Body>
        {!!request && !!actionState && (
          <>
            <SuiteRequestForm suiteRequest={request} setSuiteRequest={setRequest} setValidForm={setValidRequest} />
            <Row>
              <FormColumn width={2}>
                <SubSection header="Request Details">
                  <Row>
                    <FormColumn width={2}>
                      <AttributeHeader>Requester</AttributeHeader>
                      <AttributeValue>
                        {user?.firstName} {user?.lastName}
                      </AttributeValue>
                    </FormColumn>
                    <FormColumn width={2}>
                      <AttributeHeader>Requester Email</AttributeHeader>
                      <AttributeValue>{user?.email}</AttributeValue>
                    </FormColumn>
                    <FormColumn width={2}>
                      <AttributeHeader>Title</AttributeHeader>
                      <AttributeValue>{user.title}</AttributeValue>
                    </FormColumn>
                    <FormColumn width={2}>
                      <AttributeHeader>Department</AttributeHeader>
                      <AttributeValue>{user.department}</AttributeValue>
                    </FormColumn>
                    <FormColumn width={2}>
                      <AttributeHeader>Date Submitted</AttributeHeader>
                      <AttributeValue>
                        {formatDate(new Date(request.submitted), DATE_FORMATTER_STR_WITH_TIME)}
                      </AttributeValue>
                    </FormColumn>
                  </Row>
                </SubSection>
              </FormColumn>
              <FormColumn width={2}>
                <SubSection header="Status & Actions">
                  <AdminSuiteRequestsActions
                    game={selectedGame}
                    request={request}
                    setRequest={setRequest}
                    actionState={actionState}
                    setActionState={setActionState}
                    setValidActionState={setValidActionState}
                    refreshRequest={() => {
                      void getAdminSuiteRequest(suiteRequestId);
                    }}
                    saveRequest={thinSave}
                  />
                </SubSection>
              </FormColumn>
            </Row>
          </>
        )}
      </Card.Body>
      <Card.Footer>
        <StickyFooterButtons>
          <Button
            variant="primary"
            onClick={() => {
              void save(request, actionState);
            }}
            disabled={!canSave}
          >
            Save
          </Button>
          <Button variant="default" onClick={goBack}>
            Cancel
          </Button>
        </StickyFooterButtons>
      </Card.Footer>
    </Card>
  );
};

export default AdminSuiteRequestEdit;

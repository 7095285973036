import React, { memo } from "react";
import TrackVisibility from "react-on-screen";
import { GamePreviewDTO } from "../../../types/Game";
import { TicketRequestDTO } from "../../../types/TicketRequest";
import AdminRequestGame from "./AdminRequestGame";

type AdminRequestsViewProps = {
  schedule: number[];
  games: { [key: number]: GamePreviewDTO };
  requests: { [key: number]: TicketRequestDTO[] };
  totalRequests: { [key: number]: number };
  jumpToRequestId?: number;
};

const AdminRequestsView = memo(
  ({ schedule = [], games, requests, totalRequests, jumpToRequestId }: AdminRequestsViewProps) => (
    <>
      {schedule.map((gamePk: number, index: number) => (
        <TrackVisibility key={gamePk} once partialVisibility offset={500}>
          <AdminRequestGame
            index={index}
            game={games[gamePk]}
            requests={requests[gamePk]}
            totalRequests={totalRequests[gamePk]}
            jumpToRequestId={jumpToRequestId}
          />
        </TrackVisibility>
      ))}
    </>
  )
);

export default AdminRequestsView;

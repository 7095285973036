import { Button, formatDate, FormColumn, Hint, Label, Textarea, useLoading } from "best-common-react-2";
import React from "react";
import { sendSuiteConfirmationEmailTicketReminder } from "../../../api/RequesTixApi";
import { SuiteStatuses } from "../../../types/SuiteRequest";
import { AdminSuiteActionsProps } from "./AdminSuiteRequestsActions";

const AdminSuiteConfirmationEmail = ({
  request,
  actionState,
  setActionState,
  saveRequest,
  refreshRequest,
}: AdminSuiteActionsProps) => {
  const { setLoading } = useLoading();

  const sendConfirmationEmail = async () => {
    try {
      setLoading(true);
      await saveRequest(request, actionState);
      setTimeout(async () => {
        await sendSuiteConfirmationEmailTicketReminder(request.suiteRequestId);
        void refreshRequest();
      }, 100);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const canSendEmail = () => {
    switch (request.suiteRequestStatus.suiteStatusId) {
      case SuiteStatuses.DENIED:
        return true;
      case SuiteStatuses.APPROVED:
        return !!actionState.selectedGamePk;
      default:
        return false;
    }
  };

  return (
    <>
      <FormColumn width={1}>
        <Textarea
          id="confirmation-email-notes"
          label="Confirmation Email Notes"
          value={actionState.confirmationEmailNotes}
          onChange={(value: string) => {
            setActionState({
              ...actionState,
              confirmationEmailNotes: value,
            });
          }}
          gutterBottom
        />
      </FormColumn>
      <FormColumn width={2}>
        <Label htmlFor="send-confirmation-email">Confirmation Email</Label>
        <div className="d-flex align-items-center mb-2">
          <Button
            id="send-confirmation-email"
            variant="default"
            className="me-2"
            onClick={sendConfirmationEmail}
            disabled={!canSendEmail()}
          >
            Send Email
          </Button>
          <Hint>
            {actionState.confirmationEmailSentDate
              ? `Email sent: ${formatDate(new Date(actionState.confirmationEmailSentDate))}`
              : ""}
          </Hint>
        </div>
      </FormColumn>
    </>
  );
};

export default AdminSuiteConfirmationEmail;

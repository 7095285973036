import {
  Accordion,
  AttributeHeader,
  AttributeValue,
  CustomMobileAccordionType,
  Icon,
  Link,
  Select,
  useTheme,
} from "best-common-react-2";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { getAllotmentByRequest, getRequestActions, getRequestSurroundingGames } from "../../../api/RequesTixApi";
import { APPROVED, OPEN, PAY_BY_COMP } from "../../../constants/RequestTableConstants";
import RouteConstants, { urlReplace } from "../../../constants/RouteConstants";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { TicketRequestDTO } from "../../../types/TicketRequest";
import { getStatusColor, removeRequestId } from "../../../util/TicketRequestUtil";
import { formatSubmittedDate } from "../../../util/TimeUtil";
import SurroundingGame from "../../misc/SurroundingGame";
import AdminRequestActionCheckList from "./AdminRequestActionCheckList";
import AdminRequestCompleteIcon from "./AdminRequestCompleteIcon";
import RequestAllotmentTableMobile from "./RequestAllotmentTableMobile";

const DropdownHolder = styled.div`
  width: 150px;
`;

const HeaderSection = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CommentsContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  font-weight: 300;
  align-items: start;
`;

const CommentsText = styled.p`
  margin-left: 0.5rem;
  word-break: break-word;
`;

const SurroundingGames = styled.div`
  margin-left: 1rem;
  color: black;
`;

const AdminRequestAccordion: React.FC<CustomMobileAccordionType<TicketRequestDTO>> = ({ data, ...rest }) => {
  const {
    requestId,
    status,
    recipient,
    requester,
    department,
    quantity,
    category,
    payBy,
    maxPrice,
    project,
    business,
    submitted,
    fulfillmentType,
    completed,
    surroundingGames,
    comments,
    adminComments,
  } = data;
  const history = useHistory();
  const location = useLocation();
  const { Theme } = useTheme();
  const { requestStateOptions } = useDropdowns();
  const [allotment, setAllotment] = React.useState({});
  const [actions, setActions] = React.useState([]);
  const [games, setGames] = React.useState([]);

  const onExpansion = () => {
    getAllotmentByRequest(requestId).then((res) => setAllotment(res));
    getRequestActions(requestId).then((res) => setActions(res));
    if (surroundingGames.length > 0) {
      getRequestSurroundingGames(requestId).then((res) => setGames(res));
    }
  };

  const onStatusChange = (stateId) => {
    const newLocation = { ...location, search: removeRequestId(location) };
    history.push({
      pathname: urlReplace(RouteConstants.ADMIN.REQUESTS_EDIT, { requestId: requestId }),
      state: { previousLocation: newLocation },
      search: `?stateId=${stateId}`,
    });
  };

  const backgroundColor = getStatusColor(data.requestId, data.status.requestStateId, false);

  return (
    <Accordion
      {...rest}
      styles={{
        ...Theme.accordion,
        header: {
          ...Theme.accordion.header,
          background: backgroundColor,
        },
        body: {
          ...Theme.accordion.body,
          background: backgroundColor,
        },
      }}
    >
      <Accordion.Header>
        <div>
          <HeaderSection>
            <Link
              to={{
                pathname: urlReplace(RouteConstants.ADMIN.REQUESTS_EDIT, { requestId: requestId }),
                state: { previousLocation: location },
              }}
            >
              <Icon iconName="fa-pencil-alt" className="me-3" />
            </Link>
            <HeaderText>
              {requester} - {department}
            </HeaderText>
          </HeaderSection>
          <HeaderSection className="mt-1">
            <DropdownHolder>
              <Select
                id="request state"
                options={requestStateOptions}
                value={requestStateOptions.find((option) => option.value.requestStateId === status.requestStateId)}
                onChange={(value) => {
                  onStatusChange(value.value.requestStateId);
                }}
                portal={document.body}
              />
            </DropdownHolder>
            {status.requestStateId !== OPEN && <AdminRequestCompleteIcon className="ms-2" complete={completed} />}
            {surroundingGames.length > 0 ? <Icon iconName="fa-calendar" className="ms-2" /> : null}
            {comments && <Icon iconName="fa-comment" className="ms-2" />}
          </HeaderSection>
        </div>
      </Accordion.Header>
      <Accordion.Body onExpansion={onExpansion}>
        <div>
          <RequestAllotmentTableMobile allotment={allotment} category={category} />
        </div>

        <AttributeHeader>Recipient</AttributeHeader>
        <AttributeValue>{recipient}</AttributeValue>

        <AttributeHeader>Qty</AttributeHeader>
        <AttributeValue>{quantity}</AttributeValue>

        <AttributeHeader>Category</AttributeHeader>
        <AttributeValue>{category}</AttributeValue>

        <AttributeHeader>Pay By</AttributeHeader>
        <AttributeValue>{payBy}</AttributeValue>

        {maxPrice !== null && payBy !== PAY_BY_COMP && (
          <>
            <AttributeHeader>Max Ticket Price</AttributeHeader>
            <AttributeValue>{maxPrice}</AttributeValue>
          </>
        )}

        <AttributeHeader>Project</AttributeHeader>
        <AttributeValue>{project}</AttributeValue>

        <AttributeHeader>Business</AttributeHeader>
        <AttributeValue>{business}</AttributeValue>

        <AttributeHeader>Request Submitted</AttributeHeader>
        <AttributeValue>{formatSubmittedDate(submitted)}</AttributeValue>

        {status.requestStateId === APPROVED && (
          <>
            <AttributeHeader>Fulfillment Type</AttributeHeader>
            <AttributeValue>{fulfillmentType}</AttributeValue>
          </>
        )}

        {surroundingGames.length > 0 && (
          <div className="d-flex mt-3">
            <Icon iconName="fa-calendar" />
            <SurroundingGames>
              {games.map((game) => (
                <div className="mb-2" key={game.gamePk}>
                  <SurroundingGame
                    description={game.seriesDescription}
                    gameDate={game.gameDate}
                    timeZone={game.venue.timeZone}
                    tbd={!!game.status ? game.status.startTimeTBD : null}
                    home={game.teams.home.team}
                    away={game.teams.away.team}
                  />
                </div>
              ))}
            </SurroundingGames>
          </div>
        )}

        {comments && (
          <CommentsContainer>
            <Icon iconName="fa-comment" />
            <CommentsText>{comments}</CommentsText>
          </CommentsContainer>
        )}

        {adminComments && (
          <CommentsContainer>
            <Icon iconName="fa-comment" />
            <CommentsText>
              <b>Admin Comments:</b>&nbsp;{adminComments}
            </CommentsText>
          </CommentsContainer>
        )}

        <AdminRequestActionCheckList actions={actions} />
      </Accordion.Body>
    </Accordion>
  );
};

export default AdminRequestAccordion;

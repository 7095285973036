import { Button, Card, isAfter, StickyFooterButtons, useLoading } from "best-common-react-2";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  exportPurchaseOpportunity,
  getAdminPurchaseOpportunity,
  runPurchaseSelection,
  sendPurchaseEmails,
} from "../../../api/RequesTixApi";
import AdminPurchaseOpportunityGame from "../../../components/purchaseOpportunity/AdminPurchaseOpportunityGame";
import PurchaseOpportunityCard from "../../../components/purchaseOpportunity/PurchaseOpportunityCard";
import RouteConstants from "../../../constants/RouteConstants";
import { AdminPurchaseOpportunityDTO, PurchaseOpportunityGameDTO } from "../../../types/PurchaseOpportunity";
import { downloadLink, FileTypes } from "../../../util/FileUtil";

const Container = styled.div`
  display: flex;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
`;

const Games = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 768px) {
    margin-left: 1rem;
  }

  @media (max-width: 767px) {
    margin-top: 1rem;
  }
`;

type RouteParams = {
  id: string;
};

const AdminPurchaseOpportunityView = () => {
  const history = useHistory();
  const { setLoading } = useLoading();
  const { id } = useParams<RouteParams>();
  const [opp, setOpp] = useState<AdminPurchaseOpportunityDTO>(undefined);
  const [allGamesProcessed, setAllGamesProcessed] = useState<boolean>(false);
  const [pastCutoff, setPastCutoff] = useState<boolean>(false);

  const goBack = () => {
    history.push(RouteConstants.ADMIN.PURCHASE_OPPORTUNITY.BASE);
  };

  const fetchOpportunity = async (id: string) => {
    try {
      setLoading(true);
      const res: AdminPurchaseOpportunityDTO = await getAdminPurchaseOpportunity(id);
      setOpp(res);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const exportData = async () => {
    try {
      setLoading(true);
      const response = await exportPurchaseOpportunity(opp.purchaseOpportunityId);
      downloadLink(response, FileTypes.EXCEL);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const runSelection = async () => {
    try {
      setLoading(true);
      await runPurchaseSelection(opp.purchaseOpportunityId);
      await fetchOpportunity(id);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const sendEmails = async () => {
    try {
      setLoading(true);
      await sendPurchaseEmails(opp.purchaseOpportunityId);
      await fetchOpportunity(id);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!!id) {
      void fetchOpportunity(id);
    }
  }, [id]);

  useEffect(() => {
    setAllGamesProcessed(
      !!opp && !opp.purchaseOpportunityGames.map((g: PurchaseOpportunityGameDTO) => g.processed).includes(false)
    );
    setPastCutoff(!!opp && isAfter(new Date(), new Date(opp.cutoffDate as string)));
  }, [opp]);

  return (
    <Card>
      <Card.Header>Purchase Opportunity</Card.Header>
      <Card.Body>
        <Container>
          {!!opp && (
            <>
              <PurchaseOpportunityCard opp={opp} />
              <Games>
                {opp.purchaseOpportunityGames.map((game: PurchaseOpportunityGameDTO) => (
                  <AdminPurchaseOpportunityGame opp={opp} key={game.purchaseOpportunityGameId} game={game} />
                ))}
              </Games>
            </>
          )}
        </Container>
      </Card.Body>
      <Card.Footer>
        <StickyFooterButtons>
          <Button variant="default" onClick={goBack}>
            Go Back
          </Button>
          <Button variant="primary" disabled={allGamesProcessed || !pastCutoff} onClick={runSelection}>
            Run Selection
          </Button>
          <Button variant="primary" disabled={!allGamesProcessed || opp?.emailsSent} onClick={sendEmails}>
            Send Emails
          </Button>
          <Button variant="secondary" onClick={exportData} disabled={!opp?.purchaseOpportunityId}>
            Export
          </Button>
        </StickyFooterButtons>
      </Card.Footer>
    </Card>
  );
};

export default AdminPurchaseOpportunityView;

import { Button, Card, StickyFooterButtons, useLoading } from "best-common-react-2";
import React, { useEffect, useState } from "react";
import { getRequest, getVenueAcceptedBillings, saveRequest } from "../../api/RequesTixApi";
import RequestInformation from "../../components/requests/form/RequestInformation";
import TicketRequestForm from "../../components/requests/form/TicketRequestForm";
import DeleteTicketRequest from "../../components/requests/personal/DeleteTicketReqest";
import RouteConstants from "../../constants/RouteConstants";
import { TicketRequestProvider, useTicketRequest } from "../../contexts/TicketRequestContext";
import { UserAllotmentCountDTO } from "../../types/Allotment";
import { TicketRequest } from "../../types/TicketRequest";
import { useRequestValidation, useValidCreditCard } from "../../util/TicketRequestUtil";

const massageData = (data) => ({
  ...data,
  type: data.requestCategory ? data.requestCategory.requestCategoryName : null,
  requesterId: data.requester ? data.requester.employeeUserId : null,
  submitterId: data.submitter ? data.submitter.employeeUserId : null,
  projectId: data.project ? data.project.projectId : null,
  businessId: data.business ? data.business.businessId : null,
  surroundingGames: data.surroundingGames ? data.surroundingGames.map((g) => g?.surroundingGamePk?.toString()) : null,
  maxPriceOptionId: data.maxPriceOption ? data.maxPriceOption.maxPriceOptionId : null,
});

const convertRequest = (request) => ({
  ...request,
  quantity: request.quantity,
  submitterId: request.submitter.employeeUserId,
  requesterId: request.requester.employeeUserId,
  approvingSupervisorId: request.approvingSupervisor ? request.approvingSupervisor.employeeUserId : null,
});

const TicketRequestEdit = ({ request, setRequest, close }) => {
  const { setLoading } = useLoading();
  const { game } = useTicketRequest();
  const [formValid, setFormValid] = useState<boolean>(false);
  const [userId, setUserId] = useState<number>();
  const [allotment, setAllotment] = useState<UserAllotmentCountDTO>(undefined);
  const [allotments, setAllotments] = useState<UserAllotmentCountDTO[]>([]);
  const [hasEdited, setHasEdited] = useState<boolean>(false);
  const [venueBillings, setVenueBillings] = useState(null);
  const isRequestValid = useRequestValidation(request, allotment);
  const isCardValid = useValidCreditCard(request, venueBillings, game?.venue);

  const save = () => {
    if (formValid) {
      setLoading(true);
      const requestToSave = convertRequest(request);
      saveRequest(requestToSave).then(() => {
        close();
      });
    }
  };

  const changeRequest = (value) => {
    setRequest(value);
    setHasEdited(true);
  };

  useEffect(() => {
    if (game?.venue?.id) {
      getVenueAcceptedBillings(game.venue.id).then((data) => {
        setVenueBillings(data);
      });
    }
  }, [game]);

  useEffect(() => {
    if (request && request.type) {
      setAllotment(
        allotments.find(
          (allotment) =>
            allotment.season.seasonId === request.season.seasonId &&
            allotment.allotmentType.allotmentTypeName.toLowerCase() === request.type.toLowerCase()
        )
      );
    }
  }, [allotments, request]);

  useEffect(() => {
    setFormValid(isRequestValid && hasEdited && isCardValid);
  }, [isRequestValid, hasEdited, isCardValid]);

  useEffect(() => {
    if (!!request) {
      setUserId(request.requester.employeeUserId);
    }
  }, [request]);

  return (
    <Card>
      <Card.Header>Ticket Requests</Card.Header>
      <Card.Body>
        {!!request && !!request.requestId && request.requester && userId ? (
          <>
            <div className="d-flex flex-md-nowrap flex-wrap">
              <RequestInformation
                originalGamePk={request.originalGamePk}
                type={request.type}
                userId={userId}
                setAccessible={() => {}}
                setAllotments={setAllotments}
                allotments={allotments}
              />
              <TicketRequestForm ticketRequest={request} setTicketRequest={changeRequest} allotment={allotment} />
            </div>
          </>
        ) : (
          <div />
        )}
      </Card.Body>
      <Card.Footer>
        <StickyFooterButtons startsOpen={false}>
          <DeleteTicketRequest requestId={request.requestId} goBack={close} />
          <Button variant="primary" onClick={save} disabled={!formValid}>
            Save
          </Button>
          <Button variant="default" onClick={close}>
            {formValid ? "Cancel" : "Close"}
          </Button>
        </StickyFooterButtons>
      </Card.Footer>
    </Card>
  );
};

const TicketRequestEditContainer = ({ history, match, location }) => {
  const { requestId } = match.params;
  const { setLoading } = useLoading();
  const [request, setRequest] = useState<TicketRequest>();

  const close = () => {
    let go;
    if (location && location.state && location.state.previousLocation) {
      go = location.state.previousLocation;
    } else {
      go = RouteConstants.REQUESTS.BASE;
    }
    history.push(go);
  };

  const getRequestData = () => {
    try {
      setLoading(true);
      getRequest(requestId).then((data) => {
        setRequest(massageData(data));
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (requestId) {
      getRequestData();
    }
  }, [requestId]);

  return (
    <TicketRequestProvider gamePk={request?.gamePk.toString()}>
      {!!request && <TicketRequestEdit request={request} setRequest={setRequest} close={close} />}
    </TicketRequestProvider>
  );
};

export default TicketRequestEditContainer;

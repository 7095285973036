import { Button, Modal } from "best-common-react-2";
import React from "react";
import { BillingDTO } from "../../types/UserBilling";
import useCyberSource from "../../util/CreditCardUtil";
import CreditCardForm from "./CreditCardForm";

type NewCreditCardModalProps = {
  show: boolean;
  onClose: (value: BillingDTO | undefined) => void;
  userId: number;
};

const NewCreditCardModal: React.FC<NewCreditCardModalProps> = ({ show, onClose, userId }) => {
  const { creditCard, setCreditCard, validCreditCard, onSave, onCancel } = useCyberSource(null, userId);
  const [number, setNumber] = React.useState("");
  const [valid, setValid] = React.useState(false);

  React.useEffect(() => {
    onCancel();
  }, [show]);

  const save = () => {
    onSave(number)
      .then((userBilling) => {
        onClose(userBilling);
      })
      .catch(() => {});
  };

  React.useEffect(() => {
    setValid(validCreditCard(creditCard));
  }, [creditCard]);

  return (
    <Modal show={show} size="xl">
      <Modal.Header close={() => onClose(undefined)}>Add a credit card</Modal.Header>
      <Modal.Body>
        <CreditCardForm cc={creditCard} setCC={setCreditCard} number={number} setNumber={setNumber} />
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <Button variant="primary" className="me-2" disabled={!valid} onClick={save}>
            Save
          </Button>
          <Button variant="default" onClick={() => onClose(undefined)}>
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default NewCreditCardModal;

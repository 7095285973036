import { getDigitalTickets } from "../api/RequesTixApi";
import {
  BoxOfficeForwardDTO,
  BoxOfficeTicketDTO,
  BoxOfficeTicketsDTO,
  BoxOfficeUnsentTicketDTO,
} from "../types/Tickets";

export const fetchDigitalTickets = async (
  gamePk: number | string,
  homeTeamId: number | string,
  existingForwardId?: string,
  refresh = false
): Promise<BoxOfficeTicketDTO[]> => {
  const res: BoxOfficeTicketsDTO = await getDigitalTickets(gamePk, homeTeamId, refresh);
  let tickets: BoxOfficeTicketDTO[] = res.tickets.map((t: BoxOfficeUnsentTicketDTO) => {
    const forward: BoxOfficeForwardDTO[] =
      res.forwards?.filter((f: BoxOfficeForwardDTO) => f.forwardID === existingForwardId) || [];
    const revokable: boolean = !!forward.length ? forward[0].canRecall : false;
    return {
      ...t,
      revokable: revokable,
    };
  });
  const acceptedTickets: BoxOfficeTicketDTO[][] = res.forwards.map((forward: BoxOfficeForwardDTO) => {
    return forward.tickets.map((t: BoxOfficeTicketDTO) => {
      return {
        ...t,
        revokable: !!forward?.canRecall,
      };
    });
  });
  const flat: BoxOfficeTicketDTO[] = [].concat(...acceptedTickets);
  tickets = tickets.concat(flat);
  tickets = tickets.filter(
    (ticket: BoxOfficeTicketDTO, index: number, self: BoxOfficeTicketDTO[]) =>
      self.findIndex((t: BoxOfficeTicketDTO) => t.ticketID === ticket.ticketID) === index
  );
  return tickets;
};

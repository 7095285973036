import { DataTableDataType, FormatterType, TextFormatter } from "best-common-react-2";
import React from "react";
import { Business } from "../../types/Business";

const BusinessFormatter = <T extends DataTableDataType>({ value, ...rest }: FormatterType<T>) => {
  const business: Business = value;
  return <TextFormatter {...rest} value={business?.businessName} />;
};

export default BusinessFormatter;

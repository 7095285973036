import { Accordion, BestLink, Icon } from "best-common-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { OPEN } from "../../../constants/RequestTableConstants";
import RouteConstants, { urlReplace } from "../../../constants/RouteConstants";
import { getStatusColor, removeRequestId } from "../../../util/TicketRequestUtil";
import { useTimezone } from "../../../util/TimeUtil";
import MatchUp from "../../game/MatchUp";
import ProjectRequestDetails from "./ProjectRequestsDetails";

const Status = styled.div.attrs(() => ({
  className: "px-3 py-2 d-flex justify-content-between",
}))`
  height: 2.5625rem;
  background: ${(props) => props.color};
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  border-right: 1px solid rgba(0, 0, 0, 0.125);
`;

const Header = styled.div`
  font-size: 1.125rem;
  font-weight: bold;
  line-height: ${(props) => props.theme.lineHeight["eventGrid"]};
`;

const Description = styled.div`
  font-size: 0.875rem;
  color: ${(props) => props.theme["dark-grey"]};
  line-height: ${(props) => props.theme.lineHeight["eventGrid"]};
`;

const SubDescription = styled.div`
  font-size: 0.875rem;
  color: #666666;
`;

const ProjectRequestsByProjectAccordion = ({ data: request, ...rest }) => {
  const [gameTime, setGameTime] = useState({});

  useEffect(() => {
    setGameTime(useTimezone(request.game.gameDate, request.game.venue.timeZone, request.game.status));
  }, [request]);

  return (
    <div>
      <Status color={getStatusColor(request.requestId, request.status.requestStateId)}>
        <div>{request.status.requestStateName}</div>
        {request.status.requestStateId === OPEN ? (
          <BestLink
            to={{
              pathname: urlReplace(RouteConstants.REQUESTS.EDIT, { requestId: request.requestId }),
              state: { previousLocation: { ...request.location, search: removeRequestId(request.location) } },
            }}
          >
            <Icon iconName="fa-pencil-alt" />
          </BestLink>
        ) : null}
      </Status>
      <Accordion {...rest}>
        <Accordion.Header isToggle={true}>
          <div className="d-flex justify-content-between">
            <div>
              <Header>{gameTime.date}</Header>
              <Description>
                {gameTime.day}
                <span className="ms-2">{gameTime.time}</span>
              </Description>
            </div>
            <MatchUp
              home={request.game.teams.home.team}
              away={request.game.teams.away.team}
              description={request.game.seriesDescription}
            />
          </div>
          <SubDescription>
            {request.quantity} ticket(s) for {request.business}
          </SubDescription>
        </Accordion.Header>
        <Accordion.Body>
          <ProjectRequestDetails value={request} />
        </Accordion.Body>
      </Accordion>
    </div>
  );
};

export default ProjectRequestsByProjectAccordion;

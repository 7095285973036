import React from "react";
import styled from "styled-components";
import { TeamHomeAway } from "../../types/Team";
import { Venue } from "../../types/Venue";
import { formatGameDate } from "../../util/TimeUtil";
import MatchUp from "./MatchUp";

const GameDateHolder = styled.div.attrs(() => ({
  className: "me-2",
}))`
  width: 11.5rem;
  text-decoration: none;
  font-size: 0.875rem;
  line-height: 1.625rem;
  &:hover {
    text-decoration: none;
  }
`;

type GameInfoProps = {
  gameDate: string | Date;
  venue: Venue;
  away: TeamHomeAway;
  home: TeamHomeAway;
  seriesDescription?: string;
};

const GameInfo: React.FC<GameInfoProps> = ({ gameDate, home, away, venue, seriesDescription }) => (
  <div className="d-flex flex-wrap">
    <GameDateHolder>{formatGameDate(gameDate, venue.timeZone, false)}</GameDateHolder>
    <MatchUp away={away.team} home={home.team} description={seriesDescription} />
  </div>
);

export default GameInfo;

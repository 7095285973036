import { BestLabel, DatePickerInput } from "best-common-react";
import React from "react";
import styled from "styled-components";
import { DateRange, DateRangeFromStart } from "../../types/Dates";

type SpacerProps = {
  leftLabel?: string;
  rightLabel?: string;
};

// @ts-ignore
const Spacer = styled.div.attrs(({ leftLabel, rightLabel }) => ({
  className: leftLabel || rightLabel ? "mt-3 mx-2" : "mx-2",
}))<SpacerProps>`
  font-weight: bold;
  font-size: 1.3rem;
`;

type DateRangePickerSeparateProps = {
  value: DateRange;
  onChange: (value: DateRangeFromStart) => void;
  maxStartDate?: Date;
  maxEndDate?: Date;
  leftLabel?: string;
  rightLabel?: string;
};

const DateRangePickerSeparate: React.FC<DateRangePickerSeparateProps> = ({
  value,
  onChange,
  maxStartDate = undefined,
  maxEndDate = undefined,
  leftLabel = "",
  rightLabel = "",
}) => (
  <div className="d-flex align-items-center" id="date-range-picker">
    <div>
      {(rightLabel || leftLabel) && (
        <BestLabel htmlFor="date-range-start" required={false}>
          {leftLabel}
        </BestLabel>
      )}
      {/*@ts-ignore*/}
      <DatePickerInput
        value={value.start}
        onChange={(val) => onChange({ fromStart: true, startDate: val, endDate: value.end })}
        name="date-range-start"
        maxDate={maxStartDate}
      />
    </div>
    <Spacer rightLabel={rightLabel} leftLabel={leftLabel}>
      -
    </Spacer>
    <div>
      {(rightLabel || leftLabel) && (
        <BestLabel htmlFor="date-range-end" required={false}>
          {rightLabel}
        </BestLabel>
      )}
      {/*@ts-ignore*/}
      <DatePickerInput
        value={value.end}
        onChange={(val) => onChange({ fromStart: false, startDate: value.start, endDate: val })}
        minDate={value.start}
        maxDate={maxEndDate}
        name="date-range-end"
      />
    </div>
  </div>
);

export default DateRangePickerSeparate;

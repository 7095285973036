import {
  DateInput,
  FormColumn,
  Input,
  RadioGroup,
  RadioValue,
  randomString,
  Row,
  Select,
  ValueOpt,
} from "best-common-react-2";
import React, { useState } from "react";
import styled from "styled-components";
import { useDropdowns } from "../../contexts/DropdownsContext";

type StyleProps = {
  disabled: boolean;
};

const SelectedText = styled.span<StyleProps>`
  display: block;
  font-size: 11px;
  color: ${(props) => (props.disabled ? props.theme["grey"] : "#000")};
  ${(props) => props.disabled && "font-style: oblique"};
`;

const DateHolder = styled.div`
  margin-left: 1rem;
  @media (min-width: 992px) and (max-width: 1200px) {
    margin-left: 0.25rem;
  }
`;

const BeforeHolder = styled.div`
  margin-top: 1rem;
  margin-left: 1rem;
  @media (min-width: 992px) and (max-width: 1200px) {
    margin-left: 0.25rem;
  }
`;

type AccessCutoffProps = {
  override: any;
  setOverride: (value: any) => void;
  isCutoff?: boolean;
  timezone?: string;
};

const AccessCutoff = ({ override, setOverride, isCutoff = false }: AccessCutoffProps) => {
  const [id] = useState<string>(randomString());
  const { active = true, date, hoursBefore, useDate } = override;
  const { hoursOptions } = useDropdowns();

  const handleDaysChange = (value: number) => {
    if (!isNaN(value)) {
      setOverride({ ...override, hoursBefore: (hoursBefore % 24) + value * 24 });
    }
  };

  const changeType = (value: RadioValue) => {
    setOverride({
      ...override,
      useDate: value === 1,
    });
  };

  return (
    <>
      <RadioGroup
        id={`cutoff-type-${id}`}
        name={`cutoff-type-${id}`}
        disabled={!active}
        value={useDate ? 1 : 2}
        options={[
          {
            value: 1,
            label: "Start Date",
          },
          {
            value: 2,
            label: "Offset From Game Time",
          },
        ]}
        onChange={(value) => {
          changeType(value);
        }}
        gutterBottom
      />
      {useDate ? (
        <DateHolder>
          <DateInput
            id={`overrideDate-${id}`}
            label={!isCutoff ? "Start Date" : "End Date"}
            value={date ? new Date(date) : null}
            showTimeSelect={true}
            disabled={!active || !useDate}
            onChange={(value: Date) => setOverride({ ...override, date: value })}
            withPortal
            gutterBottom
          />
        </DateHolder>
      ) : (
        <>
          <Row>
            <FormColumn width={2}>
              <Input
                id={`days-change-${id}`}
                disabled={!active || useDate}
                type="number"
                onChange={(value: string) => handleDaysChange(parseInt(value))}
                value={Math.floor(hoursBefore / 24)}
                gutterBottom
              />
              <SelectedText disabled={!active || useDate}>
                {Math.floor(hoursBefore / 24) === 1 ? "day" : "days"}
              </SelectedText>
            </FormColumn>
            <FormColumn width={2}>
              <Select
                id="hoursBefore"
                disabled={!active || useDate}
                options={hoursOptions}
                value={{
                  label: hoursBefore % 24,
                  value: hoursBefore % 24,
                }}
                onChange={(value: ValueOpt<number>) =>
                  setOverride({ ...override, hoursBefore: Math.floor(hoursBefore / 24) * 24 + value.value })
                }
                gutterBottom
              />
              <SelectedText disabled={!active || useDate}>{hoursBefore % 24 === 1 ? "hr" : "hrs"}</SelectedText>
            </FormColumn>
          </Row>
          <BeforeHolder>
            <Select
              id={`before-${id}`}
              disabled={!active || useDate}
              options={[
                {
                  label: "Before",
                  value: "Before",
                },
              ]}
              value={{
                label: "Before",
                value: "Before",
              }}
              onChange={() => {}}
              gutterBottom
            />
          </BeforeHolder>
        </>
      )}
    </>
  );
};

export default AccessCutoff;

import { Accordion, Table, TableHeader, TableTdInfo } from "best-common-react";
import React from "react";
import styled from "styled-components";
import ToLinkFormatter from "../formatters/ToLinkFormatter";

const Container = styled.div`
  font-size: 0.875rem;
  line-height: normal;
`;

const Bold = styled.div`
  font-weight: bold;
`;

const Light = styled.div`
  font-weight: 300;
`;

const Th = styled.th`
  &&&& {
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
  }
`;

const Td = styled.td`
  &&& {
    text-align: center;
    vertical-align: middle;
    border: none;
    border-bottom: 1px solid #dee2e6;
  }
`;

const AllUsersAccordion = ({
  data: {
    requesterId,
    recipient,
    department,
    personalComp,
    personalApproved,
    personalCard,
    personalDenied,
    personalTotal,
    businessComp,
    businessApproved,
    businessCard,
    businessDept,
    businessDenied,
    businessTotal,
  },
  ...rest
}) => {
  return (
    <Container key={requesterId}>
      <Accordion {...rest}>
        <Accordion.Header isToggle={true}>
          <ToLinkFormatter className="h-100 d-flex align-items-center" value={recipient} />
        </Accordion.Header>
        <Accordion.Body style={{ backgroundColor: "#f7f7f7" }}>
          <div>
            <div className="mb-3">
              <Bold>Department</Bold>
              <Light>{department.value}</Light>
            </div>

            <Table>
              <TableHeader>
                <tr>
                  <th style={{ width: "33%", height: "2rem" }} />
                  <Th className="text-center">Personal</Th>
                  <Th className="text-center">Business</Th>
                </tr>
              </TableHeader>
              <tbody>
                <tr>
                  <TableTdInfo>Approved</TableTdInfo>
                  <Td>
                    <ToLinkFormatter value={personalApproved} />
                  </Td>
                  <Td>
                    <ToLinkFormatter value={businessApproved} />
                  </Td>
                </tr>
                <tr>
                  <TableTdInfo>Comp</TableTdInfo>
                  <Td>
                    <ToLinkFormatter value={personalComp} />
                  </Td>
                  <Td>
                    <ToLinkFormatter value={businessComp} />
                  </Td>
                </tr>
                <tr>
                  <TableTdInfo>Card</TableTdInfo>
                  <Td>
                    <ToLinkFormatter value={personalCard} />
                  </Td>
                  <Td>
                    <ToLinkFormatter value={businessCard} />
                  </Td>
                </tr>
                <tr>
                  <TableTdInfo>Dept</TableTdInfo>
                  <Td />
                  <Td>
                    <ToLinkFormatter value={businessDept} />
                  </Td>
                </tr>
                <tr>
                  <TableTdInfo>Denied</TableTdInfo>
                  <Td>
                    <ToLinkFormatter value={personalDenied} />
                  </Td>
                  <Td>
                    <ToLinkFormatter value={businessDenied} />
                  </Td>
                </tr>
                <tr>
                  <TableTdInfo>Total</TableTdInfo>
                  <Td>
                    <ToLinkFormatter value={personalTotal} />
                  </Td>
                  <Td>
                    <ToLinkFormatter value={businessTotal} />
                  </Td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Accordion.Body>
      </Accordion>
    </Container>
  );
};

export default AllUsersAccordion;

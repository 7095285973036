import { BestLabel, BestSelect, LightButton, Modal, PrimaryButton, Table, TableHeader } from "best-common-react";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import UserAllotmentConstants from "../../constants/UserAllotmentConstants";
import UserAllotmentBulkUpdateTr from "./UserAllotmentBulkUpdateTr";

const DEFAULT_ALLOTMENT = {
  unlimited: false,
  amount: "",
};

const SelectedUsers = styled.span.attrs(() => ({
  className: "align-self-end",
}))`
  font-size: 0.8rem;
  color: ${(props) => props.theme["dark-grey"]};
  font-style: italic;
`;

const UserAllotmentBulkUpdate = ({ isOpen = false, year, onClose, onSave, seasons, userCount }) => {
  const [selectedSeason, setSelectedSeason] = useState();
  const [seasonOptions, setSeasonOptions] = useState([]);
  const [allotments, setAllotments] = useState([]);
  const [hasEdited, setHasEdited] = React.useState(false);

  useEffect(() => {
    const seasonsOrdered = seasons.sort((a, b) => (a.value.seasonId < b.value.seasonId ? -1 : 1));
    const seasonGroupBy = _.groupBy(
      seasonsOrdered.map((season) => season.value),
      "year"
    );
    const currentSeasonOptions = Object.keys(seasonGroupBy)
      .map((seasonKey) => ({
        label: seasonKey,
        value: seasonGroupBy[seasonKey],
      }))
      .reverse();
    setSeasonOptions(currentSeasonOptions);

    const currentSelectedSeason = currentSeasonOptions.find(
      (season) => year && season.label.toString() === year.toString()
    );
    setSelectedSeason(currentSelectedSeason);
  }, [seasons, year]);

  useEffect(() => {
    if (selectedSeason && selectedSeason.value) {
      const newAllotments = selectedSeason.value.map((season) => {
        const allotment = {};
        allotment.season = season;
        allotment[UserAllotmentConstants.BUSINESS] = DEFAULT_ALLOTMENT;
        allotment[UserAllotmentConstants.PERSONAL] = DEFAULT_ALLOTMENT;
        return allotment;
      });
      setAllotments(newAllotments);
    }
  }, [selectedSeason]);

  const changeAllotment = (index, type, unlimited, amount) => {
    if (allotments && allotments.length) {
      const tempAllotments = [...allotments];
      const num = amount && amount.length ? parseInt(amount) : null;
      tempAllotments[index][type] = {
        unlimited: unlimited,
        amount: num,
      };
      setAllotments(tempAllotments);
      setHasEdited(true);
    }
  };

  return (
    <>
      {!!isOpen && (
        <Modal show={isOpen} size="lg">
          <Modal.HeaderWithTitleAndClose title="Update Allotments" close={onClose} />
          <Modal.Body>
            <div>
              <div className="row">
                <div className="col">
                  <div className="d-flex justify-content-between">
                    <div>
                      <BestLabel htmlFor="year">Year</BestLabel>
                      <BestSelect
                        id="year"
                        options={seasonOptions}
                        value={selectedSeason}
                        onChange={setSelectedSeason}
                      />
                    </div>
                    <SelectedUsers>{userCount} users selected</SelectedUsers>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col">
                  <Table>
                    <TableHeader>
                      <tr>
                        <th>Season</th>
                        <th>Personal</th>
                        <th>Business</th>
                      </tr>
                    </TableHeader>
                    <tbody>
                      {selectedSeason && selectedSeason.value && (
                        <>
                          {selectedSeason.value.map((season, index) => (
                            <UserAllotmentBulkUpdateTr
                              allotments={allotments}
                              season={season}
                              index={index}
                              key={index}
                              changeAllotment={changeAllotment}
                            />
                          ))}
                        </>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <PrimaryButton
              disabled={!hasEdited}
              onClick={() => {
                onSave(allotments);
              }}
            >
              Save
            </PrimaryButton>
            <LightButton onClick={onClose}>Cancel</LightButton>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default UserAllotmentBulkUpdate;

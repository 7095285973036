import { BestLink, BestTable, FormColumn4Wide, Icon, LightButton, Table, TableHeader } from "best-common-react";
import { useLoading } from "best-common-react-2";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getReceiptPDF, getReconciliationRequests } from "../../../api/RequesTixApi";
import RouteConstants from "../../../constants/RouteConstants";
import { downloadLink, FileTypes } from "../../../util/FileUtil";
import { urlReplace } from "../../../util/RouteUtil";
import { sortWithFunction } from "../../../util/SortUtil";
import { includes, toLower, toMoney } from "../../../util/StringUtil";
import { format } from "../../../util/TimeUtil";
import { DateFormatter } from "../../tables/DateFormatter";
import GameDateFormatter from "../../tables/GameDateFormatter";
import { MoneyFormatter } from "../../tables/MoneyFormatter";

const EventsSize = styled.span`
  font-size: 0.8rem;
  color: ${(props) => props.theme["dark-grey"]};
  font-style: italic;
`;

const RequestFormatter = ({ value }) => {
  const url = urlReplace(RouteConstants.ADMIN.REQUESTS_EDIT, { requestId: value });
  return <BestLink to={url}>{value}</BestLink>;
};

const Columns = [
  {
    name: "Request",
    key: "requestId",
    minWidth: 100,
    sortable: true,
    formatter: RequestFormatter,
  },
  {
    name: "Charge Date",
    key: "chargeDate",
    minWidth: 150,
    sortable: true,
    formatter: DateFormatter,
  },
  {
    name: "Total Ticket Cost",
    key: "totalTicketCost",
    minWidth: 150,
    sortable: true,
    cellClass: "text-center",
    formatter: MoneyFormatter,
  },
  {
    name: "Department",
    key: "department",
    minWidth: 200,
    sortable: true,
  },
  {
    name: "Requester",
    key: "requester",
    minWidth: 300,
    sortable: true,
  },
  {
    name: "# of Tickets",
    key: "quantity",
    minWidth: 100,
    sortable: true,
  },
  {
    name: "Business",
    key: "business",
    minWidth: 200,
    sortable: true,
  },
  {
    name: "Game",
    key: "game",
    minWidth: 350,
    formatter: GameDateFormatter,
    sortable: true,
  },
  {
    name: "Order Charged",
    key: "orderCost",
    minWidth: 125,
    sortable: true,
    formatter: MoneyFormatter,
  },
  {
    name: "Admin comments",
    key: "adminComments",
    minWidth: 400,
    sortable: true,
  },
];

const freeTextFilter = (data, search) =>
  search && search.length
    ? data.filter(
        (data) =>
          includes(data.business, search) ||
          includes(data.requestId, search) ||
          includes(data.gamePk, search) ||
          includes(data.department, search) ||
          includes(data.quantity, search) ||
          includes(data.totalTicketCost, search) ||
          includes(data.requester, search) ||
          includes(data.orderCost, search)
      )
    : data;

const canExportReceipt = (value) => value.fulfillmentType === "charge card";

const ReconciliationDataTable = ({ reportData, dateRange, location, setCanExport }) => {
  const { setLoading } = useLoading();
  const [data, setData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [sortFilters, setSortFilters] = useState({ key: "chargedDate", direction: BestTable.ASC });
  const [totalTickets, setTotalTickets] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [selectedRequestsIds, setSelectedRequestIds] = useState([]);

  const onSort = (key, direction) => setSortFilters({ key, direction });

  const fetchData = async (dateRange, fulfillmentTypeId, homeTeamId) => {
    try {
      setLoading(true);
      const results = await getReconciliationRequests(
        format(dateRange.start),
        format(dateRange.end),
        fulfillmentTypeId,
        homeTeamId
      );
      setData(
        results.map((r) => ({
          ...r,
          id: r.requestId,
          isSelected: false,
        }))
      );
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const rowSelected = (rows) => {
    const selected = rows.filter((r) => canExportReceipt(r)).map((r) => r.requestId);
    const requestIds = selectedRequestsIds.concat(selected);
    setSelectedRequestIds(requestIds);
    setData(
      data.map((d) => ({
        ...d,
        isSelected: requestIds.includes(d.requestId),
      }))
    );
  };

  const rowDeselected = (rows) => {
    const deselected = rows.map((r) => r.requestId);
    const requestIds = selectedRequestsIds.filter((id) => !deselected.includes(id));
    setSelectedRequestIds(requestIds);
    setData(
      data.map((d) => ({
        ...d,
        isSelected: requestIds.includes(d.requestId),
      }))
    );
  };

  const downloadReceipt = async (requestId) => {
    try {
      const response = await getReceiptPDF(requestId);
      downloadLink(response, FileTypes.PDF);
      return Promise.resolve();
    } catch (e) {
      console.error(e);
      return Promise.reject();
    }
  };

  const downloadReceipts = () => {
    setLoading(true);
    Promise.all(selectedRequestsIds.map((requestId) => downloadReceipt(requestId))).then((response) => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (dateRange.start && dateRange.end) {
      fetchData(dateRange, reportData.fulfillmentTypeId, reportData.homeTeamId);
    }
  }, [dateRange, reportData.fulfillmentTypeId, reportData.homeTeamId]);

  useEffect(() => {
    if (data) {
      const dir = sortFilters.direction === BestTable.ASC ? 1 : sortFilters.direction === BestTable.DESC ? -1 : 0;
      setDisplayData(
        sortWithFunction(freeTextFilter(data, reportData.search), sortFilters.key, dir, (value) => {
          if (sortFilters.key === "game") {
            return value.gameDate;
          } else {
            return value ? toLower(value) : "";
          }
        })
      );
    } else {
      setDisplayData([]);
    }
  }, [data, sortFilters, reportData.search]);

  useEffect(() => {
    let tickets = 0;
    let cost = 0;

    data.forEach((ticket) => {
      tickets += ticket.quantity;
      cost += ticket.totalTicketCost;
    });

    setTotalTickets(tickets);
    setTotalCost(cost);
  }, [data]);

  return (
    <div>
      <div className="row mt-2">
        <FormColumn4Wide>
          <Table>
            <TableHeader>
              <tr>
                <th>Ticket Qty</th>
                <th>Cost</th>
              </tr>
            </TableHeader>
            <tr>
              <td>{totalTickets}</td>
              <td>{toMoney(totalCost)}</td>
            </tr>
          </Table>
        </FormColumn4Wide>
      </div>
      <div className="row mt-2">
        <div className="col">
          <EventsSize>{data.length} projects</EventsSize>
          <BestTable
            columns={Columns}
            data={displayData}
            headerHeight={50}
            sortColumn={sortFilters.key}
            sortDirection={sortFilters.direction}
            sortFunction={onSort}
            rowSelection={{
              showCheckbox: true,
              onRowsSelected: rowSelected,
              onRowsDeselected: rowDeselected,
              selectBy: {
                isSelectedKey: "isSelected",
              },
              enableShiftSelect: true,
              canSelect: (value) => {
                return canExportReceipt(value);
              },
            }}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col">
          <LightButton onClick={downloadReceipts} disabled={!selectedRequestsIds.length}>
            <Icon iconName="fa-download" /> Receipt
          </LightButton>
        </div>
      </div>
    </div>
  );
};

export default ReconciliationDataTable;

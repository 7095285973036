import { Accordion, AttributeHeader, AttributeValue, Table, TableHeader, TableTdInfo } from "best-common-react";
import React from "react";
import styled from "styled-components";
import ToLinkFormatter from "../formatters/ToLinkFormatter";

const Tr = styled.tr`
  height: 1.875rem;
  padding: 0;
`;

const THead = styled(TableHeader)`
  &&& {
    border-bottom: 1px solid ${(props) => props.theme["grey"]};
  }
`;

const TdLabel = styled(TableTdInfo)`
  &&& {
    padding: 0 0 0 0.5rem;
    border: none;
    border-right: 1px solid ${(props) => props.theme["grey"]};
    height: 1.875rem;
  }
`;

const Td = styled.td`
  &&& {
    border: none;
    border-right: 1px solid #eceeef;
    height: 1.875rem;
    color: #000000;
    font-weight: normal;
    text-align: right;
    padding: 0.3125rem 0.5rem;
  }
`;

const ProjectRequestsAllProjectsAccordion = ({ data: request, ...rest }) => (
  <Accordion {...rest}>
    <Accordion.Header isToggle={true}>
      <ToLinkFormatter value={request.projectName} />
    </Accordion.Header>
    <Accordion.Body>
      <div>
        <AttributeHeader>Users</AttributeHeader>
        <AttributeValue>{request.userCount}</AttributeValue>
      </div>
      <div>
        <Table>
          <THead>
            <Tr>
              <th style={{ width: "30%" }} />
              <th style={{ "text-align": "right" }}>Business</th>
            </Tr>
          </THead>
          <tbody>
            <Tr>
              <TdLabel>Approved</TdLabel>
              <Td>
                <ToLinkFormatter value={request.approved} />
              </Td>
            </Tr>
            <Tr>
              <TdLabel>Comp</TdLabel>
              <Td>
                <ToLinkFormatter value={request.comped} />
              </Td>
            </Tr>
            <Tr>
              <TdLabel>Card</TdLabel>
              <Td>
                <ToLinkFormatter value={request.card} />
              </Td>
            </Tr>
            <Tr>
              <TdLabel>Dept</TdLabel>
              <Td>
                <ToLinkFormatter value={request.depy} />
              </Td>
            </Tr>
            <Tr>
              <TdLabel>Denied</TdLabel>
              <Td>
                <ToLinkFormatter value={request.denied} />
              </Td>
            </Tr>
            <Tr>
              <TdLabel>Total</TdLabel>
              <Td>
                <ToLinkFormatter value={request.total} />
              </Td>
            </Tr>
            <Tr>
              <TdLabel>Card Total</TdLabel>
              <Td>{request.cardTotalCost}</Td>
            </Tr>
            <Tr>
              <TdLabel>Dept Total</TdLabel>
              <Td>{request.deptTotalCost}</Td>
            </Tr>
            <Tr>
              <TdLabel>Total Cost</TdLabel>
              <Td>{request.totalCost}</Td>
            </Tr>
          </tbody>
        </Table>
      </div>
    </Accordion.Body>
  </Accordion>
);

export default ProjectRequestsAllProjectsAccordion;

import axios, { AxiosInstance } from "axios";
import axiosRetry from "axios-retry";

const API_URL = `https://statsapi.mlb.com/api`;

const client: AxiosInstance = axios.create({ baseURL: API_URL });
axiosRetry(client, {
  retries: 3,
  retryCondition: (error) => {
    console.error("Error fetching from API will retry...");
    return !!error?.response && (error.response.status === 401 || error.response.status === 403);
  },
  retryDelay: (retryCount) => retryCount * 1000,
});

export default client;

import { LightButton, Modal, PrimaryButton } from "best-common-react";
import { useLoading } from "best-common-react-2";
import React from "react";
import { revokeDayOfRequest } from "../../../api/RequesTixApi";

type AdminTicketsRevokeModalProps = {
  isOpen: boolean;
  onClose: () => void;
  requestId: number;
};

const AdminTicketsRevokeModal: React.FC<AdminTicketsRevokeModalProps> = ({ isOpen, onClose, requestId }) => {
  const { setLoading } = useLoading();

  const revokeTicketsCall = async () => {
    try {
      setLoading(true);
      await revokeDayOfRequest(requestId);
      onClose();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={isOpen} size="lg">
      <Modal.HeaderWithTitleAndClose title="Are you sure you want to revoke these tickets" close={onClose} />
      <Modal.Body>
        <div>Please confirm you wish to send a revoke these tickets</div>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton disabled={false} onClick={revokeTicketsCall}>
          Revoke
        </PrimaryButton>
        <LightButton onClick={onClose}>Cancel</LightButton>
      </Modal.Footer>
    </Modal>
  );
};

export default AdminTicketsRevokeModal;

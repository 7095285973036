import {
  BestSelect,
  FormColumn2Wide,
  Input,
  LightButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeaderWithTitleAndClose,
  PrimaryButton,
  RequiredInputWithLabel,
} from "best-common-react";
import { useLoading } from "best-common-react-2";
import React, { useEffect, useState } from "react";
import isEmail from "validator/lib/isEmail";
import { createUser } from "../../../api/RequesTixApi";
import { Department } from "../../../types/Department";
import { ValueOpt } from "../../../types/Dropdown";
import { CreateUserDTO } from "../../../types/User";

type AdminCreateUserType = {
  show?: boolean;
  close: (saved: boolean) => void;
  departments: ValueOpt<Department>[];
};

const AdminCreateUser: React.FC<AdminCreateUserType> = ({ show = false, close, departments = [] }) => {
  const { setLoading } = useLoading();
  const [user, setUser] = useState<CreateUserDTO>({});
  const [canSave, setCanSave] = useState<boolean>(false);
  const [departmentOptions, setDepartmentOptions] = useState<ValueOpt<Department>[]>([]);
  const [validEmail, setValidEmail] = React.useState(false);

  const closeForm = (saved: undefined | boolean = false) => {
    setUser({});
    close(saved);
  };

  const createUserCall = async () => {
    try {
      setLoading(true);
      await createUser(user);
      closeForm(true);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (key: string, value: string | number) => {
    setUser({ ...user, [key]: value });
  };

  const getDepartmentOption = (
    value: number | undefined,
    options: ValueOpt<Department>[]
  ): ValueOpt<Department> | undefined => {
    return options.find((opt: ValueOpt<Department>) => opt?.value?.departmentId === value);
  };

  useEffect(() => {
    if (user?.email) {
      const valid = isEmail(user?.email);
      setValidEmail(valid);
    } else {
      setValidEmail(false);
    }
  }, [user]);

  useEffect(() => {
    const allRequired =
      !!user?.firstName?.length &&
      !!user?.lastName?.length &&
      !!user?.email?.length &&
      !!user?.departmentId &&
      !!user?.directSupervisor?.length &&
      !!user?.jobTitle?.length &&
      !!user?.location?.length;
    setCanSave(allRequired && validEmail);
  }, [user, validEmail]);

  useEffect(() => {
    setDepartmentOptions(departments.filter((d: ValueOpt<Department>) => !!d.value));
  }, [departments]);

  return (
    <>
      {/*@ts-ignore*/}
      <Modal show={show} onClose={closeForm} size="xl">
        <ModalHeaderWithTitleAndClose title="Create User" close={closeForm} />
        <ModalBody>
          <div className="row">
            <FormColumn2Wide>
              <RequiredInputWithLabel label="First Name" name="firstName">
                <Input
                  id="firstName"
                  value={user.firstName}
                  onChange={(event) => handleChange("firstName", event.target.value)}
                />
              </RequiredInputWithLabel>
            </FormColumn2Wide>
            <FormColumn2Wide>
              <RequiredInputWithLabel label="Last Name" name="lastName">
                <Input
                  id="lastName"
                  value={user.lastName}
                  onChange={(event) => handleChange("lastName", event.target.value)}
                />
              </RequiredInputWithLabel>
            </FormColumn2Wide>
            <FormColumn2Wide>
              <RequiredInputWithLabel label="Email" name="email">
                <Input id="email" value={user.email} onChange={(event) => handleChange("email", event.target.value)} />
              </RequiredInputWithLabel>
            </FormColumn2Wide>
            <FormColumn2Wide>
              <RequiredInputWithLabel label="Department" name="department">
                {/*@ts-ignore*/}
                <BestSelect
                  options={departmentOptions}
                  value={getDepartmentOption(user?.departmentId, departmentOptions)}
                  onChange={(value) => handleChange("departmentId", value?.value?.departmentId)}
                />
              </RequiredInputWithLabel>
            </FormColumn2Wide>
            <FormColumn2Wide>
              <RequiredInputWithLabel label="Job Title" name="jobTitle">
                <Input
                  id="jobTitle"
                  value={user.jobTitle}
                  onChange={(event) => handleChange("jobTitle", event.target.value)}
                />
              </RequiredInputWithLabel>
            </FormColumn2Wide>
            <FormColumn2Wide>
              <RequiredInputWithLabel label="Location" name="location">
                <Input
                  id="location"
                  value={user.location}
                  onChange={(event) => handleChange("location", event.target.value)}
                />
              </RequiredInputWithLabel>
            </FormColumn2Wide>
            <FormColumn2Wide>
              <RequiredInputWithLabel label="Direct Supervisor" name="directSupervisor">
                <Input
                  id="directSupervisor"
                  value={user.directSupervisor}
                  onChange={(event) => handleChange("directSupervisor", event.target.value)}
                />
              </RequiredInputWithLabel>
            </FormColumn2Wide>
          </div>
        </ModalBody>
        <ModalFooter>
          <div>
            <PrimaryButton className="me-2" disabled={!canSave} onClick={createUserCall}>
              Save
            </PrimaryButton>
            <LightButton onClick={closeForm}>Cancel</LightButton>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AdminCreateUser;

import { AttributeHeader, AttributeValue, Button, Card, useLoading } from "best-common-react-2";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { getDayOfRequest } from "../../../api/RequesTixApi";
import AdminTicketsRevokeModal from "../../../components/todaysTickets/admin/AdminTicketsRevokeModal";
import TodaysTicketsGame from "../../../components/todaysTickets/TodaysTicketsGame";
import { DayOfGameRequestDTO } from "../../../types/TodaysTickets";
import { formatSubmittedDate } from "../../../util/TimeUtil";

const GameContainer = styled.div`
  max-width: 500px;
`;

const ValueContainer = styled.div`
  min-width: 200px;
  margin-right: 2rem;
`;

type AdminTodaysTicketsEditRouteParams = {
  requestId: string;
};

type AdminTodaysTicketsEditProps = RouteComponentProps<AdminTodaysTicketsEditRouteParams>;

const AdminTodaysTicketsEdit: React.FC<AdminTodaysTicketsEditProps> = ({ match }) => {
  const { setLoading } = useLoading();
  const { requestId } = match.params;
  const [revokeOpen, setRevokeOpen] = useState<boolean>(false);
  const [request, setRequest] = useState<DayOfGameRequestDTO | undefined>(undefined);

  const getDayOfRequestCall = async (requestId: string) => {
    try {
      setLoading(true);
      const result: DayOfGameRequestDTO = await getDayOfRequest(requestId);
      setRequest(result);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!!requestId) {
      getDayOfRequestCall(requestId);
    }
  }, [requestId]);

  return (
    <Card>
      <Card.Header>Today's Tickets Request</Card.Header>
      <Card.Body>
        {!!request && (
          <div className="d-flex flex-column">
            <AdminTicketsRevokeModal
              isOpen={revokeOpen}
              onClose={() => setRevokeOpen(false)}
              requestId={request.dayOfRequestId}
            />
            <div className="d-flex align-items-center">
              <GameContainer>
                <TodaysTicketsGame
                  game={{
                    gamePk: request.game.gamePk,
                    home: request.game.teams.home.team,
                    away: request.game.teams.away.team,
                    gameDate: request.game.gameDate,
                    venue: request.game.venue,
                    ticketsLeft: undefined,
                    isLotto: false,
                  }}
                />
              </GameContainer>
              {request.statusId === 1 && (
                <div>
                  <Button variant="default" className="ms-2" onClick={() => setRevokeOpen(true)}>
                    Revoke Tickets
                  </Button>
                </div>
              )}
            </div>
            <div className="mt-1 d-flex flex-wrap">
              <ValueContainer>
                <AttributeHeader>Requester</AttributeHeader>
                <AttributeValue>
                  {request.user.firstName} {request.user.lastName}
                </AttributeValue>
              </ValueContainer>
              <ValueContainer>
                <AttributeHeader>Requester</AttributeHeader>
                <AttributeValue>
                  {request.user.firstName} {request.user.lastName}
                </AttributeValue>
              </ValueContainer>
              <ValueContainer>
                <AttributeHeader>Email</AttributeHeader>
                <AttributeValue>{request.emailAddress}</AttributeValue>
              </ValueContainer>
              <ValueContainer>
                <AttributeHeader>Number of Tickets</AttributeHeader>
                <AttributeValue>{request.numberOfTickets}</AttributeValue>
              </ValueContainer>
              <ValueContainer>
                <AttributeHeader>Tickets Sent</AttributeHeader>
                <AttributeValue>{request.ticketSentDisplay}</AttributeValue>
              </ValueContainer>
              <ValueContainer>
                <AttributeHeader>Requested Date</AttributeHeader>
                <AttributeValue>{formatSubmittedDate(request.requestedDate)}</AttributeValue>
              </ValueContainer>
              <ValueContainer>
                <AttributeHeader>Status</AttributeHeader>
                <AttributeValue>{request.status}</AttributeValue>
              </ValueContainer>
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default AdminTodaysTicketsEdit;

import {
  Accordion,
  AttributeHeader,
  AttributeValue,
  CustomMobileAccordionType,
  Icon,
  Link,
  Table,
} from "best-common-react-2";
import React from "react";
import RouteConstants, { urlReplace } from "../../constants/RouteConstants";
import { UserData } from "./UserTable";

const UserAccordion = ({ data, ...rest }: CustomMobileAccordionType<UserData>) => {
  return (
    <Accordion {...rest}>
      <Accordion.Header>
        <div className="d-flex">
          <Link to={urlReplace(RouteConstants.ADMIN.USERS_EDIT, { userId: data.id })}>
            <Icon iconName="fa-pencil-alt" className="fa-lg me-3" />
          </Link>
          {data.name}
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <div>
          <AttributeHeader>Email</AttributeHeader>
          <AttributeValue>{data.email}</AttributeValue>

          <AttributeHeader>Role</AttributeHeader>
          <AttributeValue>{data.role}</AttributeValue>

          <AttributeHeader>Department</AttributeHeader>
          <AttributeValue>{data.department}</AttributeValue>

          <AttributeHeader>Title</AttributeHeader>
          <AttributeValue>{data.title}</AttributeValue>
        </div>
        <Table className="mt-3">
          <Table.Head>
            <Table.Row>
              <Table.Header />
              <Table.Header>Personal</Table.Header>
              <Table.Header>Business</Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            <Table.Row>
              <Table.TdInfo>Pending</Table.TdInfo>
              <Table.Td>{data.personal_pending}</Table.Td>
              <Table.Td>{data.business_pending}</Table.Td>
            </Table.Row>
            <Table.Row>
              <Table.TdInfo>Approved</Table.TdInfo>
              <Table.Td>{data.personal_approved}</Table.Td>
              <Table.Td>{data.business_approved}</Table.Td>
            </Table.Row>
            <Table.Row>
              <Table.TdInfo>Allotted</Table.TdInfo>
              <Table.Td>{data.personal_allotted}</Table.Td>
              <Table.Td>{data.business_allotted}</Table.Td>
            </Table.Row>
          </Table.Body>
        </Table>
      </Accordion.Body>
    </Accordion>
  );
};

export default UserAccordion;

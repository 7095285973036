import { BestLabel, BestSelect, CardBody, CardContainer, Icon, TextArea } from "best-common-react";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import { useDropdowns } from "../../contexts/DropdownsContext";

const Icons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  &&& {
    font-size: 3rem;
  }
`;

const required = <span style={{ color: "red" }}>*</span>;

const DeliveryMethod = ({ deliveryMethod, index, onChange, onDelete }) => {
  const { venueDeliveryMethodsOptions } = useDropdowns();

  const getDropdownOpt = (venueDeliveryMethodsOptions, value) =>
    venueDeliveryMethodsOptions.find((opt) => !!value && opt.value.id === value.id);

  return (
    <Draggable draggableId={`draggable-${index}`} index={index}>
      {(provided) => (
        <div className="mt-2" {...provided.draggableProps} ref={provided.innerRef}>
          <CardContainer>
            <CardBody>
              <Icons>
                <Icon iconName="fa-grip-horizontal" style={{ fontSize: "1.5rem" }} {...provided.dragHandleProps} />
                <Icon iconName="fa-trash-alt" style={{ fontSize: "1.5rem" }} onClick={() => onDelete(deliveryMethod)} />
              </Icons>
              <div className="m-1">
                <BestLabel htmlFor={`deliveryMethod-${index}`}>Delivery Method {required}</BestLabel>
                <BestSelect
                  options={venueDeliveryMethodsOptions}
                  value={getDropdownOpt(venueDeliveryMethodsOptions, deliveryMethod.deliveryMethod)}
                  onChange={(value) => onChange(index, "deliveryMethod", value.value)}
                />
              </div>
              <div className="m-1">
                <BestLabel htmlFor={`description-${index}`}>Description {required}</BestLabel>
                <TextArea
                  id={`description-${index}`}
                  value={deliveryMethod.description}
                  onChange={({ target: { value } }) => onChange(index, "description", value)}
                />
              </div>
            </CardBody>
          </CardContainer>
        </div>
      )}
    </Draggable>
  );
};

const DeliveryMethods = ({ deliveryMethods, onChange, onDelete }) => (
  <>
    {deliveryMethods.map((deliveryMethod, index) => (
      <DeliveryMethod
        key={`row-${index}`}
        deliveryMethod={deliveryMethod}
        index={index}
        onChange={onChange}
        onDelete={onDelete}
      />
    ))}
  </>
);

const DeliveryMethodsDnD = ({ deliveryMethods, onReorder, onChange, onDelete }) => (
  <DragDropContext
    onDragEnd={(result) => {
      if (result && result.destination && result.source) onReorder(result.source.index, result.destination.index);
    }}
  >
    <div>
      <Droppable droppableId="mobile-delivery-methods-dnd">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <DeliveryMethods deliveryMethods={deliveryMethods} onChange={onChange} onDelete={onDelete} />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  </DragDropContext>
);

export default DeliveryMethodsDnD;

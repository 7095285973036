import { DesktopTabletView, MobileView, Table, TableHeader, TableTdInfo } from "best-common-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getProjectRequestsTotals } from "../../../api/RequesTixApi";
import { toMoney } from "../../../util/StringUtil";

const TableHolder = styled.div`
  width: 100%;
  max-width: 25rem;
`;

const Tr = styled.tr`
  height: 1.875rem;
  padding: 0;
`;

const THead = styled(TableHeader)`
  &&& {
    border-bottom: 1px solid ${(props) => props.theme["grey"]};
  }
`;

const TdLabel = styled(TableTdInfo)`
  &&& {
    padding: 0 0 0 0.5rem;
    border: none;
    border-right: 1px solid ${(props) => props.theme["grey"]};
    height: 1.875rem;
  }
`;

const TdSubLabel = styled(TableTdInfo)`
  &&& {
    padding: 0 0 0 1rem;
    border: none;
    border-right: 1px solid ${(props) => props.theme["grey"]};
    height: 1.875rem;
    font-size: 0.875rem;
    font-weight: 300 !important;
  }
`;

const Td = styled.td`
  &&& {
    border: none;
    border-right: 1px solid #eceeef;
    height: 1.875rem;
    color: #000000;
    font-weight: normal;
    padding: 0.3125rem 0.5rem;
  }
`;
const ProjectRequestsSummaryTable = ({ projectId, startDate, endDate }) => {
  const [data, setData] = useState({
    approved: {},
    comped: {},
    card: {},
    dept: {},
    denied: {},
    total: {},
  });

  useEffect(() => {
    if (projectId && startDate && endDate) {
      getProjectRequestsTotals(projectId, startDate, endDate).then((data) => {
        setData(data);
      });
    }
  }, [projectId, startDate, endDate]);

  return (
    <TableHolder>
      <Table>
        <THead>
          <Tr>
            <th style={{ width: "30%" }} />
            <th>Requests</th>
            <th>Ticket Qty</th>
            <DesktopTabletView>
              <th className="text-right pr-2">Cost</th>
            </DesktopTabletView>
          </Tr>
        </THead>
        <tbody>
          <Tr>
            <TdLabel>Approved</TdLabel>
            <Td>{data.approved.requests}</Td>
            <Td>{data.approved.ticketQty}</Td>
            <DesktopTabletView>
              <Td className="text-right">{toMoney(data.approved.cost)}</Td>
            </DesktopTabletView>
          </Tr>
          <Tr>
            <TdSubLabel>Comp</TdSubLabel>
            <Td>{data.comped.requests}</Td>
            <Td>{data.comped.ticketQty}</Td>
            <DesktopTabletView>
              <Td />
            </DesktopTabletView>
          </Tr>
          <Tr>
            <TdSubLabel>Card</TdSubLabel>
            <Td>{data.card.requests}</Td>
            <Td>{data.card.ticketQty}</Td>
            <DesktopTabletView>
              <Td className="text-right">{toMoney(data.card.cost)}</Td>
            </DesktopTabletView>
          </Tr>
          <Tr>
            <TdSubLabel>Dept</TdSubLabel>
            <Td>{data.dept.requests}</Td>
            <Td>{data.dept.ticketQty}</Td>
            <DesktopTabletView>
              <Td className="text-right">{toMoney(data.dept.cost)}</Td>
            </DesktopTabletView>
          </Tr>
          <Tr>
            <TdLabel>Denied</TdLabel>
            <Td>{data.denied.requests}</Td>
            <Td>{data.denied.ticketQty}</Td>
            <DesktopTabletView>
              <Td />
            </DesktopTabletView>
          </Tr>
          <Tr>
            <TdLabel>Total</TdLabel>
            <Td>{data.total.requests}</Td>
            <Td>{data.total.ticketQty}</Td>
            <DesktopTabletView>
              <Td className="text-right">{toMoney(data.total.cost)}</Td>
            </DesktopTabletView>
          </Tr>
        </tbody>
      </Table>
      <MobileView>
        <Table className="mt-1">
          <THead>
            <Tr>
              <th style={{ width: "30%" }} />
              <th className="text-right pr-2">Cost</th>
            </Tr>
          </THead>
          <tbody>
            <Tr>
              <TdLabel>Card Total</TdLabel>
              <Td className="text-right">{toMoney(data.card.cost)}</Td>
            </Tr>
            <Tr>
              <TdLabel>Dept Total</TdLabel>
              <Td className="text-right">{toMoney(data.dept.cost)}</Td>
            </Tr>
            <Tr>
              <TdLabel>Total</TdLabel>
              <Td className="text-right">{toMoney(data.total.cost)}</Td>
            </Tr>
          </tbody>
        </Table>
      </MobileView>
    </TableHolder>
  );
};

export default ProjectRequestsSummaryTable;

import { EventTimingDTO } from "../types/EventTiming";

export const convertToEventTypeDTO = (override) => {
  return !!override
    ? {
        personal: {
          useDate: override.personalCutoffUseDate,
          date: override.personalCutoffDate,
          hoursBefore: override.personalCutoffHoursBefore,
        },
        business: {
          useDate: override.businessCutoffUseDate,
          date: override.businessCutoffDate,
          hoursBefore: override.businessCutoffHoursBefore,
        },
      }
    : null;
};

export const convertFromEventTypeDTO = (dto: EventTimingDTO) => {
  return {
    personalCutoffUseDate: dto?.personal?.useDate,
    personalCutoffDate: dto?.personal?.date,
    personalCutoffHoursBefore: dto?.personal?.hoursBefore,
    businessCutoffUseDate: dto?.business?.useDate,
    businessCutoffDate: dto?.business?.date,
    businessCutoffHoursBefore: dto?.business?.hoursBefore,
  };
};

import * as H from "history";
import qs from "query-string";

export const useRouteParams = (search: string): any => {
  let params = {};

  if (search) {
    params = qs.parse(search);
  }

  return params;
};

export const createRouteSearch = (params: object): string => {
  let search = "?";

  let i = 0;
  for (const key in params) {
    const val = params[key];
    if (val) {
      search += `${i !== 0 ? "&" : ""}${key}=${encodeURI(val)}`;
      i++;
    }
  }

  return search;
};

export const updateRouteParams = (history: H.History, pathname: string, params: object, state?: any) => {
  const search = createRouteSearch(params);

  history.push({
    pathname: pathname,
    search: search,
    state: state,
  });
};

export const urlReplace = (str: string, data: object): string => {
  if (str) {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      str = str.replace(`:${key}`, data[key]);
    });
    return str;
  } else {
    return "";
  }
};

import React from "react";
import ActionConstants from "../../../../constants/ActionConstants";
import { Action, ActionStateDTO } from "../../../../types/Action";
import { GameDTO } from "../../../../types/Game";
import { FlattenedTicketRequest } from "../../../../types/TicketRequest";
import ConfirmationEmailSentAction from "./ConfirmationEmailSentAction";
import FulfillmentTypeSetAction from "./FulfillmentTypeSetAction";
import OrderChargedAction from "./OrderChargedAction";
import OrderDetailsCompleteAction from "./OrderDetailsCompleteAction";
import RequestSentToClubAction from "./RequestSentToClubAction";
import TicketSentToRecipientAction from "./TicketSentToRecipientAction";

export type ActionProps = {
  game: GameDTO;
  state: ActionStateDTO;
  onStateChange: (value: ActionStateDTO) => void;
  request: FlattenedTicketRequest;
  save: (showSuccessAlert?: boolean, refresh?: boolean) => Promise<any>;
  refresh: () => Promise<any>;
  statusId: number;
};

export type ActionHandlerProps = ActionProps & {
  action?: Action;
};

const ActionHandler: React.FC<ActionHandlerProps> = ({
  game,
  action,
  state,
  onStateChange,
  request,
  save,
  statusId,
  refresh,
}) => {
  const actionId = action ? action.actionId : null;
  switch (actionId) {
    case ActionConstants.REQUEST_SENT_TO_CLUB_ID:
      return (
        <RequestSentToClubAction
          game={game}
          state={state}
          onStateChange={onStateChange}
          request={request}
          save={save}
          statusId={statusId}
          refresh={refresh}
        />
      );
    case ActionConstants.FULFILLMENT_TYPE_SET_ID:
      return (
        <FulfillmentTypeSetAction
          game={game}
          state={state}
          onStateChange={onStateChange}
          request={request}
          save={save}
          statusId={statusId}
          refresh={refresh}
        />
      );
    case ActionConstants.ORDER_DETAILS_COMPLETE_ID:
      return (
        <OrderDetailsCompleteAction
          game={game}
          state={state}
          onStateChange={onStateChange}
          request={request}
          save={save}
          statusId={statusId}
          refresh={refresh}
        />
      );
    case ActionConstants.ORDER_CHARGED_ID:
      return (
        <OrderChargedAction
          game={game}
          state={state}
          onStateChange={onStateChange}
          request={request}
          save={save}
          statusId={statusId}
          refresh={refresh}
        />
      );
    case ActionConstants.TICKET_SENT_TO_RECIPIENT_ID:
      return (
        <TicketSentToRecipientAction
          game={game}
          state={state}
          onStateChange={onStateChange}
          request={request}
          save={save}
          statusId={statusId}
          refresh={refresh}
        />
      );
    case ActionConstants.CONFIRMATION_EMAIL_SENT_ID:
      return (
        <ConfirmationEmailSentAction
          game={game}
          state={state}
          onStateChange={onStateChange}
          request={request}
          save={save}
          statusId={statusId}
          refresh={refresh}
        />
      );
    default:
      return null;
  }
};

export default ActionHandler;

import { EventTimingTypeDTO } from "../types/EventTiming";

export const defaultAccess: EventTimingTypeDTO = {
  active: false,
  date: null,
  hoursBefore: 0,
  useDate: true,
};

export const defaultCutoff: { [key: string]: number | boolean | null } = {
  active: false,
  personalCutoffDate: null,
  personalCutoffHoursBefore: 0,
  personalCutoffUseDate: true,
  businessCutoffDate: null,
  businessCutoffHoursBefore: 0,
  businessCutoffUseDate: true,
};

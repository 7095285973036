import { AutoComplete, Label, Loader, Select, sort, useDebounce, ValueOpt } from "best-common-react-2";
import React, { useEffect, useState } from "react";
import { components } from "react-select";
import { getUser, graphQl } from "../../../api/RequesTixApi";
import { User, UserDTO } from "../../../types/User";

type UserSearchProps = {
  id?: string;
  initialValue?: any;
  label: string;
  onChange: (value: User | ValueOpt<User>[]) => void;
  existingUserIds?: number[];
  value?: User | UserDTO | ValueOpt<User>[] | ValueOpt<UserDTO>[];
  isMulti?: boolean;
  loadAll?: boolean;
  placeholderText?: string;
  disabled?: boolean;
  clearable?: boolean;
  required?: boolean;
  isInvalid?: boolean;
  withRequests?: boolean;
  gutterBottom?: boolean;
  setUserInfo?: () => void;
  onClear?: () => void;
  restrictUserIds?: number[];
};

export const getName = (userInfo) =>
  userInfo?.lastName && userInfo?.firstName ? `${userInfo.lastName}, ${userInfo.firstName}` : "";

const createSingleQuery = (queryStr) => `{
  searchUsers(queryString: "${queryStr}") {
    firstName,
    lastName,
    email,
    title,
    employeeUserId,
    department {
			departmentName  
    }
  }
}
`;

const createAllUsersQuery = () => `{
  getAllUsers{
  	employeeUserId,
    firstName,
    lastName,
    email,
    title,
    department {
			departmentName  
    }
  }
}
`;

const createWithRequestsQuery = () => `{
  getAllUsersWithRequests {
  	employeeUserId,
    firstName,
    lastName,
    email,
    title,
    department {
			departmentName  
    }
  }
}
`;

type UserSearchSingleLoadAllProps = {
  label?: string;
  value: any;
  initialValue: any;
  onChange: (value: User) => void;
  placeholderText?: string;
  disabled?: boolean;
  setUserInfo: (value: User) => void;
  withRequests?: boolean;
  restrictUserIds?: number[];
  initialUserId?: number;
};

const UserLoader = ({ label }: { label: string }) => (
  <div>
    <Label htmlFor="loader">{label}</Label>
    <Loader size="10px" type="pulse" />
  </div>
);

const UserSearchSingleLoadAll: React.FC<UserSearchSingleLoadAllProps> = ({
  label,
  value,
  onChange,
  placeholderText = "First, Last",
  disabled,
  setUserInfo,
  withRequests,
  restrictUserIds = [],
  initialUserId,
}) => {
  const [options, setOptions] = useState<ValueOpt<User>[]>([]);
  const [searchValue, setSearchValue] = useState<ValueOpt<User> | undefined>();

  const All = {
    label: "All",
    value: null,
  };

  useEffect(() => {
    const queryObj = {
      query: withRequests ? createWithRequestsQuery() : createAllUsersQuery(),
      variables: null,
      operationName: null,
    };
    graphQl(queryObj).then((data) => {
      //@ts-ignore
      const users: User[] = withRequests ? data.getAllUsersWithRequests : data.getAllUsers;
      const opts = [All].concat(
        sort(
          users
            .filter((user: User) => !restrictUserIds.includes(user.employeeUserId))
            .map((user: User) => ({
              label: `${user.lastName}, ${user.firstName}`,
              value: user,
            })),
          "label"
        )
      );
      setOptions(opts);
    });
  }, []);

  useEffect(() => {
    if (options?.length) {
      if (initialUserId) {
        const val: ValueOpt<User> = options.find((opt: ValueOpt<User>) => opt?.value?.employeeUserId === initialUserId);
        const result: ValueOpt<User> = val || All;
        setSearchValue(result);
        setUserInfo(result.value);
      } else {
        const val: ValueOpt<User> = !!value
          ? options.find((opt: ValueOpt<User>) => opt?.value?.employeeUserId === value)
          : undefined;
        const result: ValueOpt<User> = val || All;
        setSearchValue(result);
        setUserInfo(result.value);
      }
    } else {
      setSearchValue(undefined);
    }
  }, [value, options, initialUserId]);

  return (
    <>
      {options.length ? (
        <Select
          label={label}
          id="user-search"
          options={options}
          value={searchValue}
          onChange={(value: ValueOpt<User>) => {
            onChange(value.value);
          }}
          placeholder={placeholderText}
          disabled={disabled}
        />
      ) : (
        <UserLoader label={label} />
      )}
    </>
  );
};

type UserSearchSingleQueryProps = {
  value: User;
  onChange: (value: User) => void;
  initialValue?: string;
  placeholderText?: string;
  label?: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  gutterBottom?: boolean;
  restrictUserIds?: number[];
};

const UserSearchSingleQuery: React.FC<UserSearchSingleQueryProps> = ({
  value,
  label,
  onChange,
  initialValue,
  placeholderText = "First, Last",
  disabled,
  gutterBottom,
  required,
  restrictUserIds = [],
}) => {
  const [suggestions, setSuggestions] = useState<User[]>([]);

  const getUsers = (query): Promise<User[]> => {
    const queryObj = {
      query: createSingleQuery(query),
      variables: null,
      operationName: null,
    };
    //@ts-ignore
    return graphQl(queryObj).then((data) =>
      data.searchUsers.filter((user: User) => !restrictUserIds.includes(user.employeeUserId))
    );
  };

  const renderSuggestion = (suggestion?: User): string => {
    return !!suggestion ? getName(suggestion) : "";
  };

  const getSuggestions = useDebounce((value: string) => {
    if (value) {
      getUsers(value).then((data: User[]) => {
        setSuggestions(data);
      });
    } else {
      setSuggestions([]);
    }
  }, 300);

  const onSuggestionSelected = (value: User) => {
    onChange(value);
  };

  return (
    <>
      {disabled ? (
        <div>{initialValue}</div>
      ) : (
        <>
          <AutoComplete
            id="auto-complete-user"
            label={label}
            required={required}
            gutterBottom={gutterBottom}
            options={suggestions}
            renderSuggestion={renderSuggestion}
            value={value}
            clearable={false}
            onSuggestionsFetchRequested={getSuggestions}
            placeholder={placeholderText}
            onChange={onSuggestionSelected}
            disabled={disabled}
          />
        </>
      )}
    </>
  );
};

type UserSearchSingleProps = {
  value: any;
  onChange: (value: User) => void;
  initialValue: string;
  placeholderText?: string;
  disabled?: boolean;
  loadAll: boolean;
  setUserInfo: (value: User) => void;
  withRequests?: boolean;
  required?: boolean;
  gutterBottom?: boolean;
  label?: string;
  restrictUserIds?: number[];
  initialUserId?: number;
};

const UserSearchSingle: React.FC<UserSearchSingleProps> = ({
  value,
  onChange,
  initialValue,
  placeholderText = "First, Last",
  disabled,
  loadAll,
  setUserInfo,
  withRequests,
  label,
  gutterBottom,
  required,
  restrictUserIds,
  initialUserId,
}) => {
  return loadAll ? (
    <UserSearchSingleLoadAll
      label={label}
      value={value}
      onChange={onChange}
      initialValue={initialValue}
      placeholderText={placeholderText}
      disabled={disabled}
      setUserInfo={setUserInfo}
      withRequests={withRequests}
      restrictUserIds={restrictUserIds}
      initialUserId={initialUserId}
    />
  ) : (
    <UserSearchSingleQuery
      label={label}
      required={required}
      gutterBottom={gutterBottom}
      value={value}
      onChange={onChange}
      initialValue={initialValue}
      placeholderText={placeholderText}
      disabled={disabled}
      restrictUserIds={restrictUserIds}
    />
  );
};

const SectionOption = ({ data, ...rest }) => (
  <components.Option {...rest}>
    {data && data.value && (
      <div>
        {data.value.firstName} {data.value.lastName} - {data.value.department.departmentName}
      </div>
    )}
  </components.Option>
);

const MultiValue = ({ data, ...rest }) => (
  <components.MultiValue {...rest}>
    {data && data.value && (
      <div>
        {data.value.firstName} {data.value.lastName}
      </div>
    )}
  </components.MultiValue>
);

type UserSearchMultiProps = {
  value: any;
  onChange: (value: any) => void;
  existingUserIds: number[];
  placeholderText?: string;
  disabled?: boolean;
  required?: boolean;
  gutterBottom?: boolean;
  label?: string;
  restrictUserIds?: number[];
};

const UserSearchMulti: React.FC<UserSearchMultiProps> = ({
  value,
  onChange,
  existingUserIds,
  placeholderText = "begin typing to search for users",
  disabled,
  restrictUserIds = [],
}) => {
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState(value);

  useEffect(() => {
    const queryObj = {
      query: createAllUsersQuery(),
      variables: null,
      operationName: null,
    };
    graphQl(queryObj).then((data) => {
      //@ts-ignore
      const users: User[] = data.getAllUsers;
      const opts = sort(
        users
          .filter((user: User) => !restrictUserIds.includes(user.employeeUserId))
          .map((user: User) => ({
            label: `${user.firstName} ${user.lastName} - ${user.department.departmentName}`,
            value: user,
          })),
        "label"
      );
      setOptions(opts);
    });
  }, []);

  useEffect(() => {
    if (existingUserIds && existingUserIds.length && options && options.length) {
      setSearchValue(options.filter((opt) => existingUserIds.indexOf(opt.value.employeeUserId) > -1));
    }
  }, [existingUserIds, options]);

  return (
    <>
      {options.length ? (
        <>
          <Select
            id="user-search-multi"
            isMulti
            options={options}
            value={searchValue}
            onChange={(value) => {
              setSearchValue(value);
              onChange(value);
            }}
            placeholder={placeholderText}
            components={{ Option: SectionOption, MultiValue: MultiValue }}
            disabled={disabled}
          />
        </>
      ) : (
        <UserLoader label="" />
      )}
    </>
  );
};

const UserSearch: React.FC<UserSearchProps> = ({
  value,
  onChange,
  initialValue,
  isMulti = false,
  existingUserIds,
  placeholderText = "Search users",
  label,
  required = false,
  disabled = false,
  loadAll = false,
  setUserInfo = () => {},
  withRequests = false,
  gutterBottom = false,
  restrictUserIds = [],
}) => {
  const [existingUsername, setExistingUsername] = useState<string>("");

  useEffect(() => {
    if (!isMulti && existingUserIds && existingUserIds.length > 0 && existingUserIds[0]) {
      getUser(existingUserIds[0]).then((data: UserDTO) => {
        setExistingUsername(data.firstName + " " + data.lastName);
        onChange({
          employeeUserId: data.employeeUserId,
          firstName: data.firstName,
          lastName: data.lastName,
          title: data.title,
          email: data.email,
          active: true,
          department: {
            departmentName: data.department,
          },
        } as User);
      });
    }
  }, []);

  return (
    <>
      {isMulti ? (
        <UserSearchMulti
          value={value}
          onChange={onChange}
          existingUserIds={existingUserIds}
          placeholderText={placeholderText}
          disabled={disabled}
          gutterBottom={gutterBottom}
          required={required}
          label={label}
          restrictUserIds={restrictUserIds}
        />
      ) : (
        <UserSearchSingle
          value={value}
          onChange={onChange}
          initialValue={initialValue ? initialValue : existingUsername}
          initialUserId={existingUserIds?.length ? existingUserIds[0] : undefined}
          placeholderText={placeholderText}
          disabled={disabled}
          loadAll={loadAll}
          setUserInfo={setUserInfo}
          withRequests={withRequests}
          gutterBottom={gutterBottom}
          required={required}
          label={label}
          restrictUserIds={restrictUserIds}
        />
      )}
    </>
  );
};

export default UserSearch;

import { Accordion, AttributeHeader, AttributeValue, CustomMobileAccordionType, Icon, Link } from "best-common-react-2";
import React from "react";
import RouteConstants, { urlReplace } from "../../constants/RouteConstants";
import { DepartmentBySeasonDTO } from "../../types/Department";
import { AccessCutoffFormatter, AllotmentFormatter, OnOffFormatter } from "./DepartmentTable";

const DepartmentAccordion = ({
  data: { departmentName, departmentId, personalAllotment, businessAllotment, cutoff, access, personalRequests },
  ...rest
}: CustomMobileAccordionType<DepartmentBySeasonDTO>) => {
  return (
    <Accordion {...rest}>
      <Accordion.Header>
        <div className="d-flex">
          <Link to={urlReplace(RouteConstants.ADMIN.DEPARTMENTS_EDIT, { departmentId: departmentId })}>
            <Icon iconName="fa-pencil-alt" className="fa-lg me-3" />
          </Link>
          {departmentName}
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <AttributeHeader>Personal</AttributeHeader>
        <AttributeValue>
          <AllotmentFormatter value={personalAllotment} />
        </AttributeValue>

        <AttributeHeader>Business</AttributeHeader>
        <AttributeValue>
          <AllotmentFormatter value={businessAllotment} />
        </AttributeValue>

        <AttributeHeader>Event Access (ET)</AttributeHeader>
        <AttributeValue>
          <AccessCutoffFormatter value={access} />
        </AttributeValue>

        <AttributeHeader>Event Cutoff (ET)</AttributeHeader>
        <AttributeValue>
          <AccessCutoffFormatter value={cutoff} />
        </AttributeValue>

        <AttributeHeader>Personal Requests</AttributeHeader>
        <AttributeValue>
          <OnOffFormatter value={personalRequests} />
        </AttributeValue>
      </Accordion.Body>
    </Accordion>
  );
};

export default DepartmentAccordion;

import { BestTable } from "best-common-react";
import { useLoading } from "best-common-react-2";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getAllProjectRequestsTotals, runReport } from "../../../api/RequesTixApi";
import ReportConstants from "../../../constants/ReportConstants";
import { downloadLink, FileTypes } from "../../../util/FileUtil";
import { createRouteSearch } from "../../../util/RouteUtil";
import { sortWithFunction } from "../../../util/SortUtil";
import { includes, toLower, toMoney } from "../../../util/StringUtil";
import { format } from "../../../util/TimeUtil";
import ExportFormatter from "../formatters/ExportFormatter";
import ToLinkFormatter from "../formatters/ToLinkFormatter";
import ProjectRequestsAllProjectsAccordion from "./ProjectRequestsAllProjectsAccordion";

const EventsSize = styled.span`
  font-size: 0.8rem;
  color: ${(props) => props.theme["dark-grey"]};
  font-style: italic;
`;

const Columns = [
  {
    name: "",
    key: "projectId",
    width: 40,
    formatter: ExportFormatter,
  },
  {
    name: "Project",
    key: "projectName",
    minWidth: 175,
    formatter: ToLinkFormatter,
    sortable: true,
  },
  {
    name: "User",
    key: "userCount",
    cellClass: "text-center",
    minWidth: 100,
    sortable: true,
  },
  {
    name: "Approved",
    key: "approved",
    cellClass: "text-center",
    formatter: ToLinkFormatter,
    minWidth: 100,
    sortable: true,
  },
  {
    name: "Comp",
    key: "comped",
    cellClass: "text-center",
    formatter: ToLinkFormatter,
    minWidth: 100,
    sortable: true,
  },
  {
    name: (
      <div className="d-flex flex-column">
        <div>Business</div> <div>Card</div>
      </div>
    ),
    key: "card",
    cellClass: "text-center",
    formatter: ToLinkFormatter,
    minWidth: 100,
    sortable: true,
  },
  {
    name: "Dept",
    key: "dept",
    cellClass: "text-center",
    formatter: ToLinkFormatter,
    minWidth: 100,
    sortable: true,
  },
  {
    name: "Denied",
    key: "denied",
    cellClass: "text-center",
    formatter: ToLinkFormatter,
    minWidth: 100,
    sortable: true,
  },
  {
    name: "Total",
    key: "total",
    cellClass: "text-center",
    formatter: ToLinkFormatter,
    minWidth: 100,
    sortable: true,
  },
  {
    name: "Card Total",
    key: "cardTotalCost",
    cellClass: "text-center",
    minWidth: 100,
    sortable: true,
  },
  {
    name: "Dept Total",
    key: "deptTotalCost",
    cellClass: "text-center",
    minWidth: 100,
    sortable: true,
  },
  {
    name: "Total Cost",
    key: "totalCost",
    cellClass: "text-center",
    minWidth: 100,
    sortable: true,
  },
];

const massageData = (data, location, startDate, endDate, generateReport) => {
  const to = location.pathname;
  return data.map((d) => ({
    ...d,
    projectId: {
      value: d.requesterId,
      onClick: () => generateReport(d.projectId),
    },
    projectName: {
      value: d.projectName,
      to: to,
      search: createRouteSearch({
        projectId: d.projectId,
        startDate,
        endDate,
      }),
    },
    approved: {
      value: d.approved,
      to: to,
      search: createRouteSearch({
        statusId: 3,
        projectId: d.projectId,
        startDate,
        endDate,
      }),
    },
    comped: {
      value: d.comped,
      to: to,
      search: createRouteSearch({
        statusId: 3,
        fulfillmentTypeId: 1,
        projectId: d.projectId,
        startDate,
        endDate,
      }),
    },
    card: {
      value: d.card,
      to: to,
      search: createRouteSearch({
        statusId: 3,
        fulfillmentTypeId: 2,
        projectId: d.projectId,
        startDate,
        endDate,
      }),
    },
    dept: {
      value: d.dept,
      to: to,
      search: createRouteSearch({
        statusId: 3,
        fulfillmentTypeId: 3,
        projectId: d.projectId,
        startDate,
        endDate,
      }),
    },
    denied: {
      value: d.denied,
      to: to,
      search: createRouteSearch({
        statusId: 4,
        projectId: d.projectId,
        startDate,
        endDate,
      }),
    },
    total: {
      value: d.total,
      to: to,
      search: createRouteSearch({
        projectId: d.projectId,
        startDate,
        endDate,
      }),
    },
    cardTotalCost: toMoney(d.cardTotalCost),
    deptTotalCost: toMoney(d.deptTotalCost),
    totalCost: toMoney(d.totalCost),
  }));
};

const freeTextFilter = (data, search) =>
  search && search.length ? data.filter((data) => includes(data.projectName.value, search)) : data;

const ProjectRequestsAllProjects = ({ reportData, dateRange, location, setCanExport }) => {
  const { setLoading } = useLoading();
  const [data, setData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [sortFilters, setSortFilters] = useState({ key: "recipient", direction: BestTable.ASC });

  const onSort = (key, direction) => setSortFilters({ key, direction });

  const generateReport = (projectId) => {
    setLoading(true);
    runReport(ReportConstants.PROJECT_REQUESTS_REPORT_ID, {
      startDate: format(dateRange.start),
      endDate: format(dateRange.end),
      projectId: projectId,
    }).then((response) => {
      downloadLink(response, FileTypes.EXCEL);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (dateRange.start && dateRange.end) {
      setLoading(true);
      const startDate = format(dateRange.start);
      const endDate = format(dateRange.end);
      getAllProjectRequestsTotals(startDate, endDate).then((data) => {
        setData(massageData(data, location, startDate, endDate, generateReport));
        setLoading(false);
        setCanExport(!!data.length);
      });
    }
  }, [dateRange.start, dateRange.end]);

  useEffect(() => {
    if (data) {
      const dir = sortFilters.direction === BestTable.ASC ? 1 : sortFilters.direction === BestTable.DESC ? -1 : 0;
      setDisplayData(
        sortWithFunction(freeTextFilter(data, reportData.search), sortFilters.key, dir, (value) =>
          value ? toLower(value.value) : ""
        )
      );
    } else {
      setDisplayData([]);
    }
  }, [data, sortFilters, reportData.search]);

  return (
    <div className="mt-2 mt-md-0">
      <EventsSize>{data.length} projects</EventsSize>
      <BestTable
        columns={Columns}
        data={displayData}
        accordion={ProjectRequestsAllProjectsAccordion}
        headerHeight={50}
        sortColumn={sortFilters.key}
        sortDirection={sortFilters.direction}
        sortFunction={onSort}
      />
    </div>
  );
};

export default ProjectRequestsAllProjects;

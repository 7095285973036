import {
  AttributeHeader,
  AttributeValue,
  Card,
  CardThemeType,
  DATE_FORMATTER_STR_WITH_TIME,
  formatDate,
  useTheme,
} from "best-common-react-2";
import React from "react";
import styled from "styled-components";
import { PurchaseOpportunityDTO } from "../../types/PurchaseOpportunity";
import TeamLogo from "../game/TeamLogo";

type OppContainerProps = {
  hasHover: boolean;
};

const OppContainer = styled.div<OppContainerProps>`
  @media (max-width: 767px) {
    width: 100%;
  }

  @media (min-width: 768px) {
    min-width: 275px;
    width: 275px;
  }

  &:hover {
    ${({ hasHover }) => (hasHover ? "cursor: pointer" : "")};
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Details = styled.div`
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
`;

type PurchaseOpportunityCardProps = {
  opp: PurchaseOpportunityDTO;
  onClick?: (id: number | string) => void;
  className?: string;
};

const PurchaseOpportunityCard: React.FC<PurchaseOpportunityCardProps> = ({ opp, onClick, className }) => {
  const { Theme } = useTheme();

  const styles: CardThemeType = { ...Theme.card, body: { ...Theme.card.body, background: "#ffffff" } };
  return (
    <OppContainer hasHover={!!onClick} className={className}>
      <Card key={opp.purchaseOpportunityId} styles={styles}>
        <Card.Body styles={styles}>
          <ContentContainer
            onClick={() => {
              if (!!onClick) {
                onClick(opp.purchaseOpportunityId);
              }
            }}
          >
            <div>
              <TeamLogo teamId={opp.teamId} size="2.5rem" />
            </div>
            <Details>
              <AttributeHeader>Year</AttributeHeader>
              <AttributeValue>{opp.year}</AttributeValue>
              <AttributeHeader>Type</AttributeHeader>
              <AttributeValue>{opp.seriesType.description}</AttributeValue>
              <AttributeHeader>Number of Winners</AttributeHeader>
              <AttributeValue>{opp.numOfWinners}</AttributeValue>
              <AttributeHeader>Number of Games</AttributeHeader>
              <AttributeValue>{opp.numOfGames}</AttributeValue>
              <AttributeHeader>Cutoff Date</AttributeHeader>
              <AttributeValue>
                {formatDate(new Date(opp.cutoffDate as string), DATE_FORMATTER_STR_WITH_TIME)}
              </AttributeValue>
              <AttributeHeader>Cost for a Pair of Tickets</AttributeHeader>
              <AttributeValue>{opp.costDescription}</AttributeValue>
            </Details>
          </ContentContainer>
        </Card.Body>
      </Card>
    </OppContainer>
  );
};

export default PurchaseOpportunityCard;

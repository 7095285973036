import { Button, DataTable, DataTableColumn, Modal, useLoading } from "best-common-react-2";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BoxOfficeTicketDTO } from "../../types/Tickets";

const CantRevokeSpan = styled.span`
  color: ${(props) => props.theme["mlb-red"]};
`;

const Columns: DataTableColumn<BoxOfficeTicketDTO>[] = [
  {
    name: "Section",
    key: "section",
    width: 150,
  },
  {
    name: "Row",
    key: "row",
    width: 150,
  },
  {
    name: "Seat",
    key: "seat",
    width: 150,
  },
  {
    name: "Price",
    key: "price",
    width: 150,
  },
  {
    name: "Type",
    key: "provider",
    width: 100,
  },
];

type RevokeTicketsModalProps = {
  isOpen: boolean;
  close: () => void;
  onRevoke: () => void;
  tickets: BoxOfficeTicketDTO[];
  save?: (value: boolean) => Promise<any>;
  refreshTickets: (value: boolean) => Promise<any>;
};

const RevokeTicketsModal: React.FC<RevokeTicketsModalProps> = ({
  isOpen,
  close,
  onRevoke,
  tickets,
  save,
  refreshTickets,
}) => {
  const { setLoading } = useLoading();
  const [displayTickets, setDisplayTickets] = useState<BoxOfficeTicketDTO[]>([]);
  const [canRevoke, setCanRevoke] = useState(true);

  const recallTickets = async () => {
    try {
      setLoading(true);
      if (!!save) {
        await save(false);
      }
      close();
      setLoading(false);
      setTimeout(() => {
        refreshTickets(true);
      }, 1000);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(true);
    }
  };

  const onClose = () => {
    close();
  };

  useEffect(() => {
    if (isOpen) {
      if (!tickets?.length) {
        void recallTickets();
      } else {
        const newTickets = tickets.map((t) => ({
          ...t,
          revokable: true, //assume we can based on new TM integration
        }));
        setDisplayTickets(newTickets);
        setCanRevoke(newTickets.filter((t) => !!t.revokable).length === tickets.length);
      }
    }
  }, [isOpen, tickets]);

  return (
    <Modal show={isOpen} size="lg">
      <Modal.Header close={onClose}>Revoke Tickets</Modal.Header>
      <Modal.Body>
        <div>Are you sure you want to revoke the following tickets?</div>
        <DataTable
          columns={Columns}
          data={displayTickets}
          tableHeights={{
            maxTableHeight: 250,
          }}
        />
        {!canRevoke && (
          <div className="mt-2">
            <CantRevokeSpan>One or more of the tickets are not revocable. Please contact support.</CantRevokeSpan>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="me-2" onClick={onRevoke} disabled={!canRevoke}>
          Revoke Tickets
        </Button>
        <Button variant="default" onClick={onClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RevokeTicketsModal;

import { FormColumn, Row, TableTitle } from "best-common-react-2";
import React, { useEffect, useState } from "react";
import AccessCutoff from "./AccessCutoff";

type PersonalBusinessCutoffProps = {
  override: any;
  setOverride: (value: any) => void;
  timezone?: string;
  active?: boolean;
};

const PersonalBusinessCutoff = ({
  override,
  setOverride,
  timezone = "America/New_York",
  active = true,
}: PersonalBusinessCutoffProps) => {
  const [personalOverride, setPersonalOverride] = useState({});
  const [businessOverride, setBusinessOverride] = useState({});

  const changeBusinessOverride = (newOverride) => {
    setOverride({
      ...override,
      businessCutoffDate: newOverride.date,
      businessCutoffHoursBefore: newOverride.hoursBefore,
      businessCutoffUseDate: newOverride.useDate,
    });
  };

  const changePersonalOverride = (newOverride) => {
    setOverride({
      ...override,
      personalCutoffDate: newOverride.date,
      personalCutoffHoursBefore: newOverride.hoursBefore,
      personalCutoffUseDate: newOverride.useDate,
    });
  };

  useEffect(() => {
    setPersonalOverride({
      active: active,
      date: override.personalCutoffDate,
      hoursBefore: override.personalCutoffHoursBefore,
      useDate: override.personalCutoffUseDate,
    });
    setBusinessOverride({
      active: active,
      date: override.businessCutoffDate,
      hoursBefore: override.businessCutoffHoursBefore,
      useDate: override.businessCutoffUseDate,
    });
  }, [override, active]);

  return (
    <Row>
      <FormColumn>
        <TableTitle>Personal</TableTitle>
        <AccessCutoff
          isCutoff={true}
          override={personalOverride}
          setOverride={changePersonalOverride}
          timezone={timezone}
        />
      </FormColumn>
      <FormColumn>
        <TableTitle>Business</TableTitle>
        <AccessCutoff
          isCutoff={true}
          override={businessOverride}
          setOverride={changeBusinessOverride}
          timezone={timezone}
        />
      </FormColumn>
    </Row>
  );
};

export default PersonalBusinessCutoff;

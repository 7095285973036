import React from "react";
import { User } from "../../../types/User";
import UserSearch, { getName } from "../../user/search/UserSearch";

type TicketRequester = {
  value: User;
  onChange: (value: User) => void;
  disabled?: boolean;
};

const TicketRequester: React.FC<TicketRequester> = ({ value, onChange, disabled = false }) => (
  <UserSearch
    label="Request On Behalf Of"
    required
    initialValue={getName(value)}
    value={value}
    onChange={onChange}
    disabled={disabled}
  />
);

export default TicketRequester;

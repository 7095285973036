import React from "react";
import { AdminYearProvider } from "./AdminYearContext";
import { DropdownsProvider } from "./DropdownsContext";
import { MetadataProvider } from "./MetadataContext";

export default ({ children }) => (
  <MetadataProvider>
    <DropdownsProvider>
      <AdminYearProvider>{children}</AdminYearProvider>
    </DropdownsProvider>
  </MetadataProvider>
);

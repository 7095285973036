import { Month } from "../types/Months";

const MonthObj: { [key: string]: Month } = {
  JAN: {
    idx: 1,
    number: "01",
    name: "January",
    abbreviation: "Jan",
  },
  FEB: {
    idx: 2,
    number: "02",
    name: "February",
    abbreviation: "Feb",
  },
  MAR: {
    idx: 3,
    number: "03",
    name: "March",
    abbreviation: "Mar",
  },
  APR: {
    idx: 4,
    number: "04",
    name: "April",
    abbreviation: "Apr",
  },
  MAY: {
    idx: 5,
    number: "05",
    name: "May",
    abbreviation: "May",
  },
  JUN: {
    idx: 6,
    number: "06",
    name: "June",
    abbreviation: "Jun",
  },
  JUL: {
    idx: 7,
    number: "07",
    name: "July",
    abbreviation: "Jul",
  },
  AUG: {
    idx: 8,
    number: "08",
    name: "August",
    abbreviation: "Aug",
  },
  SEPT: {
    idx: 9,
    number: "09",
    name: "September",
    abbreviation: "Sept",
  },
  OCT: {
    idx: 10,
    number: "10",
    name: "October",
    abbreviation: "Oct",
  },
  NOV: {
    idx: 11,
    number: "11",
    name: "November",
    abbreviation: "Nov",
  },
  DEC: {
    idx: 12,
    number: "12",
    name: "December",
    abbreviation: "Dec",
  },
};

export default MonthObj;

import "best-common-react-2/lib/styles/bcr.css";
import "core-js/stable";
import React from "react";
import ReactDom from "react-dom";
import "regenerator-runtime/runtime";
import App from "./App";
import { EnvProvider } from "./contexts/EnvContext";
import WrapperProvider from "./contexts/WrapperProvider";

ReactDom.render(
  <EnvProvider>
    <WrapperProvider>
      <App />
    </WrapperProvider>
  </EnvProvider>,
  document.getElementById("requestix-app")
);

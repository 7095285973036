import { DataTableDataType, FormatterType } from "best-common-react-2";
import React from "react";
import GameInfo from "../game/GameInfo";

const GameDateFormatter = <T extends DataTableDataType>({ row }: FormatterType<T>) => {
  //@ts-ignore
  const game = row?.game || row?.preferredGame;
  if (!!game) {
    const {
      gameDate,
      venue,
      teams: { home, away },
      seriesDescription = "",
    } = game;
    return <GameInfo gameDate={gameDate} venue={venue} home={home} away={away} seriesDescription={seriesDescription} />;
  } else {
    return <div />;
  }
};

export default GameDateFormatter;

import { Loader, Typography } from "best-common-react-2";
import React from "react";
import { ActionDTO } from "../../../types/Action";
import AdminRequestCompleteIcon from "./AdminRequestCompleteIcon";

type AdminRequestActionCheckListProps = {
  loading?: boolean;
  actions: ActionDTO[];
};

const AdminRequestActionCheckList = ({ actions, loading = false }: AdminRequestActionCheckListProps) => (
  <div>
    {loading ? (
      <Loader type="spinner" size="40px" />
    ) : (
      <>
        {actions.map((action: ActionDTO) => (
          <div className="d-flex align-items-center mb-1" key={action.actionName}>
            <AdminRequestCompleteIcon complete={action.complete} className="me-2" />
            <Typography variant="div">{action.actionName}</Typography>
          </div>
        ))}
      </>
    )}
  </div>
);

export default AdminRequestActionCheckList;

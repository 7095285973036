export const FileTypes = {
  EXCEL: "application/vnd.ms-excel",
  CSV: "text/csv",
  PDF: "application/pdf",
};

const getFilename = (response) => {
  if (!!response.headers["report-title"]) {
    return response.headers["report-title"];
  }
  const disposition = response.headers["content-disposition"];
  if (disposition && disposition.indexOf("attachment") !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, "");
    }
  }
  return "";
};

export const downloadLink = (response, type) => {
  const blob = new Blob([response.data], { type: type });
  const fileName = getFilename(response);
  //@ts-ignore
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //@ts-ignore
    window.navigator.msSaveOrOpenBlob(blob, fileName);
    return;
  }
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  setTimeout(function () {
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }, 1000);
};

import React from "react";
import styled from "styled-components";

type StyleProps = {
  size: string;
};

const Image = styled.img<StyleProps>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`;

type TeamLogoProps = {
  teamId: string | number;
  size?: string;
  onError?: (event: any) => void;
};

const TeamLogo: React.FC<TeamLogoProps> = ({ teamId, size = "1.5rem", onError = () => {} }) => {
  const teamIdNumb: number = typeof teamId === "string" ? parseInt(teamId) : teamId;
  if (teamIdNumb === 1) {
    return <Image src="https://www.mlbstatic.com/team-logos/league-on-light/1.svg" size={size} onError={onError} />;
  } else {
    return <Image src={`https://www.mlbstatic.com/team-logos/${teamId}.svg`} size={size} onError={onError} />;
  }
};

export default TeamLogo;

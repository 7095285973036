import {
  CurrencyInput,
  Email,
  FormColumn,
  Input,
  PhoneNumber,
  Row,
  SubSection,
  ToggleButtons,
  ValueOpt,
} from "best-common-react-2";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { SuiteRequestDTO, SuiteRequestSaveDTO } from "../../../types/SuiteRequest";
import { User, UserDTO } from "../../../types/User";
import UserSearch, { getName } from "../../user/search/UserSearch";
import BusinessSelect from "../form/BusinessSelect";
import GameSelectorDisplay from "../form/gameSelector/GameSelectorDisplay";

type SuiteRequestFormProps = {
  suiteRequest: SuiteRequestSaveDTO | SuiteRequestDTO;
  setSuiteRequest: ((value: SuiteRequestSaveDTO) => void) | ((value: SuiteRequestDTO) => void);
  setValidForm: (value: boolean) => void;
  currentUser?: UserDTO;
};

const YesNoOptions: ValueOpt<number>[] = [
  {
    value: 1,
    label: "Yes",
  },
  {
    value: 2,
    label: "No",
  },
];

const SuiteRequestForm: React.FC<SuiteRequestFormProps> = ({
  suiteRequest,
  setSuiteRequest,
  setValidForm,
  currentUser,
}) => {
  const { userInfo } = useAuth();
  const [validEmail, setValidEmail] = useState<boolean>(false);
  const [validPhone, setValidPhone] = useState<boolean>(false);
  const [validBallparkEmail, setValidBallparkEmail] = useState<boolean>(false);

  const onChange = (key: string, value: any) => {
    //@ts-ignore
    setSuiteRequest({ ...suiteRequest, [key]: value });
  };

  useEffect(() => {
    if (!!suiteRequest) {
      const validPaymentInfo = suiteRequest.isMlbPaying
        ? true
        : !!suiteRequest.paymentName &&
          !!suiteRequest.paymentBusinessName &&
          !!suiteRequest.paymentEmail &&
          !!suiteRequest.phoneNumber &&
          validEmail &&
          validPhone;
      const validRequest =
        !!suiteRequest.onBehalfOfUser &&
        !!suiteRequest.business &&
        !!suiteRequest.onSiteContact &&
        (!!suiteRequest.ticketBudget || suiteRequest.ticketBudget === 0) &&
        (!!suiteRequest.suiteCapacityMax || suiteRequest.suiteCapacityMax === 0) &&
        (!!suiteRequest.suiteCapacityMin || suiteRequest.suiteCapacityMin === 0) &&
        !!suiteRequest.preferredGamePk &&
        !!suiteRequest.ballparkEmail?.length &&
        validBallparkEmail;
      setValidForm(validPaymentInfo && validRequest);
    } else {
      setValidForm(false);
    }
  }, [suiteRequest, validEmail, validPhone, validBallparkEmail]);

  return (
    <>
      <Row>
        <FormColumn width={2}>
          <SubSection header="Request Info">
            <Row>
              <FormColumn width={2}>
                <UserSearch
                  id="on-behalf-of"
                  label="Request on behalf of"
                  placeholderText="select a person"
                  initialValue={getName(suiteRequest.onBehalfOfUser)}
                  existingUserIds={
                    suiteRequest.onBehalfOfUser?.employeeUserId ? [suiteRequest.onBehalfOfUser.employeeUserId] : null
                  }
                  value={!!suiteRequest.onBehalfOfUser ? suiteRequest.onBehalfOfUser : undefined}
                  onChange={(value: User) => {
                    const user = value;
                    delete user.department;
                    onChange("onBehalfOfUser", user);
                  }}
                  onClear={() => {
                    if (!!currentUser) {
                      onChange("onBehalfOfUser", currentUser);
                    }
                  }}
                  restrictUserIds={[userInfo.employeeUserId]}
                  clearable={true}
                />
              </FormColumn>
              <FormColumn width={2}>
                <BusinessSelect
                  businessId={suiteRequest?.business?.businessId}
                  setBusiness={(value) => onChange("business", value)}
                  required={true}
                />
              </FormColumn>
              <FormColumn width={2}>
                <Input
                  id="on-site-contact"
                  placeholder="First Last"
                  label="On Site Contact"
                  value={suiteRequest.onSiteContact}
                  onChange={(value) => onChange("onSiteContact", value)}
                  required
                  gutterBottom
                />
              </FormColumn>
              <FormColumn width={2}>
                <ToggleButtons
                  id="mlb-paying"
                  name="mlb-paying"
                  label="Is MLB Paying?"
                  required
                  options={YesNoOptions}
                  onChange={(value: number) => {
                    onChange("isMlbPaying", value === 1);
                  }}
                  value={suiteRequest.isMlbPaying ? 1 : 2}
                  gutterBottom
                />
              </FormColumn>
              <FormColumn width={4}>
                <Input
                  id="suite-capacity-min"
                  required
                  label="Suite Capacity Min"
                  type="number"
                  placeholder="# of people"
                  value={suiteRequest.suiteCapacityMin}
                  onChange={(value) => onChange("suiteCapacityMin", value)}
                  gutterBottom
                />
              </FormColumn>
              <FormColumn width={4}>
                <Input
                  id="suite-capacity-max"
                  type="number"
                  label="Suite Capacity Max"
                  placeholder="# of people"
                  required
                  value={suiteRequest.suiteCapacityMax}
                  onChange={(value) => onChange("suiteCapacityMax", value)}
                  gutterBottom
                />
              </FormColumn>
              <FormColumn width={2}>
                <CurrencyInput
                  id="ticket-budget"
                  label="Ticket Budget"
                  value={suiteRequest.ticketBudget}
                  placeholder="000.00"
                  required
                  onChange={(value) => onChange("ticketBudget", value)}
                  gutterBottom
                />
              </FormColumn>
              <FormColumn width={2}>
                <Email
                  id="ballpark-email"
                  label="Ballpark Email"
                  value={suiteRequest.ballparkEmail ?? ""}
                  required
                  onChange={(value) => onChange("ballparkEmail", value)}
                  setValidEmail={setValidBallparkEmail}
                  gutterBottom
                />
              </FormColumn>
            </Row>
          </SubSection>
        </FormColumn>
        <FormColumn width={2}>
          <SubSection header="Game Info">
            <div className="d-flex flex-column">
              <div className="mb-4">
                <GameSelectorDisplay
                  value={suiteRequest.preferredGamePk}
                  onChange={(value: number) => {
                    onChange("preferredGamePk", value);
                  }}
                  label="Primary Game"
                  required={true}
                />
              </div>
              <GameSelectorDisplay
                value={suiteRequest.secondaryGamePk}
                onChange={(value: number) => {
                  onChange("secondaryGamePk", value);
                }}
                label="Secondary Game"
                required={false}
              />
            </div>
          </SubSection>
        </FormColumn>
      </Row>
      {!suiteRequest.isMlbPaying && (
        <Row>
          <FormColumn width={2}>
            <SubSection header="Payment Info">
              <Row>
                <FormColumn>
                  <Input
                    id="name"
                    label="Name"
                    placeholder="Payment Name"
                    required
                    value={suiteRequest.paymentName}
                    onChange={(value: string) => onChange("paymentName", value)}
                    gutterBottom
                  />
                </FormColumn>
                <FormColumn width={2}>
                  <Input
                    id="payment-business"
                    label="Payment Business"
                    placeholder="Payment Business"
                    required
                    value={suiteRequest.paymentBusinessName}
                    onChange={(value: string) => onChange("paymentBusinessName", value)}
                    gutterBottom
                  />
                </FormColumn>
                <FormColumn width={2}>
                  <Email
                    id="email"
                    label="Email"
                    placeholder="Payment Email Address"
                    value={suiteRequest.paymentEmail ?? ""}
                    onChange={(value: string) => onChange("paymentEmail", value)}
                    setValidEmail={setValidEmail}
                    gutterBottom
                    required
                  />
                </FormColumn>
                <FormColumn width={2}>
                  <PhoneNumber
                    id="phone-number"
                    label="Phone"
                    required
                    value={suiteRequest.phoneNumber ?? ""}
                    onChange={(value: string) => onChange("phoneNumber", value)}
                    setValidPhoneNumber={setValidPhone}
                    gutterBottom
                  />
                </FormColumn>
              </Row>
            </SubSection>
          </FormColumn>
        </Row>
      )}
    </>
  );
};

export default SuiteRequestForm;

import React from "react";

export default {
  name: "Name",
  title: "Title",
  department: "Department",
  pending: "Pending",
  personal_approved: (
    <div className="d-flex flex-column">
      <div>Personal</div>
      <div>Approved</div>
    </div>
  ),
  business_approved: (
    <div className="d-flex flex-column">
      <div>Business</div>
      <div>Approved</div>
    </div>
  ),
  allotted: "Alloted",
  email: "Email",
  role: "Role",
};

import dateFormat from "dateformat";
import { isMoment } from "moment";
import { Moment } from "moment-timezone";
import { useEffect, useState } from "react";
import { DateType } from "../types/Dates";
import {
  getMonthsValueOptions,
  getNumberValueOption,
  getYearValueOptions,
  MonthsValueOptions,
} from "../types/DateTypes";
import { ValueOpt } from "../types/Dropdown";
import { isString, replaceAll } from "./StringUtil";

export const DATE_FORMATTER_STR = "mm/dd/yyyy";
export const DATE_FORMATTER_STR_WITH_TIME = "mm/dd/yyyy hh:mm TT";
export const HTML5_DATE_STRING = "yyyy-mm-dd";
export const HTML5_DATETIME_STRING = "yyyy-mm-dd'T'hh:mm";

export const formatDate = (value?: DateType | string, format = DATE_FORMATTER_STR): string => {
  if (!!value) {
    if (isString(value)) {
      return value as string;
    } else if (isMoment(value)) {
      return dateFormat((value as Moment).date(), format);
    } else {
      return dateFormat(value as Date, format);
    }
  } else {
    return "";
  }
};

export const hasPast = (date: Date): boolean => isAfter(date, new Date());

export const isAfter = (date: Date, test: Date): boolean => date.getTime() > test.getTime();

export const isBefore = (date: Date, test: Date): boolean => date.getTime() < test.getTime();

export const validateDate = (date?: Date | number) => {
  if (!date) {
    return false;
  }
  const convertedDate = new Date(date);
  return !isNaN(convertedDate.getTime());
};

export const formatDateWithSlashes = (date: string): string => {
  return !date ? "" : replaceAll(date, "-", "/");
};

const startingYear = 1980;

export const calculateMaxYear = (shouldFilter: boolean, minDate?: Date, maxDate?: Date): ValueOpt<number>[] => {
  if (shouldFilter) {
    if (!!maxDate && !!minDate) {
      const minYear: number = minDate.getFullYear();
      const maxYear: number = maxDate.getFullYear();
      return getYearValueOptions(minYear, maxYear);
    } else if (!!maxDate) {
      const maxYear: number = maxDate.getFullYear();
      return getYearValueOptions(startingYear, maxYear);
    } else if (!!minDate) {
      const minYear: number = minDate.getFullYear();
      return getYearValueOptions(minYear, new Date().getFullYear() + 10);
    }
  }
  return getYearValueOptions(startingYear, new Date().getFullYear() + 10);
};

export const calculateMaxMonth = (
  shouldFilter: boolean,
  monthsValueOptions: MonthsValueOptions,
  yearValue?: ValueOpt<number>,
  minDate?: Date,
  maxDate?: Date
): ValueOpt<string>[] => {
  if (shouldFilter) {
    if (!!yearValue?.value) {
      if (!!maxDate && !!minDate) {
        const minYear: number = minDate.getFullYear();
        const maxYear: number = maxDate.getFullYear();

        if (yearValue.value === maxYear) {
          return getMonthsValueOptions(monthsValueOptions.slice(0, maxDate.getMonth() + 1));
        } else if (yearValue.value === minYear) {
          return getMonthsValueOptions(monthsValueOptions.slice(minDate.getMonth()));
        }
      } else if (!!maxDate) {
        const maxYear: number = maxDate.getFullYear();
        if (yearValue.value === maxYear) {
          return getMonthsValueOptions(monthsValueOptions.slice(0, maxDate.getMonth() + 1));
        }
      } else if (!!minDate) {
        const minYear: number = minDate.getFullYear();
        if (yearValue.value === minYear) {
          return getMonthsValueOptions(monthsValueOptions.slice(minDate.getMonth()));
        }
      }
    }
  }
  return getMonthsValueOptions(monthsValueOptions);
};

const maxDaysOfMonth: number[] = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
export const calculateMaxDay = (
  shouldFilter: boolean,
  monthsValueOptions: MonthsValueOptions,
  yearValue?: ValueOpt<number>,
  monthValue?: ValueOpt<string>,
  minDate?: Date,
  maxDate?: Date
): ValueOpt<number>[] => {
  let minDay = 1;
  let maxDay = 31;
  if (shouldFilter) {
    if (!!yearValue && !!monthValue) {
      const monthIndex: number = monthsValueOptions.findIndex((opt: string) => opt === monthValue?.value);
      if (monthIndex > -1) {
        maxDay = maxDaysOfMonth[monthIndex] || 31;
        if (!!maxDate && !!minDate) {
          const minYear: number = minDate.getFullYear();
          const maxYear: number = maxDate.getFullYear();
          const minMonth: number = minDate.getMonth();
          const maxMonth: number = maxDate.getMonth();
          if (yearValue.value === maxYear) {
            if (maxMonth === monthIndex) {
              maxDay = maxDate.getDate();
            }
          } else if (yearValue.value === minYear) {
            if (minMonth === monthIndex) {
              minDay = minDate.getDate();
            }
          }
        } else if (!!maxDate) {
          const maxYear: number = maxDate.getFullYear();
          const maxMonth: number = maxDate.getMonth();
          if (yearValue.value === maxYear) {
            if (maxMonth === monthIndex) {
              maxDay = maxDate.getDate();
            }
          }
        } else if (!!minDate) {
          const minYear: number = minDate.getFullYear();
          const minMonth: number = minDate.getMonth();
          if (yearValue.value === minYear) {
            if (minMonth === monthIndex) {
              minDay = minDate.getDate();
            }
          }
        }
      }

      //edge case for leap year
      if (monthIndex === 1 && yearValue?.value % 4 === 0 && maxDay === 28) {
        maxDay = 29;
      }
    }
  }
  return getNumberValueOption(minDay, maxDay);
};

export const useCountdown = (value: number): string => {
  const [currentTime, setCurrentTime] = useState(value);

  const millisToMinutesAndSeconds = (millis: number): string => {
    const minutes: number = Math.floor(millis / 60000);
    const seconds: number = parseInt(((millis % 60000) / 1000).toFixed(0));
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  };

  useEffect(() => {
    const intv: NodeJS.Timeout = setTimeout(() => {
      setCurrentTime(currentTime - 1000);
    }, 1000);

    return () => {
      if (!!intv) {
        clearInterval(intv);
      }
    };
  }, [currentTime]);

  return millisToMinutesAndSeconds(currentTime);
};

export const getDaysInMonth = (year: number, month: number): number => {
  return new Date(year, month, 0).getDate();
};

export const getLastDayOfMonth = (month: string, year?: number): number => {
  const useYear = year || new Date().getFullYear();
  const nextMonth = new Date(useYear, parseInt(month), 1);
  nextMonth.setDate(nextMonth.getDate() - 1);
  return nextMonth.getDate();
};

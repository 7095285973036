import { Attribute, FormColumn, Row, Search, Select, sort, ValueOpt } from "best-common-react-2";
import React, { useEffect, useMemo, useState } from "react";
import { getDepartments } from "../../../api/RequesTixApi";
import { APPROVED, DENIED } from "../../../constants/RequestTableConstants";
import { useAuth } from "../../../contexts/AuthContext";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { useMetadata } from "../../../contexts/MetadataContext";
import { Department } from "../../../types/Department";
import { RequestState } from "../../../types/RequestType";
import { Season } from "../../../types/Season";
import { User } from "../../../types/User";
import { getCategoryOption, getDepartmentOption, getSeasonOption, getStatusOption } from "../../../util/DropdownUtil";
import UserSearch from "../../user/search/UserSearch";
import UserAllotmentBreakdownDisplay from "../../user/UserAllotmentBreakdownDisplay";
import { ReportHandlerTypeProps } from "../ReportHandler";
import AllUsersRequests from "./AllUsersRequests";
import UserRequests from "./UserRequests";

const UserRequestsReport = ({ reportData, setReportData, location, setCanExport }: ReportHandlerTypeProps) => {
  const { isAdmin } = useAuth();
  const { activeSeason } = useMetadata();
  const {
    seasonsOptions,
    requestCategoryOptions,
    requestStateOptions,
    requestFulfillmentTypesOptions,
    gameOptions,
    getOptionByValue,
  } = useDropdowns();
  const [user, setUser] = useState<User | undefined>(undefined);
  const [currentSeason, setCurrentSeason] = useState<Season | undefined>(undefined);
  const [departmentOptions, setDepartmentOptions] = useState<ValueOpt<Department | object>[]>([]);
  const { categoryId, seasonId, search, fulfillmentTypeId, statusId, userId, departmentId, gameSearchId } = reportData;
  const isUserAll = !userId && isAdmin;
  //@ts-ignore
  const fulfillmentTypesOptions: ValueOpt<RequestState>[] = useMemo(
    () => [{ label: "All", value: {} as RequestState } as ValueOpt<RequestState>, ...requestFulfillmentTypesOptions],
    [requestFulfillmentTypesOptions]
  );
  const validStateIds = [APPROVED, DENIED];
  const validStates: ValueOpt<RequestState>[] = useMemo(
    () => requestStateOptions.filter((state) => validStateIds.includes(state.value.requestStateId)),
    [requestStateOptions]
  );
  const stateOptions: ValueOpt<RequestState>[] = useMemo(
    () => [{ label: "All", value: {} }, ...validStates],
    [validStates]
  );

  const onChange = (key, data) => {
    setReportData({ ...reportData, [key]: data });
  };

  useEffect(() => {
    const seasonId = reportData.seasonId ? reportData.seasonId : activeSeason.seasonId;
    const val = seasonsOptions.find((season) => season.value.seasonId * 1 === seasonId * 1);
    if (val) {
      setCurrentSeason(val.value);
      onChange("seasonId", val.value.seasonId);
    }
  }, [activeSeason, seasonsOptions, reportData.seasonId]);

  useEffect(() => {
    if (isUserAll) {
      getDepartments().then((data: Department[]) => {
        setDepartmentOptions(
          [
            {
              label: "All",
              value: {},
            },
          ].concat(
            sort(
              data.map((d: Department) => ({
                label: d.departmentName,
                value: d,
              })),
              "label",
              1
            )
          )
        );
      });
    }
  }, [isUserAll]);

  return (
    <>
      <Row>
        {isAdmin && (
          <FormColumn width={4}>
            <UserSearch
              label="User"
              id="user-search"
              value={user}
              existingUserIds={[parseInt(userId as string)]}
              onChange={(value: User) => {
                if (parseInt(userId as string) !== value.employeeUserId) {
                  onChange("userId", value.employeeUserId);
                }
                setUser(value);
              }}
              loadAll={true}
              withRequests={true}
              gutterBottom
            />
          </FormColumn>
        )}
        <FormColumn width={4}>
          <Select
            id="season"
            label="Season"
            options={seasonsOptions}
            value={getSeasonOption(seasonId, seasonsOptions)}
            onChange={(value) => {
              onChange("seasonId", value.value.seasonId);
            }}
            gutterBottom
          />
        </FormColumn>
        {isUserAll && (
          <FormColumn width={4}>
            <Select
              id="department"
              label="Department"
              options={departmentOptions}
              value={getDepartmentOption(departmentId, departmentOptions)}
              onChange={(value) => {
                onChange("departmentId", value.value.departmentId);
              }}
              gutterBottom
            />
          </FormColumn>
        )}
        <FormColumn width={4}>
          <Select
            id="category"
            label="Category"
            options={requestCategoryOptions}
            value={getCategoryOption(categoryId, requestCategoryOptions)}
            onChange={(value) => {
              onChange("categoryId", value.value.requestCategoryId);
            }}
            gutterBottom
          />
        </FormColumn>
        <FormColumn width={4}>
          <Select
            id="games"
            label="Games"
            options={gameOptions}
            value={gameOptions.find((option) => option.value === (gameSearchId ? gameSearchId : null))}
            onChange={(value: ValueOpt<number>) => {
              onChange("gameSearchId", value.value);
            }}
            gutterBottom
          />
        </FormColumn>
        <FormColumn width={4}>
          <Search
            id="search"
            label="Search Requests"
            value={search}
            onChange={(value: string) => {
              onChange("search", value);
            }}
            placeholder="begin typing to filter requests"
            gutterBottom
          />
        </FormColumn>
        {!isUserAll && (
          <>
            <FormColumn width={4}>
              <Select
                id="status"
                label="Status"
                options={stateOptions}
                value={getStatusOption(statusId, stateOptions)}
                onChange={(value) => {
                  onChange("statusId", value.value.requestStateId);
                }}
                gutterBottom
              />
            </FormColumn>
            <FormColumn width={4}>
              <Select
                id="fulfillment"
                label="Fulfillment"
                options={fulfillmentTypesOptions}
                value={getOptionByValue(fulfillmentTypesOptions, fulfillmentTypeId ? fulfillmentTypeId : {})}
                onChange={(value) => {
                  const val = typeof value.value === "object" ? null : value.value;
                  onChange("fulfillmentTypeId", val);
                }}
                gutterBottom
              />
            </FormColumn>
          </>
        )}
      </Row>
      {!isUserAll && (
        <>
          <Row>
            {isAdmin && (
              <>
                <FormColumn width={4}>
                  <Attribute header="Department" value={user?.department?.departmentName ?? ""} />
                </FormColumn>
                <FormColumn width={4}>
                  <Attribute header="Title" value={user?.title ?? ""} />
                </FormColumn>
              </>
            )}
          </Row>
          {!!currentSeason && (
            <Row className="mt-2">
              <FormColumn width={4}>
                <UserAllotmentBreakdownDisplay userId={userId} year={currentSeason.year} />
              </FormColumn>
            </Row>
          )}
        </>
      )}

      <Row className="mt-2">
        <FormColumn>
          {isUserAll ? (
            <AllUsersRequests
              seasonId={seasonId}
              departmentId={departmentId}
              search={search}
              location={location}
              setCanExport={setCanExport}
            />
          ) : (
            <UserRequests
              categoryId={categoryId}
              seasonId={seasonId}
              fulfillmentTypeId={fulfillmentTypeId}
              statusId={statusId}
              gameSearchId={gameSearchId}
              userId={userId}
              search={search}
              location={location}
              setCanExport={setCanExport}
            />
          )}
        </FormColumn>
      </Row>
    </>
  );
};

export default UserRequestsReport;

import moment, { Moment } from "moment-timezone";
import { EventTimingTypeDTO } from "../types/EventTiming";
import { GameDTO, GameStatus, GameTime } from "../types/Game";
import { TimeZone } from "../types/Venue";

export type Duration = {
  minutes?: number;
  hours?: number;
  days?: number;
};

export type Offset = Duration & {
  text?: string;
};

export type TimeUntil = {
  offset: Offset;
  accessible: boolean;
};

const DEFAULT_TIMEZONE = "America/New_York";
const GAME_DATE_FORMAT = "ddd M/D";
const GAME_DATETIME_FORMAT = "ddd M/D - h:mma";
export const DATE_FORMAT = "MM/DD/YYYY";
export const SUBMITTED_DATE_FORMAT = "MM/DD/YYYY - h:mm a";
export const format = (date?: Date | Moment, format: string | undefined = DATE_FORMAT): string =>
  !!date ? (moment.isMoment(date) ? date.format(format) : moment(date).format(format)) : "";

export const today: Date = moment().toDate();

export const tomorrow: Date = moment().add(1, "days").toDate();

export const yesterday: Date = moment().add(-1, "days").toDate();

export const nextMonth: Date = moment().add(1, "months").toDate();

export const oneMonthBeforeYesterday: Date = moment().add(-1, "months").add(-1, "days").toDate();

export const oneWeekAgo: Date = moment().add(-1, "weeks").toDate();

export const oneWeek: Date = moment().add(1, "weeks").toDate();

export const twoWeeks: Date = moment().add(2, "weeks").toDate();

export const convertTz = (tz) => (tz.length === 2 ? tz : tz[0] + tz[tz.length - 1]);

export function useTimezone(date?: Date, timezone?: TimeZone, status?: GameStatus): GameTime {
  if (!date && !timezone) return {};
  const isTBD = !!status?.startTimeTBD;
  let converted = isTBD
    ? moment(date).utc()
    : timezone
    ? moment(date).tz(timezone.id)
    : moment(date).tz(DEFAULT_TIMEZONE);

  if (!converted) {
    converted = moment(date);
  }
  const convertedDate: string = converted.format("MMM D");
  const day: string = converted.format("ddd");
  const time: string = !isTBD ? `${converted.format("h:mm a")} ${convertTz(converted.format("zz"))}` : "TBD";

  return { date: convertedDate, day, time };
}

export function isPastHourOfDay(hourOfDay: number, timeZone: string): boolean {
  const currentEasternTime = moment().tz(timeZone);
  const hourOfDayEastern = moment()
    .tz(timeZone)
    .set("hour", hourOfDay)
    .set("minutes", 0)
    .set("seconds", 0)
    .set("milliseconds", 0);
  return isAfter(currentEasternTime, hourOfDayEastern);
}

export function formatSubmittedDate(gameDate: Date | string): string {
  return moment(gameDate).format(SUBMITTED_DATE_FORMAT);
}

export function formatGameDate(date?: Date | string, timezone?: TimeZone, tbd?: boolean): string {
  const tz: string = timezone && timezone.tz ? convertTz(timezone.tz) : "";
  const tzId: string = timezone ? timezone.id : moment.tz.guess();
  if (!!tbd) {
    return moment(date).utc().format(GAME_DATE_FORMAT) + " - TBD";
  } else {
    const result = moment(date).tz(tzId);
    if (!!result) {
      return result.format(GAME_DATETIME_FORMAT) + " " + tz;
    } else {
      return moment(result).format(GAME_DATETIME_FORMAT) + " " + tz;
    }
  }
}

export function useCutoffTime(event: GameDTO, useDate = false, date: Date, hoursBefore: number) {
  if (useDate) {
    return formatGameDate(date, event.venue.timeZone, !!event.status ? event.status.startTimeTBD : null);
  }
  return getHoursBeforeText(hoursBefore);
}

export function getDateText(date: Date): string {
  return moment(date).tz(DEFAULT_TIMEZONE).format("MM/DD/YYYY - h:mma");
}

export function getHoursBeforeText(hoursBefore: number): string {
  const days = Math.floor(hoursBefore / 24);
  const daysStr = days > 0 ? `${days} day${days > 1 ? "s" : ""} ` : "";
  const hours = hoursBefore % 24;
  const hoursStr = hours > 0 ? `${hours} hr${hours > 1 ? "s" : ""} ` : "";
  return daysStr + hoursStr + "prior to game";
}

export function useTimeUntil(
  gameDate: Date,
  eventAccess: EventTimingTypeDTO,
  eventCutoff: EventTimingTypeDTO
): TimeUntil {
  const now = moment();
  const access = eventAccess
    ? eventAccess.useDate
      ? moment(eventAccess.date)
      : moment(gameDate).subtract(eventAccess.hoursBefore, "hours")
    : null;
  const cutoff = eventCutoff
    ? eventCutoff.useDate
      ? moment(eventCutoff.date)
      : moment(gameDate).subtract(eventCutoff.hoursBefore, "hours")
    : null;

  if (access && access.diff(now, "minutes") > 0) {
    return { offset: { ...convertDuration(now, access), text: "request tickets in" }, accessible: false };
  } else if (cutoff && cutoff.diff(now, "minutes") > 0) {
    return {
      offset: { ...convertDuration(now, cutoff), text: "time remaining" },
      accessible: true,
    };
  } else {
    return {
      offset: {
        days: 0,
        hours: 0,
        minutes: 0,
        text: "time remaining",
      },
      accessible: false,
    };
  }
}

export function useDayDate(gameDate: Date | Moment | string): string {
  return moment(gameDate).format(GAME_DATE_FORMAT);
}

const convertDuration = (now: Date | Moment | string, then: Moment): Duration => {
  const diff: number = then.diff(now);
  const duration: moment.Duration = moment.duration(diff);
  const days: number = Math.floor(duration.asDays());
  duration.subtract(moment.duration(days, "days"));
  const hours: number = Math.floor(duration.asHours());
  duration.subtract(moment.duration(hours, "hours"));
  const minutes: number = Math.floor(duration.asMinutes());
  return { days, hours, minutes };
};

export const isDayBefore = (date1: Date | Moment | string, date2: Date | Moment | string): boolean => {
  const mom1 = moment.isMoment(date1) ? date1 : moment(date1);
  const mom2 = moment.isMoment(date2) ? date2 : moment(date2);
  return mom1.isBefore(mom2) || mom1.diff(mom2, "days") === 0;
};

export const isBefore = (date1: Date | Moment | string, date2: Date | Moment | string): boolean => {
  const mom1 = moment.isMoment(date1) ? date1 : moment(date1);
  const mom2 = moment.isMoment(date2) ? date2 : moment(date2);
  return mom1.isBefore(mom2);
};

export const isAfter = (date1: Date | Moment | string, date2: Date | Moment | string): boolean => {
  const mom1 = moment.isMoment(date1) ? date1 : moment(date1);
  const mom2 = moment.isMoment(date2) ? date2 : moment(date2);
  return mom1.isAfter(mom2);
};

import { Checkbox, Typography } from "best-common-react-2";
import React, { useEffect, useState } from "react";

type TicketAgreementProps = {
  setAgreed: (value: boolean) => void;
};

const TicketAgreement = ({ setAgreed }: TicketAgreementProps) => {
  const [ticketsPersonal, setTicketsPersonal] = useState(false);
  const [mlbAgreement, setMlbAgreement] = useState(false);

  useEffect(() => {
    setAgreed(ticketsPersonal && mlbAgreement);
  }, [ticketsPersonal, mlbAgreement]);

  return (
    <div className="d-flex flex-column mt-2">
      <div className="mb-2">
        <Checkbox
          id="tickets-personal"
          checked={ticketsPersonal}
          onChange={(value: boolean) => setTicketsPersonal(value)}
          label="I agree that these tickets are for personal use and if I am selected to receive the tickets"
          className="mb-2"
          required
        />
        <ul>
          <li>I plan to attend the game.</li>
          <li>I will not forward them to other employees.</li>
          <li>I will not forward them to business contacts.</li>
        </ul>
      </div>
      <div>
        <Checkbox
          id="mlb-agreement"
          checked={mlbAgreement}
          onChange={(value: boolean) => setMlbAgreement(value)}
          label="I agree to the below statement"
          className="mb-2"
          required
        />
        <Typography variant="div">
          Any tickets received from any Baseball Entities, whether through purchase, contractual commitment, or
          otherwise, may not be offered for any public sale, either through any online resale outlet or any offline
          ticket reseller. For purposes of the foregoing, “Baseball Entities” include MLB Advanced Media, L.P., the
          Office of the Commissioner of Baseball, the Major League Baseball Clubs, Major League Baseball Properties,
          Inc., The MLB Network, LLC, and each of their respective parent, subsidiary, affiliated or related entities.
        </Typography>
      </div>
    </div>
  );
};

export default TicketAgreement;

import {
  BCRSuspense,
  Button,
  DataTable,
  DataTableColumn,
  DataTableDataType,
  FormatterType,
  FormColumn4Wide,
  Modal,
  Select,
  StickyFooterButtons,
} from "best-common-react-2";
import React, { useEffect, useState } from "react";
import { getSchedule } from "../../../../api/StatsApi";
import { useDropdowns } from "../../../../contexts/DropdownsContext";
import { ValueOpt } from "../../../../types/Dropdown";
import { Month } from "../../../../types/Months";
import { StatsApiGame, StatsApiSchedule, StatsApiScheduleDate } from "../../../../types/Stats";
import { Team } from "../../../../types/Team";
import { getLastDayOfMonth } from "../../../../util/DateUtil";
import SimpleGameMatchup from "../../../game/SimpleGameMatchup";

const createQueryParams = (team: number, month: string) => {
  const teamQueryParam = `teamId=${team}`;
  const year = new Date().getFullYear();
  const lastDayOfMonth = getLastDayOfMonth(month);
  const monthQueryParam = `startDate=${month}/01/${year}&endDate=${month}/${lastDayOfMonth}/${year}`;
  return `${teamQueryParam}&${monthQueryParam}`;
};

type FlatGame = DataTableDataType & {
  gamePk: number;
  gameDate: string;
  homeTeamId: number;
  awayTeamId: number;
  isSelected: boolean;
};

const createFlatGame = (game: StatsApiGame) => {
  return {
    gamePk: game.gamePk,
    isSelected: false,
    homeTeamId: game.teams.home.team.id,
    awayTeamId: game.teams.away.team.id,
    gameDate: game.gameDate,
  } as FlatGame;
};

const GameFormatter = (props: FormatterType<FlatGame>) => {
  const { row } = props;
  const fg: FlatGame = row as FlatGame;
  const { gameDate, homeTeamId, awayTeamId } = fg;
  return <SimpleGameMatchup gameDate={gameDate} homeTeamId={homeTeamId} awayTeamId={awayTeamId} />;
};

const GameColumns: DataTableColumn<FlatGame>[] = [
  {
    key: "",
    name: "",
    readonlyFormatter: GameFormatter,
  },
];

type GameSelectorModalProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  setSelectedGame: (value: StatsApiGame) => void;
};

const GameSelectorModal: React.FC<GameSelectorModalProps> = ({ isOpen, setIsOpen, setSelectedGame }) => {
  const { teamsOptions: teamOpts, monthOptions } = useDropdowns();
  const [loading, setLoading] = useState<boolean>(false);
  const [games, setGames] = useState<StatsApiGame[]>([]);
  const [flatGames, setFlatGames] = useState<FlatGame[]>([]);
  const [teamsOptions, setTeamsOptions] = useState<ValueOpt<Team>[]>([]);
  const [activeSelection, setActiveSelection] = useState<StatsApiGame | undefined>();
  const [team, setTeam] = useState<number>();
  const [month, setMonth] = useState<string>();

  const fetchGames = async () => {
    try {
      setLoading(true);
      const statsQueryParams: string = createQueryParams(team, month);
      const data: StatsApiSchedule = await getSchedule(statsQueryParams);
      const games: StatsApiGame[] = data.dates
        .map((date: StatsApiScheduleDate) => date.games)
        .reduce((a, b) => a.concat(b))
        .filter((g: StatsApiGame) => g.teams.home.team.id === team);
      setGames(games);
    } catch (e) {
      console.error(e);
      setGames([]);
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const selectGame = (values: FlatGame[]) => {
    const flatGame: FlatGame = values[0];
    setFlatGames(
      flatGames.map((fg: FlatGame) => ({
        ...fg,
        id: fg.gamePk,
        isSelected: fg.gamePk === flatGame.gamePk,
      }))
    );
  };

  const deselectGame = (_values: FlatGame[]) => {
    setFlatGames(
      flatGames.map((fg: FlatGame) => ({
        ...fg,
        isSelected: false,
      }))
    );
  };

  const sendGame = () => {
    setSelectedGame(activeSelection);
    onClose();
  };

  useEffect(() => {
    if (teamOpts.length) {
      setTeamsOptions(teamOpts.slice(1));
    } else {
      setTeamsOptions([]);
    }
  }, [teamOpts]);

  useEffect(() => {
    if (isOpen && month && team) {
      void fetchGames();
    } else {
      setGames([]);
    }
  }, [isOpen, team, month]);

  useEffect(() => {
    const selected = flatGames.find((fg: FlatGame) => fg.isSelected);
    if (!!selected) {
      setActiveSelection(games.find((g: StatsApiGame) => g.gamePk === selected.gamePk));
    }
  }, [games, flatGames]);

  useEffect(() => {
    const flattened: FlatGame[] = games.map((g: StatsApiGame) => createFlatGame(g));
    setFlatGames(flattened);
  }, [games]);

  return (
    <Modal show={isOpen} size="xl">
      <Modal.Header close={onClose}>Games</Modal.Header>
      <Modal.Body>
        <div className="row">
          <FormColumn4Wide>
            <Select
              id="hometeam-dropdown"
              label="Home Team"
              options={teamsOptions}
              required={true}
              value={teamsOptions.find((to: ValueOpt<Team>) => to.value.id === team?.toString())}
              onChange={(team: ValueOpt<Team>) => setTeam(parseInt(team.value.id))}
              placeholder="Home Team"
            />
          </FormColumn4Wide>
          <FormColumn4Wide>
            <Select
              id="month-dropdown"
              label="Month"
              options={monthOptions}
              value={monthOptions.find((to: ValueOpt<Month>) => to.value.number === month)}
              required={true}
              onChange={(value: ValueOpt<Month>) => setMonth(value.value.number)}
              placeholder="Month"
            />
          </FormColumn4Wide>
        </div>
        <div className="mt-2 w-100">
          {loading ? (
            <BCRSuspense />
          ) : (
            <>
              <DataTable
                columns={GameColumns}
                data={flatGames}
                selectAll={false}
                rowSelection={{
                  showCheckbox: true,
                  onRowsSelected: selectGame,
                  onRowsDeselected: deselectGame,
                  selectBy: {
                    isSelectedKey: "isSelected",
                  },
                  canSelect: () => true,
                }}
              />
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <StickyFooterButtons>
          <Button className="me-2" variant="primary" onClick={sendGame} disabled={!activeSelection}>
            Select
          </Button>
          <Button variant="default" onClick={onClose}>
            Cancel
          </Button>
        </StickyFooterButtons>
      </Modal.Footer>
    </Modal>
  );
};

export default GameSelectorModal;

import React, { createContext, useContext, useEffect, useState } from "react";
import { getEvent } from "../api/RequesTixApi";
import { GameDTO } from "../types/Game";
import { getSeasonTypeForGame, seasonTypesCodes, SPRING_TRAINING } from "../util/SeasonUtil";

type TicketRequestContextType = {
  gamePk?: string;
  game?: GameDTO;
  fetchingGame: boolean;
  isSpringTraining: boolean;
};

const TicketRequestContext = createContext<TicketRequestContextType | undefined>(undefined);

export type TicketRequestProviderProps = {
  gamePk?: string;
};

const TicketRequestProvider: React.FC<React.PropsWithChildren<TicketRequestProviderProps>> = ({ children, gamePk }) => {
  const [isSpringTraining, setIsSpringTraining] = useState<boolean>(false);
  const [fetchingGame, setFetchingGame] = useState<boolean>(false);
  const [game, setGame] = useState<GameDTO>();

  const fetchGame = async (gamePk: string) => {
    try {
      setFetchingGame(true);
      const res: GameDTO = await getEvent(gamePk);
      setGame(res);
    } catch (e) {
      console.error(e);
    } finally {
      setFetchingGame(false);
    }
  };

  useEffect(() => {
    if (!!game) {
      const seasonType = getSeasonTypeForGame(game);
      setIsSpringTraining(seasonType.id === seasonTypesCodes[SPRING_TRAINING].id);
    } else {
      setIsSpringTraining(false);
    }
  }, [game]);

  useEffect(() => {
    if (!!gamePk) {
      void fetchGame(gamePk);
    }
  }, [gamePk]);

  return (
    <TicketRequestContext.Provider
      value={{
        gamePk,
        fetchingGame,
        isSpringTraining,
        game,
      }}
    >
      {children}
    </TicketRequestContext.Provider>
  );
};

const useTicketRequest = (): TicketRequestContextType => {
  const ticketRequestContext: TicketRequestContextType | undefined = useContext<TicketRequestContextType | undefined>(
    TicketRequestContext
  );
  if (ticketRequestContext === undefined) {
    throw new Error(`useTicketRequest must be used within a TicketRequestProvider`);
  }
  return ticketRequestContext;
};

export { TicketRequestContext, TicketRequestProvider, useTicketRequest };

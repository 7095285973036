import { Checkbox, DesktopView } from "best-common-react-2";
import React, { useEffect } from "react";
import styled from "styled-components";
import { getSurroundingGames } from "../../../api/RequesTixApi";
import { useAuth } from "../../../contexts/AuthContext";
import { Game } from "../../../types/Game";
import SurroundingGame from "../../misc/SurroundingGame";

const ButtonLink = styled.button.attrs({ className: "btn btn-link" })`
  font-size: 0.875rem;
  margin-left: 0.5rem;
`;

const SelectTabletText = styled.div`
  display: none;
  @media (min-width: 768px) and (max-width: 991px) {
    display: block;
  }
`;

const SurroundingGames = styled.div`
  margin-left: 0.5rem;
`;

type GameListProps = {
  gamePk: string;
  surroundingGames: Game[];
  onChange: (value: string, checked: boolean) => void;
  selected: string[];
  canSelectGame?: boolean;
  onGameChange: (value: string) => void;
};

const GameList: React.FC<GameListProps> = ({
  gamePk,
  surroundingGames,
  onChange,
  selected,
  canSelectGame = false,
  onGameChange,
}) => (
  <div>
    {surroundingGames.map((game) => (
      <AdditionalGame
        game={game}
        isChecked={selected.includes(game.gamePk)}
        key={game.gamePk}
        onChange={onChange}
        canSelectGame={canSelectGame}
        disabled={gamePk === game.gamePk}
        onGameChange={onGameChange}
      />
    ))}
    {surroundingGames.length === 0 && <span>There are no surrounding games</span>}
  </div>
);

type AdditionalGameProps = {
  game: Game;
  isChecked: boolean;
  onChange: (value: string, checked: boolean) => void;
  canSelectGame?: boolean;
  disabled: boolean;
  onGameChange: (value: string) => void;
};

const AdditionalGame: React.FC<AdditionalGameProps> = ({
  game,
  isChecked,
  onChange,
  canSelectGame = false,
  disabled,
  onGameChange,
}) => {
  const { isAdmin } = useAuth();
  return (
    <div className="d-flex align-items-center mt-1">
      <Checkbox
        id={`another-game-${game.gamePk}`}
        checked={isChecked}
        label=""
        onChange={() => onChange(game.gamePk, !isChecked)}
      />
      <SurroundingGames>
        <SurroundingGame
          gameDate={game.gameDate}
          timeZone={game.venue.timeZone}
          tbd={game.status.startTimeTBD}
          home={game.teams.home.team}
          away={game.teams.away.team}
          description={game.seriesDescription}
        />
      </SurroundingGames>
      {isAdmin && canSelectGame && isChecked && (
        <>
          <DesktopView>
            <ButtonLink disabled={disabled} onClick={() => onGameChange(game.gamePk!)}>
              select this game
            </ButtonLink>
          </DesktopView>
          <SelectTabletText>
            <ButtonLink>select</ButtonLink>
          </SelectTabletText>
        </>
      )}
    </div>
  );
};

type TicketRequestSurroundingGamesProps = {
  originalGamePk?: string;
  gamePk: string;
  selected: string[];
  setSelected: (value: string[]) => void;
  canSelectGame?: boolean;
  onGameChange: (value: string) => void;
};

const TicketRequestSurroundingGames: React.FC<TicketRequestSurroundingGamesProps> = ({
  originalGamePk,
  gamePk,
  selected,
  setSelected,
  canSelectGame,
  onGameChange,
}) => {
  const [showSurrounding, setShowSurrounding] = React.useState<boolean>(false);
  const [surroundingGames, setSurroundingGames] = React.useState<Game[]>([]);

  useEffect(() => {
    const pk: string = originalGamePk ? originalGamePk : gamePk;
    if (pk) {
      getSurroundingGames(pk).then(setSurroundingGames);
    }
  }, [originalGamePk, gamePk]);

  useEffect(() => {
    if (selected && selected.length > 0) {
      setShowSurrounding(true);
    }
  }, [selected]);

  const onChange = (gamePk, isChecked) => {
    if (isChecked) {
      setSelected([...selected, gamePk]);
    } else {
      setSelected(selected.filter((selected) => selected !== gamePk));
    }
  };

  return (
    <div>
      {surroundingGames && surroundingGames.length > 0 ? (
        <>
          <Checkbox
            id="showSurroundingGames"
            label="I would like another game in the surrounding days if this one is not available"
            checked={showSurrounding}
            onChange={() => setShowSurrounding(!showSurrounding)}
          />
          {showSurrounding ? (
            <GameList
              gamePk={gamePk}
              surroundingGames={surroundingGames}
              onChange={onChange}
              selected={selected}
              canSelectGame={canSelectGame}
              onGameChange={onGameChange}
            />
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default TicketRequestSurroundingGames;

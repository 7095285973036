import { Button, Card, StickyFooterButtons, useLoading } from "best-common-react-2";
import React, { useEffect, useMemo, useState } from "react";
import { getEvent, getVenueAcceptedBillings, saveRequest } from "../../api/RequesTixApi";
import RequestInformation from "../../components/requests/form/RequestInformation";
import TicketRequestForm from "../../components/requests/form/TicketRequestForm";
import TicketAgreement from "../../components/tickets/TicketAgreement";
import RouteConstants from "../../constants/RouteConstants";
import { TicketRequestAllotmentTypes } from "../../constants/TicketRequestConstants";
import { useAuth } from "../../contexts/AuthContext";
import { TicketRequestProvider } from "../../contexts/TicketRequestContext";
import { UserAllotmentCountDTO } from "../../types/Allotment";
import { GameDTO } from "../../types/Game";
import { SeasonTypeCode } from "../../types/Season";
import { FlattenedTicketRequest, SaveTicketRequestDTO } from "../../types/TicketRequest";
import { useAllotmentAmounts } from "../../util/AllotmentUtil";
import { getSeasonTypeForGame } from "../../util/SeasonUtil";
import { capitalizeFirstLetter } from "../../util/StringUtil";
import { isBusiness, isCompOnly, useRequestValidation, useValidCreditCard } from "../../util/TicketRequestUtil";

const TicketRequestComp = ({ history, match, location }) => {
  const { setLoading } = useLoading();
  const { userInfo, isAdmin } = useAuth();
  const { gamePk, type } = match.params;
  const [ticketRequest, setTicketRequest] = useState<FlattenedTicketRequest>({
    type: type,
    //@ts-ignore
    submitter: userInfo,
    //@ts-ignore
    requester: userInfo,
    notifyRequester: false,
    originalGamePk: null,
    gamePk: gamePk,
    projectId: -1,
    businessId: null,
    requestType: undefined,
    quantity: undefined,
    //@ts-ignore
    approvingSupervisor: {
      employeeUserId: null,
    },
    maxPriceOptionId: null,
    userBilling: null,
    recipient: "",
    recipientFirst: "",
    recipientLast: "",
    deliveryEmail: "",
    forGovernmentUse: false,
    forCharityUse: false,
    comments: "",
    surroundingGames: [],
    phoneNumber: "",
  });

  const [accessible, setAccessible] = useState<boolean>(false);
  const [canSave, setCanSave] = useState<boolean>(false);
  const [allotment, setAllotment] = useState<UserAllotmentCountDTO | undefined>(undefined);
  const [allotments, setAllotments] = useState<UserAllotmentCountDTO[]>([]);
  const [game, setGame] = useState<GameDTO>(undefined);
  const [hasBusinessAllotment, setHasBusinessAllotment] = useState<boolean>(false);
  const [hasPersonalAllotment, setHasPersonalAllotment] = useState<boolean>(false);
  const [venueBillings, setVenueBillings] = useState(null);
  const [agreed, setAgreed] = useState<boolean>(false);

  const isFormValid = useRequestValidation(ticketRequest, allotment);
  const isCardValid = useValidCreditCard(ticketRequest, venueBillings, game?.venue);
  const isBusinessType = useMemo(() => isBusiness(type), [type]);

  useEffect(() => {
    if (!!game) {
      const season: SeasonTypeCode = getSeasonTypeForGame(game);
      const allot: UserAllotmentCountDTO | undefined = allotments.find(
        (allotment: UserAllotmentCountDTO) =>
          allotment.season?.seasonType?.seasonTypeId === season.id &&
          allotment.season.year === game.season &&
          (isBusiness(type)
            ? allotment.allotmentType.allotmentTypeId === TicketRequestAllotmentTypes.BUSINESS
            : allotment.allotmentType.allotmentTypeId === TicketRequestAllotmentTypes.PERSONAL)
      );
      setAllotment(allot);
    }
  }, [allotments, game, type]);

  useEffect(() => {
    getEvent(gamePk).then((data: GameDTO) => {
      setGame(data);
    });
  }, [gamePk]);

  useEffect(() => {
    if (game?.venue?.id) {
      getVenueAcceptedBillings(game.venue.id).then((data) => {
        setVenueBillings(data);
      });
    }
  }, [game]);

  useEffect(() => {
    if (!!game) {
      const { hasBusinessAllotment, hasPersonalAllotment } = useAllotmentAmounts(
        allotments,
        game.season,
        game.gameType
      );
      setHasBusinessAllotment(hasBusinessAllotment);
      setHasPersonalAllotment(hasPersonalAllotment);
    }
  }, [game, allotments]);

  useEffect(() => {
    const hasAllotmentLeft = !isCompOnly(ticketRequest) || isBusinessType ? hasBusinessAllotment : hasPersonalAllotment;
    const hasAgreed = isBusinessType ? true : agreed;
    setCanSave(isFormValid && hasAgreed && isCardValid && ((accessible && hasAllotmentLeft) || isAdmin));
  }, [ticketRequest, isFormValid, allotments, isCardValid, agreed, isBusiness]);

  const save = async () => {
    try {
      setLoading(true);
      const { submitter, requester, approvingSupervisor, ...rest } = ticketRequest;
      //@ts-ignore
      const toSave: SaveTicketRequestDTO = {
        submitterId: submitter.employeeUserId,
        requesterId: requester.employeeUserId,
        approvingSupervisorId: approvingSupervisor.employeeUserId,
        ...rest,
      };
      await saveRequest(toSave);
      cancel();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const cancel = () => {
    const url = !!location?.state?.previousLocation ? location.state.previousLocation : RouteConstants.BASE;
    history.push(url);
  };

  return (
    <TicketRequestProvider gamePk={gamePk}>
      <Card>
        <Card.Header>{capitalizeFirstLetter(type)} Request</Card.Header>
        <Card.Body>
          <div className="d-flex flex-md-nowrap flex-wrap">
            <RequestInformation
              originalGamePk={ticketRequest.originalGamePk}
              setAccessible={setAccessible}
              userId={ticketRequest.requester.employeeUserId}
              setAllotments={setAllotments}
              allotments={allotments}
              type={type}
            />
            <div>
              <TicketRequestForm
                ticketRequest={ticketRequest}
                setTicketRequest={setTicketRequest}
                allotment={allotment}
              />
              {!isBusinessType && <TicketAgreement setAgreed={setAgreed} />}
            </div>
          </div>
        </Card.Body>
        <Card.Footer>
          <StickyFooterButtons startsOpen={false}>
            <Button variant="primary" disabled={!canSave} onClick={save}>
              Submit
            </Button>
            <Button variant="default" onClick={cancel}>
              Cancel
            </Button>
          </StickyFooterButtons>
        </Card.Footer>
      </Card>
    </TicketRequestProvider>
  );
};

export default TicketRequestComp;

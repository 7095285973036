import React from "react";
import { useMetadata } from "../contexts/MetadataContext";
import { State } from "../types/State";

type StateAbbreviationProps = {
  name: string;
};

export const StateAbbreviation: React.FC<StateAbbreviationProps> = ({ name }) => {
  const { states } = useMetadata();

  const getState = (): string => {
    const state: State | undefined = states.find((state) => state.name === name);
    return state ? state.code : "";
  };

  return <>{getState()}</>;
};

export type Alert = {
  severity: AlertTypes;
  message: string;
};

export type AlertType = {
  id?: string | number;
  text: string;
  type: AlertTypes;
  selfClosing?: boolean;
  closeTime?: Date;
};

export type AlertTypes = "danger" | "success" | "warning" | "info";

export const AlertConstants = {
  TYPES: {
    SUCCESS: "success" as AlertTypes,
    INFO: "info" as AlertTypes,
    DANGER: "danger" as AlertTypes,
    WARNING: "warning" as AlertTypes,
  },
};

export default AlertConstants;

import { FormColumn, Row } from "best-common-react-2";
import React from "react";
import AccessCutoffOverrider from "../cutoffs/AccessCutoffOverrider";

const EventAccessAndCutoff = ({ accessOverride, setAccessOverride, cutoffOverride, setCutoffOverride, timezone }) => (
  <Row>
    <FormColumn width={2}>
      <AccessCutoffOverrider override={accessOverride} setOverride={setAccessOverride} timezone={timezone} />
    </FormColumn>
    <FormColumn width={2}>
      <AccessCutoffOverrider
        override={cutoffOverride}
        setOverride={setCutoffOverride}
        timezone={timezone}
        isCutoff={true}
      />
    </FormColumn>
  </Row>
);

export default EventAccessAndCutoff;

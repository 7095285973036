import { Icon, MobileView } from "best-common-react";
import React from "react";
import styled from "styled-components";
import { formatSubmittedDate } from "../../../util/TimeUtil";

const AttributeHolder = styled.div.attrs(() => ({
  className: "d-flex flex-column flex-md-row align-items-md-center mb-0 mb-md-1",
}))``;

const AttributeHeader = styled.div.attrs(() => ({
  className: "mb-1 mb-md-0",
}))`
  font-size: 0.75rem;
  font-weight: bold;
  width: 8.5rem;
`;

const AttributeValue = styled.div.attrs(() => ({
  className: "mb-2 mb-md-0",
}))`
  font-size: 0.875rem;
`;

const CommentText = styled.div.attrs(() => ({
  className: "ms-2",
}))`
  font-size: 0.875rem;
`;

const ProjectRequestDetails = ({ value }) => (
  <div className="d-flex flex-column flex-md-row">
    <div>
      <MobileView>
        <AttributeHolder>
          <AttributeHeader>Requester</AttributeHeader>
          <AttributeValue>{value.requester}</AttributeValue>
        </AttributeHolder>
        <AttributeHolder>
          <AttributeHeader>Fulfillment</AttributeHeader>
          <AttributeValue>{value.fulfillmentType}</AttributeValue>
        </AttributeHolder>
        <AttributeHolder>
          <AttributeHeader>Total Ticket Cost</AttributeHeader>
          <AttributeValue>${value.totalTicketCost ? `$${value.totalTicketCost}` : ""}</AttributeValue>
        </AttributeHolder>
        <AttributeHolder>
          <AttributeHeader>Home Team</AttributeHeader>
          <AttributeValue>{value.homeTeam}</AttributeValue>
        </AttributeHolder>
      </MobileView>
      <AttributeHolder>
        <AttributeHeader>Recipient</AttributeHeader>
        <AttributeValue>{value.recipient}</AttributeValue>
      </AttributeHolder>
      <AttributeHolder>
        <AttributeHeader>Supervisor</AttributeHeader>
        <AttributeValue>{value.supervisor}</AttributeValue>
      </AttributeHolder>
      <AttributeHolder>
        <AttributeHeader>Submitter</AttributeHeader>
        <AttributeValue>{value.submitter}</AttributeValue>
      </AttributeHolder>
      <AttributeHolder>
        <AttributeHeader>Submitted</AttributeHeader>
        <AttributeValue>{formatSubmittedDate(value.submitted)}</AttributeValue>
      </AttributeHolder>
    </div>
    {value.comments && (
      <div>
        <div className="ms-0 ms-md-4 d-flex align-items-center">
          <Icon iconName="fa-comment" />
          <CommentText>{value.comments}</CommentText>
        </div>
      </div>
    )}
  </div>
);

export default ProjectRequestDetails;

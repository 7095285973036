import { BestLabel, BestSelect, FormColumn4Wide, Input } from "best-common-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { graphQl } from "../../../api/RequesTixApi";
import { APPROVED, DENIED } from "../../../constants/RequestTableConstants";
import { useAuth } from "../../../contexts/AuthContext";
import { useDropdowns } from "../../../contexts/DropdownsContext";
import { getStatusOption } from "../../../util/DropdownUtil";
import { sort } from "../../../util/SortUtil";
import { format, oneMonthBeforeYesterday, yesterday } from "../../../util/TimeUtil";
import DateRangePickerSeparate from "../../misc/DateRangePickerSeparate";
import ProjectRequestsAllProjects from "./ProjectRequestsAllProjects";
import ProjectRequestsByProject from "./ProjectRequestsByProject";

const createQuery = () => `{
  getProjectsForUser{
    projectId,
    name,
    description,
    active
  }
}
`;

const AllProject = {
  label: "All",
  value: {},
};

const ProjectRequestsReport = ({ reportData, setReportData, location, setCanExport }) => {
  const { isAdmin } = useAuth();
  const [selectedProject, setSelectedProject] = useState({});
  const [projectOptions, setProjectOptions] = useState([]);
  const [dateRange, setDateRange] = useState({});
  const { requestStateOptions, requestFulfillmentTypesOptions, getOptionByValue } = useDropdowns();

  const fulfillmentTypesOptions = [{ label: "All", value: {} }].concat(
    requestFulfillmentTypesOptions.filter((opt) => opt.label.length)
  );

  const validStateIds = [APPROVED, DENIED];
  const stateOptions = [{ label: "All", value: {} }].concat(
    requestStateOptions.filter((opt) => validStateIds.includes(opt.value.requestStateId))
  );
  const { search, fulfillmentTypeId, statusId, projectId, startDate, endDate } = reportData;
  const isAllProject = !projectId;
  const isApproved = statusId && statusId * 1 === APPROVED;

  const onChange = (key, data) => {
    setReportData({ ...reportData, [key]: data });
  };

  const updateDate = ({ fromStart, startDate, endDate }) => {
    if (fromStart) {
      onChange("startDate", format(startDate));
    } else {
      onChange("endDate", format(endDate));
    }
  };

  useEffect(() => {
    const queryObj = {
      query: createQuery(),
      variables: null,
      operationName: null,
    };
    graphQl(queryObj).then((data) => {
      if (data) {
        let projectOptions = sort(
          data.getProjectsForUser.map((d) => ({
            label: d.name,
            value: d,
          })),
          "label",
          1
        );
        if (isAdmin) {
          projectOptions.unshift(AllProject);
        }

        setProjectOptions(projectOptions);
      }
    });
  }, []);

  useEffect(() => {
    if (projectId) {
      const project = projectOptions.find((opt) => opt.value.projectId * 1 === projectId * 1);
      setSelectedProject(project);
    } else {
      setSelectedProject(AllProject);
    }
  }, [projectId, projectOptions]);

  useEffect(() => {
    const newDate = {
      start: startDate ? moment(startDate) : oneMonthBeforeYesterday,
      end: endDate ? moment(endDate) : yesterday,
    };
    setDateRange(newDate);
    setReportData({ ...reportData, startDate: format(newDate.start), endDate: format(newDate.end) });
  }, [startDate, endDate]);

  return (
    <div>
      <div className="row">
        <FormColumn4Wide>
          <BestLabel htmlFor="project">Project</BestLabel>
          <BestSelect
            id="project"
            value={selectedProject}
            options={projectOptions}
            onChange={(value) => onChange("projectId", value.value.projectId)}
          />
        </FormColumn4Wide>
        <FormColumn4Wide>
          <BestLabel htmlFor="date">Date</BestLabel>
          <DateRangePickerSeparate
            value={dateRange}
            onChange={updateDate}
            maxStartDate={yesterday}
            maxEndDate={yesterday}
          />
        </FormColumn4Wide>
        <FormColumn4Wide>
          <BestLabel htmlFor="search">Search Projects</BestLabel>
          <Input
            id="search"
            value={search}
            onChange={(event) => {
              onChange("search", event.target.value);
            }}
            placeholder="begin typing to filter"
          />
        </FormColumn4Wide>
      </div>
      {!isAllProject && (
        <>
          <div className="row">
            <FormColumn4Wide>
              <BestLabel htmlFor="status">Status</BestLabel>
              <BestSelect
                id="status"
                options={stateOptions}
                value={getStatusOption(statusId, stateOptions)}
                onChange={(value) => {
                  const statusId = value.value.requestStateId;
                  if (statusId === APPROVED) {
                    onChange("statusId", statusId);
                  } else {
                    setReportData({ ...reportData, fulfillmentTypeId: null, statusId: statusId });
                  }
                }}
              />
            </FormColumn4Wide>
            {isApproved && (
              <FormColumn4Wide>
                <BestLabel htmlFor="fulfillment">Fulfillment</BestLabel>
                <BestSelect
                  id="fulfillment"
                  options={fulfillmentTypesOptions}
                  value={getOptionByValue(fulfillmentTypesOptions, fulfillmentTypeId ? fulfillmentTypeId * 1 : {})}
                  onChange={(value) => {
                    const val = typeof value.value === "object" ? null : value.value;
                    onChange("fulfillmentTypeId", val);
                  }}
                />
              </FormColumn4Wide>
            )}
          </div>
          <div className="row">
            <div className="col">
              <BestLabel>Project Description</BestLabel>
              <div>{selectedProject?.value?.description ? selectedProject.value.description : ""}</div>
            </div>
          </div>
        </>
      )}
      {isAllProject ? (
        <ProjectRequestsAllProjects
          reportData={reportData}
          dateRange={dateRange}
          location={location}
          setCanExport={setCanExport}
        />
      ) : (
        <ProjectRequestsByProject reportData={reportData} dateRange={dateRange} setCanExport={setCanExport} />
      )}
    </div>
  );
};

export default ProjectRequestsReport;

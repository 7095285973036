import React from "react";
import styled from "styled-components";

const Bold = styled.b`
  font-weight: bold;
`;

const PersonalComp = (
  <>
    "Comp tickets only" requests will be fulfilled only if the MLB Agency Agreement seats are available.{" "}
    <Bold>
      A request is limited to a quantity of four (4) tickets per request and only one (1) fulfilled request per
      homestand.
    </Bold>{" "}
    Please note that personal requests are based on availability and not guaranteed. Requests will not be reviewed until
    after each event's cutoff time. This is typically 5 days before the game but it may vary by game and is at the
    discretion of MLB.
  </>
);
const PersonalCharge = (
  <>
    By making this request, you acknowledge that the final price and seat location are left to the club’s discretion.
    Information on location and pricing will not be confirmed until after the order has been processed and tickets have
    been distributed. You have the option to include a maximum price per ticket which will be communicated to the club.
    If the club cannot stay below this limit, the request will be denied. Requests will not be reviewed until after each
    event’s cutoff time. This is typically 5 days before the game but it may vary by game and is at the discretion of
    MLB.
  </>
);

const BusinessComp = (
  <>
    “Comp tickets only” requests will be fulfilled only if the MLB Agency Agreement seats are available.{" "}
    <Bold>
      A request is limited to a quantity of four (4) tickets per request and only one (1) fulfilled request per
      homestand.
    </Bold>{" "}
    Any requests over 4 will need to be either charged to the department, or purchased on the client’s card to guarantee
    that all seats are together. Please note that comped tickets are based on availability and not guaranteed. Requests
    will not be reviewed until after each event’s cutoff time. This is typically 5 days before the game but it may vary
    by game and is at the discretion of MLB.
  </>
);

const BusinessCharge = (
  <>
    By making this request, you acknowledge that the final price and seat location are left to the club’s discretion.
    Information on location and pricing will not be confirmed until after the order has been processed and tickets have
    been distributed. You have the option to include a maximum price per ticket which will be communicated to the club.
    If the club cannot stay below this limit, the request will be denied. Requests will not be reviewed until after each
    event’s cutoff time. This is typically 5 days before the game but it may vary by game and is at the discretion of
    MLB.
  </>
);

type DisclosureType = {
  personal: { [key: number]: any };
  business: { [key: number]: any };
};

const Disclosures: DisclosureType = {
  personal: {
    1: PersonalComp,
    2: PersonalCharge,
  },
  business: {
    1: BusinessComp,
    2: BusinessCharge,
    3: BusinessCharge,
  },
};

export default Disclosures;
